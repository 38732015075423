import { createSelector, isAnyOf } from '@reduxjs/toolkit';
import {
  createGenericAsyncThunk,
  createGenericBuilderCases,
  createGenericSlice,
} from '../../../../../store/features/generic';
import { selectPageConfig } from '../../../../../store/features/pageConfig';
import { updateSitePricingBillingDetails } from '../billingDetails';
import {
  fetchSitePricingDelegate,
  updateSitePricingDelegate,
} from '../delegate';
import { RootState } from '../../../../../store/store';
import { GenericState } from '../../../../../types';
import { PricingDelegateWithOptions } from '../delegate/types';
import {
  PricingCheckoutDetails,
  SitePricingCheckoutDetailsParams,
} from './types';

export const fetchSitePricingCheckoutDetails = createGenericAsyncThunk<
  PricingCheckoutDetails,
  SitePricingCheckoutDetailsParams
>(
  'sitePricingCheckoutDetails/fetchSitePricingCheckoutDetails',
  async (params, { extra, rejectWithValue, getState }) => {
    const { basketId } = params;
    const { siteId } = selectPageConfig(getState());

    const response = await extra.ajax.get(
      `/caas/commerce/v1/site/${siteId}/purchase/${basketId}/checkout-details`,
      {
        headers: {
          'Accept-Language': extra.headers['Accept-Language'],
          'Published-State': extra.headers['Published-State'],
        },
      },
    );

    const data: PricingCheckoutDetails = await response.json();

    if (response.status !== 200) {
      return rejectWithValue(extra.processRejectedValue(response, data));
    }

    return data;
  },
);

const sitePricingCheckoutDetailsSlice = createGenericSlice({
  name: 'sitePricingCheckoutDetails',
  initialState: {
    status: 'idle',
    data: {
      delegates: [],
      billingDetails: {
        data: {},
      } as PricingCheckoutDetails['billingDetails'],
    },
  } as GenericState<PricingCheckoutDetails>,
  reducers: {},
  extraReducers: (builder) => {
    createGenericBuilderCases<
      PricingCheckoutDetails,
      SitePricingCheckoutDetailsParams
    >(builder, fetchSitePricingCheckoutDetails);
    builder.addCase(
      updateSitePricingBillingDetails.fulfilled,
      (state, action) => {
        state.data.billingDetails = action.payload;
      },
    );
    builder.addMatcher(
      isAnyOf(
        updateSitePricingDelegate.fulfilled,
        fetchSitePricingDelegate.fulfilled,
      ),
      (state, action) => {
        const delegateIndex = state.data.delegates.findIndex(
          (delegate) =>
            delegate.delegate.data.uuid === action.payload.delegate.data.uuid,
        );

        if (delegateIndex > -1) {
          state.data.delegates[delegateIndex] = action.payload;
        }
      },
    );
  },
});

export const selectSitePricingCheckoutDetails = (state: RootState) =>
  state.pricing.checkoutDetails.data;

export const selectSitePricingCheckoutDelegates = (state: RootState) =>
  state.pricing.checkoutDetails.data.delegates || [];

export const selectSitePricingCheckoutCompletedDelegates = createSelector(
  [
    selectSitePricingCheckoutDelegates,
    (state: RootState, excludeDelegateUuid?: string) => excludeDelegateUuid,
  ],
  (delegates, excludeDelegateUuid) =>
    delegates.filter(
      (delegateData) =>
        !!delegateData.delegateComplete &&
        delegateData.delegate.data.uuid !== excludeDelegateUuid,
    ),
);

export const selectSitePricingCheckoutFirstIncompleteDelegate = createSelector(
  [selectSitePricingCheckoutDelegates],
  (delegates) => {
    const firstIncompleteDelegate = delegates.find((delegateData) => {
      return !delegateData.delegateComplete;
    });

    return firstIncompleteDelegate || null;
  },
);

export const selectSitePricingCheckoutDelegatesComplete = (state: RootState) =>
  state.pricing.checkoutDetails.data.delegatesComplete;

export const selectSitePricingCheckoutBillingDetails = (state: RootState) =>
  state.pricing.checkoutDetails.data.billingDetails.data;

export const selectSitePricingCheckoutBillingDetailsMeta = (state: RootState) =>
  state.pricing.checkoutDetails.data.billingDetails.meta;

export const selectSitePricingCheckoutDelegateById = createSelector(
  [
    selectSitePricingCheckoutDelegates,
    (state: RootState, delegateUuid: string) => delegateUuid,
  ],
  (delegates, delegateUuid) => {
    const foundDelegate = delegates.find((delegateData) => {
      return delegateData.delegate.data.uuid === delegateUuid;
    });

    return foundDelegate || ({} as PricingDelegateWithOptions);
  },
);

export const selectSitePricingCheckoutDelegateDataById = createSelector(
  [selectSitePricingCheckoutDelegateById],
  (delegate) => {
    return (
      delegate.delegate.data ||
      ({} as PricingDelegateWithOptions['delegate']['data'])
    );
  },
);

export const selectSitePricingCheckoutDelegateCompleteById = createSelector(
  [selectSitePricingCheckoutDelegateById],
  (delegate) => delegate.delegateComplete,
);

export const selectSitePricingCheckoutDelegateMetaById = createSelector(
  [selectSitePricingCheckoutDelegateById],
  (delegate) => {
    return (
      delegate.delegate.meta ||
      ({} as PricingDelegateWithOptions['delegate']['meta'])
    );
  },
);

export const selectSitePricingCheckoutDelegatePackageById = createSelector(
  [selectSitePricingCheckoutDelegateById],
  (delegate) => {
    return (
      delegate.packageData || ({} as PricingDelegateWithOptions['packageData'])
    );
  },
);

export const { start, fulfilled, rejected } =
  sitePricingCheckoutDetailsSlice.actions;

export default sitePricingCheckoutDetailsSlice.reducer;
