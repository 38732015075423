import { createSelector } from '@reduxjs/toolkit';
import { UploadedImage } from '../../../types';

export interface LeadGen {
  teaser: {
    articleTitle: string;
    teaserHeading?: string;
    teaserBody?: string;
    thumbnail?: UploadedImage;
  };
}

export const selectLeadGenForm = createSelector(
  [(state) => state.leadGenForm.data as LeadGen],
  (data) => data,
);

export const selectLeadGenFormLoaded = createSelector(
  [(state) => state.leadGenForm.loaded as boolean],
  (loaded) => loaded,
);
