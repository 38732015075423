import i18next from 'i18next';
import CallToActionService from './CallToActionService';

// TODO: remove current service after "/src/components/shared/Link" component is removed

const service = {
  getButtonDetails(button, siteType = 'EVENT', isCoLoEvent = false) {
    const isAgendaBtn =
      button.type === 'AGENDA_LINK' || button.type === 'AGENDA_LINK_SHORT';
    const buttonType =
      isAgendaBtn && isCoLoEvent ? 'COLO_AGENDA_LINK' : button.type;
    const isEvent = siteType === 'EVENT';

    const details = CallToActionService.getConfigByType(
      (buttonType || '').toUpperCase(),
    );

    return details
      ? {
          path: details.withConfiguredPath
            ? button.path
            : !isEvent && details.coursePath
            ? details.coursePath
            : details.path,
          label: i18next.t(details.label),
        }
      : {
          path: button.customUrl || '/',
          label: button.customText,
        };
  },
};

export default service;
