import { useRootSelector } from '../../store/hooks';
import { selectPageConfig } from '../../store/features/pageConfig';
import { CallToActionSelectTypes, HubModuleSiteLink } from '../../types';
import { generateDomainUrl } from '../../helpers/generateDomainUrl';
import CallToActionService from '../../services/CallToActionService';

export interface SiteOptions {
  tenant: string;
  domain: string;
  path: string;
  link?: HubModuleSiteLink;
}

export function useBuildSiteUrl(siteOptions: SiteOptions): string {
  const { tenantId, tenantsConfig: domains } =
    useRootSelector(selectPageConfig);

  const { tenant, domain, path, link } = siteOptions;

  switch (link?.selectType) {
    case CallToActionSelectTypes.CUSTOM:
      return link.customUrl;
    case CallToActionSelectTypes.LANDING:
      return generateDomainUrl({
        domains,
        domain,
        tenant,
        path: `${path}${link.customUrl}`,
      });
    case CallToActionSelectTypes.BASE: {
      const basePagePath = CallToActionService.getLinkDetailsByConfig(
        link as HubModuleSiteLink,
      ).path;

      return generateDomainUrl({
        domains,
        domain,
        tenant,
        path: `${path}${basePagePath}`,
      });
    }
  }

  // TODO check what does it mean?
  if (tenantId === tenant) {
    return path.replace(/^(\/+)?/, '/');
  }

  return generateDomainUrl({ domains, domain, tenant, path });
}
