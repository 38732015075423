import {
  ProductsPreviewList,
  ProductsList,
} from '../../../../productFilter/products';
import TopicCard from '../../../../TopicCard';

import { ViewMode } from '../index';
import { useRootSelector } from '../../../../../../../store/hooks';
import { selectHubDiscoveryTopicHomesRecord } from '../../../../../../../store/features/discovery';
import { UseInfiniteScrollProps } from '../../../../../../../hooks';
import { selectBrandHubOptions } from '../../../../../brandHub/store/features/options';

export interface TopicHomesListProps {
  mode: keyof typeof ViewMode;
  loadMoreResults?: UseInfiniteScrollProps['fetchMoreData'];
  previewCount?: number;
}

export default function TopicHomesList(props: TopicHomesListProps) {
  const { mode, loadMoreResults, previewCount } = props;
  const { results, totalPages } = useRootSelector(
    selectHubDiscoveryTopicHomesRecord,
  );
  const {
    data: { brandUrlSuffix: brandPath },
  } = useRootSelector(selectBrandHubOptions);

  return mode === ViewMode.list && loadMoreResults ? (
    <ProductsList
      products={results}
      onLoadMore={loadMoreResults}
      totalPage={totalPages}
    >
      {(product) => <TopicCard topic={product} brandPath={brandPath} />}
    </ProductsList>
  ) : (
    <ProductsPreviewList products={results} countToShow={previewCount}>
      {(product) => <TopicCard topic={product} brandPath={brandPath} />}
    </ProductsPreviewList>
  );
}
