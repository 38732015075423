import React, { PropsWithChildren } from 'react';
import cn from 'classnames';
import styles from './Button.module.scss';
import { useHubContext } from '../context/hubContext';
import { Anchor, AnchorProps } from '../../../shared/Anchor';

export enum ButtonType {
  button = 'button',
  link = 'link',
}

export enum ButtonVariant {
  contained = 'contained',
  outlined = 'outlined',
  text = 'text',
}

export enum ButtonColor {
  primary = 'primary',
  secondary = 'secondary',
  white = 'white',
  dark = 'dark',
}

export enum ButtonSize {
  small = 'small',
  medium = 'medium',
  large = 'large',
}

export interface ButtonProps extends AnchorProps {
  type: keyof typeof ButtonType;
  variant: keyof typeof ButtonVariant;
  size: keyof typeof ButtonSize;
  startIcon?: string;
  endIcon?: string;
  fullWidth?: boolean;
  color?: keyof typeof ButtonColor;
}

export const Button = ({
  to,
  size,
  variant,
  type,
  startIcon,
  endIcon,
  fullWidth,
  color,
  className,
  children,
  ...restProps
}: PropsWithChildren<ButtonProps>) => {
  const { indexPath } = useHubContext();

  const buttonClassName = cn(
    styles.button,
    styles[size],
    styles[variant],
    color && styles[color],
    className,
    {
      [styles.fullWidth]: fullWidth,
    },
  );

  return (
    <Anchor
      to={to}
      pathPrefix={indexPath}
      className={buttonClassName}
      {...restProps}
    >
      {startIcon && (
        <span className={cn('material-icons', styles.startIcon)}>
          {startIcon}
        </span>
      )}
      <span className={styles.label}>{children}</span>
      {endIcon && (
        <span className={cn('material-icons', styles.endIcon)}>{endIcon}</span>
      )}
      {!endIcon && type !== ButtonType.link && (
        <span className={cn('material-icons', styles.arrowIcon)}>east</span>
      )}
    </Anchor>
  );
};

Button.defaultProps = {
  type: ButtonType.button,
  variant: ButtonVariant.contained,
  size: ButtonSize.large,
  fullWidth: false,
};
