import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { DFPSlotsProvider, DFPManager, AdSlot } from 'react-dfp';
import clientConfig from '../../../clientConfig';

import './Advertising.scss';

/*
 * white label
 */
export class Advertising extends Component {
  constructor(props) {
    super(props);

    const { advSize } = this.props;

    this.currentAdv = clientConfig.advertising[advSize];
  }

  advRefresh = () => {
    const currentWidth = window.innerWidth;

    this.currentAdv.sizeMapping.forEach((item) => {
      const viewport = item.viewport[0];
      const isVertexCrossing =
        Math.sign(viewport - currentWidth) !==
        Math.sign(viewport - this.lastWidth);

      if (viewport && isVertexCrossing) {
        this.lastWidth = currentWidth;
        DFPManager.refresh(this.props.advSlots);
      }
    });
  };

  componentDidMount() {
    const { options: { data: { advertisingEnabled = false } = {} } = {} } =
      this.props;

    if (advertisingEnabled && this.currentAdv.sizes.length > 1) {
      this.lastWidth = window.innerWidth;
      window.addEventListener('resize', this.advRefresh);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.advRefresh);
  }

  render() {
    const {
      advSlots,
      advSize,
      options: {
        data: { advertisingEnabled = false, networkId, adUnit } = {},
      } = {},
      style,
    } = this.props;

    return (
      advertisingEnabled && (
        <div className="c-advertising" style={style}>
          <DFPSlotsProvider dfpNetworkId={networkId} collapseEmptyDivs>
            {advSlots.map((id, i) => {
              return (
                <div key={i} className={`c-advertising__${advSize}`}>
                  <AdSlot
                    adUnit={adUnit}
                    sizes={this.currentAdv.sizes}
                    sizeMapping={this.currentAdv.sizeMapping}
                    slotId={id}
                    targetingArguments={{ pos: id }}
                  />
                </div>
              );
            })}
          </DFPSlotsProvider>
        </div>
      )
    );
  }
}

Advertising.propTypes = {
  options: PropTypes.object,
  advSlots: PropTypes.array,
  advSize: PropTypes.string,
  style: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    options: state.options,
  };
}

export default connect(mapStateToProps)(Advertising);
