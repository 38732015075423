import React from 'react';
import { FacetType, HubFacetTypes } from '../../../../../../types';
import {
  Discovery,
  DiscoveryFilter,
} from '../../../../../../store/features/discovery';
import { FilterFacetGroup } from '../FilterFacetGroup';
import { Checkbox, Radio } from '../index';
import { DeliveryType } from '../../../../../../constants';

export const headingMap = {
  [FacetType.CONTENT_TYPE]: 'Products',
  [FacetType.EVENT_TYPES]: 'Attendance',
  [FacetType.COURSE_TYPES]: 'Attendance',
  [FacetType.AUDIENCE_HOME]: 'Topic Homes',
  [FacetType.SUB_BRANDS]: 'Brands',
  [FacetType.SECTORS]: 'Sectors',
  [FacetType.TOPICS]: 'Topics',
  [FacetType.DATES]: 'Dates',
  [FacetType.LOCATIONS]: 'Location',
  [FacetType.PUBLISHED_DATES]: 'Publish Date',
  [FacetType.AWARD_GROUPS]: 'Award Group',
  [FacetType.INFORMATION_TYPES]: 'Qualification',
  [FacetType.PARTNERS]: 'Delivery Partners',
  [FacetType.CREDIT_PARTNERS]: 'Credit Partners',
  [FacetType.LANGUAGES]: 'Languages',
  [FacetType.ACCESS_TYPES]: 'Access Types',
  [FacetType.CREATED_TIME_FRAME]: 'Created Time',
};

export const maxFiltersToShowCountMap = {
  [FacetType.CONTENT_TYPE]: 5,
  [FacetType.EVENT_TYPES]: 5,
  [FacetType.COURSE_TYPES]: 5,
  [FacetType.AUDIENCE_HOME]: 5,
  [FacetType.SUB_BRANDS]: 5,
  [FacetType.SECTORS]: 10,
  [FacetType.TOPICS]: 10,
  [FacetType.DATES]: 3,
  [FacetType.LOCATIONS]: 5,
  [FacetType.PUBLISHED_DATES]: 5,
  [FacetType.AWARD_GROUPS]: 5,
  [FacetType.INFORMATION_TYPES]: 5,
  [FacetType.PARTNERS]: 5,
  [FacetType.CREDIT_PARTNERS]: 5,
  [FacetType.LANGUAGES]: 5,
  [FacetType.ACCESS_TYPES]: 5,
  [FacetType.CREATED_TIME_FRAME]: 5,
};

export const facetTypeOrder = [
  FacetType.CONTENT_TYPE,
  FacetType.EVENT_TYPES,
  FacetType.COURSE_TYPES,
  FacetType.SUB_BRANDS,
  FacetType.SECTORS,
  FacetType.TOPICS,
  FacetType.DATES,
  FacetType.LOCATIONS,
  FacetType.PUBLISHED_DATES,
  FacetType.AWARD_GROUPS,
  FacetType.INFORMATION_TYPES,
  FacetType.PARTNERS,
  FacetType.CREDIT_PARTNERS,
  FacetType.LANGUAGES,
  FacetType.ACCESS_TYPES,
  FacetType.CREATED_TIME_FRAME,
];

export const deliveryTypeOrder = [
  DeliveryType.PHYSICAL,
  DeliveryType.CLASSROOM,
  DeliveryType.IN_PERSON,
  DeliveryType.VIRTUAL,
  DeliveryType.ONLINE,
  DeliveryType.LIVE_ONLINE,
  DeliveryType.DISTANCE,
  DeliveryType.ONLINE_PROGRAMME,
  DeliveryType.ON_DEMAND,
  DeliveryType.FLEXI,
];

export const awardGroupOrder = ['Discover', 'Develop', 'Distinguish'];

export const qualificationOrder = [
  'Practical Training Course',
  'Award',
  'Certificate',
  'Advanced Certificate',
  'Diploma',
  'Degree',
  'Postgraduate Certificate',
  'Masters',
  'Doctorate',
];

export const zeroCountFacetsHiddenList = [
  FacetType.SECTORS,
  FacetType.TOPICS,
  FacetType.DATES,
  FacetType.LOCATIONS,
];

export function sortByFacetTypeOrder(a: FacetType, b: FacetType) {
  const aIndex = facetTypeOrder.indexOf(a);
  const bIndex = facetTypeOrder.indexOf(b);

  if (aIndex > bIndex) {
    return 1;
  } else if (aIndex < bIndex) {
    return -1;
  }

  return 0;
}

export function sortByTypeOrder(order: string[]) {
  return function (a: DiscoveryFilter, b: DiscoveryFilter) {
    const aIndex = order.indexOf(a.label);
    const bIndex = order.indexOf(b.label);

    if (aIndex > bIndex) {
      return 1;
    } else if (aIndex < bIndex) {
      return -1;
    }

    return 0;
  };
}

export function sortByFacetType(
  facetType: FacetType,
  filters: DiscoveryFilter[],
): DiscoveryFilter[] {
  if ([FacetType.EVENT_TYPES, FacetType.COURSE_TYPES].includes(facetType)) {
    return filters.sort(sortByTypeOrder(deliveryTypeOrder));
  }

  if ([FacetType.AWARD_GROUPS].includes(facetType)) {
    return filters.sort(sortByTypeOrder(awardGroupOrder));
  }

  if ([FacetType.INFORMATION_TYPES].includes(facetType)) {
    return filters.sort(sortByTypeOrder(qualificationOrder));
  }

  return filters;
}

const filterNonZeroFilters = (
  facets: DiscoveryFilter[],
  facetName: FacetType,
) =>
  facets.filter((facet) => {
    if (!facet.label) {
      return false;
    }

    if (zeroCountFacetsHiddenList.includes(facetName)) {
      return facet.count > 0;
    }

    return true;
  });

export interface FilterFacetsProps {
  facets: Discovery['facets'];
}

export function FilterFacets({ facets }: FilterFacetsProps) {
  const facetTypes = Object.keys(facets) as [HubFacetTypes];
  const sortedFacetTypes = [...facetTypes].sort(sortByFacetTypeOrder);

  return (
    <>
      {sortedFacetTypes
        .filter((facetType) => facets[facetType].length > 0)
        .map((facetType) => {
          const zeroFilter = facets[facetType][0];
          const inputType = zeroFilter.type === 'ALL' ? 'radio' : 'checkbox';
          const inputName = zeroFilter.filterValue.type;
          const filters = filterNonZeroFilters(facets[facetType], facetType);
          const sortedFilters = sortByFacetType(facetType, filters);

          return (
            filters.length > 0 && (
              <FilterFacetGroup
                key={facetType}
                type={inputType}
                name={inputName}
                maxFiltersToShowCount={maxFiltersToShowCountMap[facetType]}
                heading={headingMap[facetType]}
                filters={sortedFilters}
                renderFilterItem={(filter, type) => (
                  <>
                    {type === 'radio' && <Radio data={filter} />}
                    {type === 'checkbox' && <Checkbox data={filter} />}
                  </>
                )}
              />
            )
          );
        })}
    </>
  );
}
