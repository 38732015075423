import { ArticleHomeRelatedArticle } from '../../../../../../../../store/features/articles/home';
import {
  Image,
  Title,
  Badge,
  CardAnchor,
  CardButton,
} from '../../../../../../shared/productFilter/cards/components';
import { ProductListCard } from '../../../../../../shared/productFilter/products';

export enum RelatedArticleCardSizes {
  small = 'small',
  large = 'large',
}

export interface RelatedArticleCardProps {
  article: ArticleHomeRelatedArticle;
  size: keyof typeof RelatedArticleCardSizes;
}

export function RelatedArticleCard(props: RelatedArticleCardProps) {
  const {
    article: { articlePath, title, imageUrl: imagePath, primaryTopic },
    size,
  } = props;

  return (
    <CardAnchor to="/" pathPrefix={articlePath}>
      <ProductListCard
        image={<Image imageStyle="list" path={imagePath} />}
        imageSize={size === RelatedArticleCardSizes.large ? 'small' : 'large'}
        title={<Title size={size}>{title}</Title>}
        leftHeader={
          primaryTopic && (
            <Badge theme="outlined" size="tiny">
              {primaryTopic}
            </Badge>
          )
        }
        showMoreBadge={<CardButton>Read More</CardButton>}
      />
    </CardAnchor>
  );
}

RelatedArticleCard.defaultProps = {
  size: RelatedArticleCardSizes.small,
};
