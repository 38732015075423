import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { SubmitHandler, useForm } from 'react-hook-form';
import { redirect } from '../../../../../../../helpers';
import Patterns from '../../../../../../../utils/pattern/patterns';
import Auth from '../../../../../../../services/AuthService';
import { selectTenantConfig } from '../../../../../../../store/features/tenantConfig';
import s from './ForgottenPasswordForm.module.scss';
import Form from '../../shared/Form';
import TextField from '../../shared/TextField';
import HelperText from '../../shared/HelperText';
import InputControl from '../../shared/InputControl';
import Anchor from '../../shared/Anchor';
import Button from '../../shared/Button';

const errors = {
  DEFAULT: 'Something went wrong. Please try again later.',
};

interface User {
  username: string;
}

export default function ForgottenPasswordForm() {
  const form = useForm<User>({
    defaultValues: {
      username: '',
    },
  });
  const { id: tenantId } = useSelector(selectTenantConfig);
  const [error, setError] = useState('');

  const {
    formState: { isSubmitting },
  } = form;

  const handleSubmit: SubmitHandler<User> = useCallback(
    async (user) => {
      setError('');

      const { username } = user;
      const response = await Auth.sendForgotPassword({ username, tenantId });
      const data = await response.json();

      if (data.success) {
        const url = `/account/forgotten-password/sent/?username=${username}`;
        redirect(url);
        return;
      }

      setError(errors.DEFAULT);
    },
    [tenantId],
  );

  return (
    <Form form={form} onSubmit={handleSubmit}>
      <InputControl>
        <TextField
          type="email"
          name="username"
          label="Email Address"
          validation={{
            required: 'Required',
            validate: {
              isValid(email) {
                return !email || Patterns.isEmail(email)
                  ? true
                  : 'Please enter a valid email address';
              },
            },
          }}
        />
      </InputControl>
      <Anchor to="/account/login/" className={s.backToLoginAnchor}>
        Back to Login
      </Anchor>
      {error && (
        <HelperText color="danger" className={s.errorHelperText}>
          {error}
        </HelperText>
      )}
      <Button
        type="submit"
        color="accent"
        disabled={isSubmitting}
        className={s.submitButton}
      >
        Send email
      </Button>
    </Form>
  );
}
