import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import CallToAction from '../../../../shared/CallToAction/CallToAction';

import './LeadGenConsentStatements.scss';

export default function LeadGenConsentStatements(props) {
  const { tenantName, color, leadGenType } = props;
  const articleLinkClass = classnames({
    'c-lead-gen-consent-statements__link-article': leadGenType === 'article',
  });

  return (
    <div className="c-lead-gen-consent-statements">
      <div className="c-lead-gen-consent-statements__use-data">
        {tenantName} will use the data collected here to provide access to the
        requested content.
      </div>
      <div className="c-lead-gen-consent-statements__policy">
        You can opt out of hearing from us at any time by clicking the
        unsubscribe link in our emails or by contacting us. To understand how we
        use your data, please see our{' '}
        <CallToAction
          type="link"
          target="_blank"
          to="https://informa.com/privacy-policy"
          className={articleLinkClass}
          style={{ color }}
        >
          privacy policy
        </CallToAction>
      </div>
    </div>
  );
}

LeadGenConsentStatements.propTypes = {
  tenantName: PropTypes.string,
  color: PropTypes.string,
  leadGenType: PropTypes.string,
};
