import React from 'react';
import { renderStyledElementStyles } from '../../../helpers';
import styled from 'styled-components';
import CTA, {
  CallToActionProps as CTAProps,
  CallToActionConfigProps as CTAConfigProps,
} from '../../../../shared/CallToAction/CallToAction';

export const CallToAction = ({ ctaConfig, ...props }) => (
  <CTA {...props} ctaConfig={ctaConfig} />
);

CallToAction.propTypes = CTAProps;

export const CallToActionConfigProps = CTAConfigProps;

const StyledCallToActionWrapper = styled.div`
  ${({ theme, displayStyles }) =>
    renderStyledElementStyles(theme, displayStyles?.elements?.callToAction)}
  ${({ theme, visualStyles }) =>
    renderStyledElementStyles(theme, visualStyles?.elements?.callToAction)}
`;

export const StyledCallToAction = ({
  displayStyles,
  visualStyles,
  ...props
}) => (
  <StyledCallToActionWrapper
    displayStyles={displayStyles}
    visualStyles={visualStyles}
  >
    <CallToAction {...props} />
  </StyledCallToActionWrapper>
);

StyledCallToAction.propTypes = CTAProps;
