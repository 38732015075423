import { combineReducers } from '@reduxjs/toolkit';
import articleReducer from './features/articles/articleReducer';
import brandHubReducer from '../components/hub/brandHub/store/brandHubReducer';
import audienceHubReducer from '../components/hub/audienceHub/store/audienceHubReducer';
import pricingReducer from '../components/paymentJourneyRedesign/store/pricingReducer';
import discoveryReducer from './features/discovery';
import festivalProGuestsReducer from './features/festivalProGuests';
import festivalProScheduleReducer from './features/festivalProSchedule';
import siteArticlesReducer from './features/siteArticles';
import siteArticleDetailsReducer from './features/siteArticles/siteArticleDetails';

const rootReducer = {
  ...articleReducer,
  ...brandHubReducer,
  ...audienceHubReducer,
  ...pricingReducer,
  discovery: discoveryReducer,
  festivalProGuests: festivalProGuestsReducer,
  festivalProSchedule: festivalProScheduleReducer,
  site: combineReducers({
    articles: siteArticlesReducer,
    articleDetails: siteArticleDetailsReducer,
  }),
};

export default rootReducer;
