import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import qs from 'qs';
import Auth from '../../../../../../services/AuthService';
import { useRootSelector } from '../../../../../../store/hooks';
import { selectBrandHubLayout } from '../../../store/features/layout';
import { selectBrandHubOptions } from '../../../store/features/options';
import { selectPageConfig } from '../../../../../../store/features/pageConfig';
import s from './RegisterSuccess.module.scss';
import StaticPageLayout from '../../../layouts/StaticPageLayout';
import { MetaTags } from '../../../../shared/MetaTags';
import PublicPageLayout from '../shared/PublicPageLayout';
import Paper from '../shared/Paper';
import Button from '../shared/Button';

export function RegisterSuccess() {
  const {
    data: {
      header: {
        headerNavBar: { brandLogo },
      },
    },
  } = useRootSelector(selectBrandHubLayout);
  const {
    data: { name: hubName },
  } = useRootSelector(selectBrandHubOptions);

  const { location } = useSelector(selectPageConfig);
  const [loading, setLoading] = useState(false);

  const { username } = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });

  const handleResendConfirmationEmail = useCallback(async () => {
    setLoading(true);

    try {
      await Auth.resendConfirmationEmail({ username });
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [username]);

  return (
    <StaticPageLayout className={s.body}>
      <MetaTags
        title="Thanks for signing up!"
        titleSuffix={hubName}
        thumbnail={brandLogo?.path}
      />
      <PublicPageLayout>
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-md-10 col-md-offset-1 col-lg-8 col-lg-offset-2">
              <Paper
                heading="Thanks for signing up!"
                text={
                  <p>
                    We've sent an email to <strong>{username as string}</strong>
                    . Please check your inbox and click on the email link to
                    confirm your registration.
                  </p>
                }
              >
                <Button
                  onClick={handleResendConfirmationEmail}
                  color="grey"
                  disabled={loading}
                >
                  Resend confirmation email
                </Button>
              </Paper>
            </div>
          </div>
        </div>
      </PublicPageLayout>
    </StaticPageLayout>
  );
}
