import { v4 as uuid } from 'uuid';
import jwtDecode from 'jwt-decode';
import loadable from '@loadable/component';
import { canCookieBeSecure } from './helpers';
import clientConfig from './clientConfig';
import { SiteTypes } from './types';
import qs from 'qs';

import { getPackageFormDefaultValues } from './components/paymentJourneyRedesign/helpers/selectPackage/getPackageFormDefaultValues';
import { getFetchPricingProductsParams } from './components/paymentJourneyRedesign/helpers/selectPackage/getFetchPricingProductsParams';

import { fetchHubAction } from './store/commonDucks/hub';
import { fetchAudienceHubAction } from './store/audienceDucks/audienceHub';
import { fetchAudienceHeaderAction } from './store/audienceDucks/audienceHeader';
import { fetchAudienceEventsAction } from './store/audienceDucks/audienceEvents';
import { fetchAudienceCoursesAction } from './store/audienceDucks/audienceCourses';
import { fetchAudienceArticlesAction } from './store/audienceDucks/audienceArticles';
import { fetchPortfolioAction } from './store/commonDucks/portfolio';
import { fetchLeadGenFormAction } from './store/commonDucks/leadGenForm';

import {
  fetchArticleHomeAction,
  removeArticleHomeAction,
  setArticleGatedAction,
  setPortfolioAction,
} from './store/articleDucks/articleHome';

import { fetchSiteLayoutAction } from './store/commonDucks/options';
import { fetchSiteContentAction } from './store/siteDucks/siteContent';
import { fetchSiteSpeakerDetailsAction } from './store/siteDucks/siteSpeakerDetails';
import { fetchSiteSpeakersAction } from './store/siteDucks/siteSpeakers';
import { fetchSiteSponsorDetailsAction } from './store/siteDucks/siteSponsorDetails';
import { fetchAgendaAction } from './store/siteDucks/siteAgenda';
import { fetchCoLoAgendaAction } from './store/siteDucks/siteCoLoAgenda';
import { fetchDownloadableAgendaAction } from './store/siteDucks/siteDownloadableAgenda';
import { fetchSiteArticles } from './store/features/siteArticles';
import { fetchSiteArticleDetails } from './store/features/siteArticles/siteArticleDetails';

import { fetchSiteProductsAction } from './store/pricingDucks/siteProducts';
import { fetchCourseOptionsAction } from './store/pricingDucks/courseOptions';
import { fetchChosenProductsAction } from './store/pricingDucks/chosenProducts';
import { fetchBillingDetailsAction } from './store/pricingDucks/billingDetails';
import { fetchDelegateDetailsAction } from './store/pricingDucks/delegateDetails';
import { fetchContactDetailsAction } from './store/pricingDucks/contactDetails';
import { fetchOrderReceiptAction } from './store/pricingDucks/orderReceipt';
import { fetchSitePartnersAction } from './store/siteDucks/sitePartners';
import { updatePageConfigAction } from './store/commonDucks/pageConfig';

import { fetchSitePricingProducts } from './components/paymentJourneyRedesign/store/features/products';
import {
  fetchSitePricingPackages,
  fetchSitePricingSavedPackages,
} from './components/paymentJourneyRedesign/store/features/packages';
import { fetchCoursePricingOptions } from './components/paymentJourneyRedesign/store/features/courseOptions';
import { fetchSitePricingCheckoutDetails } from './components/paymentJourneyRedesign/store/features/checkoutDetails';
import { fetchSitePricingOrderReceipt } from './components/paymentJourneyRedesign/store/features/orderReceipt';
import { fetchSitePricingContent } from './components/paymentJourneyRedesign/store/features/content';
import { updateSitePricingVIPCode } from './components/paymentJourneyRedesign/store/features/discounts';

/**
 * Hub actions
 */
import {
  fetchArticleHome,
  removeBody as removeArticleBody,
  setGated as setArticleGated,
} from './store/features/articles/home';
import {
  fetchHubPageDiscovery,
  fetchSitePageDiscovery,
} from './store/features/discovery';
/**
 * Brand Hub pages
 */
import {
  Home as BrandHubHome,
  Events as BrandHubEvents,
  Articles as BrandHubArticles,
  Courses as BrandHubCourses,
  Landing as BrandHubLanding,
  Search as BrandHubSearch,
  StreamlyVideos as BrandHubStreamlyVideos,
  IndustryHome,
  Login as BrandLogin,
  ForgottenPassword as BrandForgottenPassword,
  ForgottenPasswordSent as BrandForgottenPasswordSent,
  Register as BrandRegister,
  RegisterSuccess as BrandRegisterSuccess,
  AccountActivated as BrandAccountActivated,
  Profile as BrandProfile,
  EditProfile as BrandEditProfile,
} from './components/hub/brandHub/pages';

/**
 * Brand Hub actions
 */
import {
  fetchBrandHubHomePage,
  fetchBrandHubBasePage,
  fetchBrandHubLandingPage,
} from './components/hub/brandHub/store/features/page';
import { fetchBrandHubLayout } from './components/hub/brandHub/store/features/layout';
import { fetchBrandHubOptions } from './components/hub/brandHub/store/features/options';
import { fetchIndustryHome } from './components/hub/brandHub/store/features/industryHome';
import { fetchIndustryHeader } from './components/hub/brandHub/store/features/industryHeader';

/**
 * Audience Hub pages
 */
import {
  Home as AudienceHubHome,
  Events as AudienceHubEvents,
  Articles as AudienceHubArticles,
  Courses as AudienceHubCourses,
  Landing as AudienceHubLanding,
  Search as AudienceHubSearch,
  Article as AudienceHubArticle,
  StreamlyVideos as AudienceHubStreamlyVideos,
} from './components/hub/audienceHub/pages';

/**
 * Audience Hub actions
 */
import {
  fetchAudienceHubHomePage,
  fetchAudienceHubBasePage,
  fetchAudienceHubLandingPage,
} from './components/hub/audienceHub/store/features/page';
import { fetchAudienceHubLayout } from './components/hub/audienceHub/store/features/layout';
import { fetchAudienceHubOptions } from './components/hub/audienceHub/store/features/options';

/**
 * Loadable functionality inside view components
 */

import TermsAndConditionsView from './components/views/siteBasePages/TermsAndConditionsView/TermsAndConditionsView';

/**
 * Account
 */

const SignInView = loadable(() =>
  import(
    /* webpackChunkName: "SignInView" */ './components/views/account/SignInView/SignInView'
  ),
);
const SignUpView = loadable(() =>
  import(
    /* webpackChunkName: "SignUpView" */ './components/views/account/SignUpView/SignUpView'
  ),
);
const AccountForgottenPasswordView = loadable(() =>
  import(
    /* webpackChunkName: "AccountForgottenPasswordView" */ './components/views/account/AccountForgottenPasswordView/AccountForgottenPasswordView'
  ),
);
const ProfileView = loadable(() =>
  import(
    /* webpackChunkName: "ProfileView" */ './components/views/account/ProfileView/ProfileView'
  ),
);
const EditProfileView = loadable(() =>
  import(
    /* webpackChunkName: "EditProfileView" */ './components/views/account/EditProfileView/EditProfileView'
  ),
);
const AccountActivatedView = loadable(() =>
  import(
    /* webpackChunkName: "AccountActivatedView" */ './components/views/account/AccountActivatedView/AccountActivatedView'
  ),
);

/**
 * Platform base pages
 */

const HubPortfolioView = loadable(() =>
  import(
    /* webpackChunkName: "HubPortfolioView" */ './components/views/hub/HubPortfolioView/HubPortfolioView'
  ),
);
const AudienceHomeView = loadable(() =>
  import(
    /* webpackChunkName: "AudienceHomeView" */ './components/views/discovery/AudienceHomeView/AudienceHomeView'
  ),
);
const AudienceEventsView = loadable(() =>
  import(
    /* webpackChunkName: "AudienceEventsView" */ './components/views/discovery/AudienceEventsView/AudienceEventsView'
  ),
);
const AudienceCoursesView = loadable(() =>
  import(
    /* webpackChunkName: "AudienceCoursesView" */ './components/views/discovery/AudienceCoursesView/AudienceCoursesView'
  ),
);
const AudienceArticlesView = loadable(() =>
  import(
    /* webpackChunkName: "AudienceArticlesView" */ './components/views/discovery/AudienceArticlesView/AudienceArticlesView'
  ),
);
const ArticleHomeView = loadable(() =>
  import(
    /* webpackChunkName: "ArticleHomeView" */ './components/views/discovery/ArticleHomeView/ArticleHomeView'
  ),
);

/**
 * Event/course base pages
 */

const HomeView = loadable(() =>
  import(
    /* webpackChunkName: "HomeView" */ './components/views/siteBasePages/HomeView/HomeView'
  ),
);
const AttendeesView = loadable(() =>
  import(
    /* webpackChunkName: "AttendeesView" */ './components/views/siteBasePages/AttendeesView/AttendeesView'
  ),
);
const AwardsView = loadable(() =>
  import(
    /* webpackChunkName: "AwardsView" */ './components/views/siteBasePages/AwardsView/AwardsView'
  ),
);
const ContactsView = loadable(() =>
  import(
    /* webpackChunkName: "ContactsView" */ './components/styled/siteViews/ContactView'
  ),
);
const HighlightsView = loadable(() =>
  import(
    /* webpackChunkName: "HighlightsView" */ './components/views/siteBasePages/HighlightsView/HighlightsView'
  ),
);
const GrowTixView = loadable(() =>
  import(
    /* webpackChunkName: "GrowTixView" */ './components/views/siteBasePages/GrowTixView/GrowTixView'
  ),
);
const FestivalProGuestsView = loadable(() =>
  import(
    /* webpackChunkName: "FestivalProGuestsView" */ './components/views/siteBasePages/FestivalProGuestsView/FestivalProGuestsView'
  ),
);
const FestivalProGuestProfileView = loadable(() =>
  import(
    /* webpackChunkName: "FestivalProGuestProfileView" */ './components/views/siteBasePages/FestivalProGuestProfileView/FestivalProGuestProfileView'
  ),
);
const FestivalProScheduleView = loadable(() =>
  import(
    /* webpackChunkName: "FestivalProScheduleView" */ './components/views/siteBasePages/FestivalProScheduleView/FestivalProScheduleView'
  ),
);
const LandingPageView = loadable(() =>
  import(
    /* webpackChunkName: "LandingPageView" */ './components/views/siteBasePages/LandingPageView/LandingPageView'
  ),
);
const MediaPartnersView = loadable(() =>
  import(
    /* webpackChunkName: "MediaPartnersView" */ './components/views/siteBasePages/MediaPartnersView/MediaPartnersView'
  ),
);
const NetworkingView = loadable(() =>
  import(
    /* webpackChunkName: "NetworkingView" */ './components/views/siteBasePages/NetworkingView/NetworkingView'
  ),
);
const PartneringView = loadable(() =>
  import(
    /* webpackChunkName: "PartneringView" */ './components/views/siteBasePages/PartneringView/PartneringView'
  ),
);
const PlanYourVisitView = loadable(() =>
  import(
    /* webpackChunkName: "PlanYourVisitView" */ './components/views/siteBasePages/PlanYourVisitView/PlanYourVisitView'
  ),
);
const ThirdPartyPartnersView = loadable(() =>
  import(
    /* webpackChunkName: "ThirdPartyPartnersView" */ './components/views/siteBasePages/ThirdPartyPartnersView/ThirdPartyPartnersView'
  ),
);
const VipServicesView = loadable(() =>
  import(
    /* webpackChunkName: "VipServicesView" */ './components/views/siteBasePages/VipServicesView/VipServicesView'
  ),
);
const EventPrivacyPolicyView = loadable(() =>
  import(
    /* webpackChunkName: "EventPrivacyPolicyView" */ './components/views/siteBasePages/EventPrivacyPolicyView/EventPrivacyPolicy'
  ),
);
const AgendaView = loadable(() =>
  import(
    /* webpackChunkName: "AgendaView" */ './components/views/siteBasePages/SiteAgenda/SiteAgenda'
  ),
);
const CoLoAgendaView = loadable(() =>
  import(
    /* webpackChunkName: "CoLoAgendaView" */ './components/views/siteBasePages/CoLoAgendaView/CoLoAgendaView'
  ),
);
const DownloadableAgendaView = loadable(() =>
  import(
    /* webpackChunkName: "DownloadableAgendaView" */ './components/views/siteBasePages/DownloadableAgendaView/DownloadableAgendaView'
  ),
);
const SpeakersView = loadable(() =>
  import(
    /* webpackChunkName: "SpeakersView" */ './components/views/siteBasePages/SpeakersView/SpeakersView'
  ),
);
const SearchView = loadable(() =>
  import(
    /* webpackChunkName: "SearchView" */ './components/views/siteBasePages/SearchView'
  ),
);
const SpeakerDetailsView = loadable(() =>
  import(
    /* webpackChunkName: "SpeakerDetailsView" */ './components/views/siteBasePages/SpeakerDetailsView/SpeakerDetailsView'
  ),
);
const SponsorsView = loadable(() =>
  import(
    /* webpackChunkName: "SponsorsView" */ './components/views/siteBasePages/SponsorsView/SponsorsView'
  ),
);
const SponsorDetailsView = loadable(() =>
  import(
    /* webpackChunkName: "SponsorDetailsView" */ './components/views/siteBasePages/SponsorDetailsView/SponsorDetailsView'
  ),
);
const HotelMapView = loadable(() =>
  import(
    /* webpackChunkName: "HotelMapView" */ './components/views/siteBasePages/HotelMapView/HotelMapView'
  ),
);
const ArticlesView = loadable(() =>
  import(
    /* webpackChunkName: "ArticlesView" */ './components/views/siteBasePages/ArticlesView'
  ),
);
const ArticleDetailsView = loadable(() =>
  import(
    /* webpackChunkName: "ArticleDetailsView" */ './components/views/siteBasePages/ArticleDetailsView'
  ),
);

/**
 * Virtual journey
 */

const VirtualLoginView = loadable(() =>
  import(
    /* webpackChunkName: "VirtualLoginView" */ './components/views/delegateJourney/virtual/LoginView/LoginView'
  ),
);
const VirtualCreatePasswordView = loadable(() =>
  import(
    /* webpackChunkName: "VirtualCreatePasswordView" */ './components/views/delegateJourney/virtual/CreatePasswordView/CreatePasswordView'
  ),
);
const VirtualForgottenPasswordView = loadable(() =>
  import(
    /* webpackChunkName: "VirtualForgottenPasswordView" */ './components/views/delegateJourney/virtual/ForgottenPasswordView/ForgottenPasswordView'
  ),
);
const VirtualPasswordExpiredView = loadable(() =>
  import(
    /* webpackChunkName: "VirtualPasswordExpiredView" */ './components/views/delegateJourney/virtual/PasswordExpiredView/PasswordExpiredView'
  ),
);
const VirtualSetupCompleteView = loadable(() =>
  import(
    /* webpackChunkName: "VirtualSetupCompleteView" */ './components/views/delegateJourney/virtual/SetupCompleteView/SetupCompleteView'
  ),
);

/**
 * P1 journey
 */

const P1LoginView = loadable(() =>
  import(
    /* webpackChunkName: "P1LoginView" */ './components/views/delegateJourney/p1/LoginView/LoginView'
  ),
);
const P1ResetPasswordView = loadable(() =>
  import(
    /* webpackChunkName: "P1ResetPasswordView" */ './components/views/delegateJourney/p1/ResetPasswordView/ResetPasswordView'
  ),
);
const P1ForgottenPasswordView = loadable(() =>
  import(
    /* webpackChunkName: "P1ForgottenPasswordView" */ './components/views/delegateJourney/p1/ForgottenPasswordView/ForgottenPasswordView'
  ),
);
const P1AccountActivationView = loadable(() =>
  import(
    /* webpackChunkName: "P1AccountActivationView" */ './components/views/delegateJourney/p1/AccountActivationView/AccountActivationView'
  ),
);

/**
 * Legacy Payment journey
 */

const SelectPackageView = loadable(() =>
  import(
    /* webpackChunkName: "SelectPackageView" */ './components/views/payments/SelectPackageView/SelectPackageView'
  ),
);
const SelectCourseOptionView = loadable(() =>
  import(
    /* webpackChunkName: "SelectCourseOptionView" */ './components/views/payments/SelectCourseOptionView/SelectCourseOptionView'
  ),
);
const BasketView = loadable(() =>
  import(
    /* webpackChunkName: "BasketView" */ './components/views/payments/BasketView/BasketView'
  ),
);
const BillingDetailsView = loadable(() =>
  import(
    /* webpackChunkName: "BillingDetailsView" */ './components/views/payments/BillingDetailsView/BillingDetailsView'
  ),
);
const SocialCheckoutView = loadable(() =>
  import(
    /* webpackChunkName: "SocialCheckoutView" */ './components/views/payments/SocialCheckoutView/SocialCheckoutView'
  ),
);
const ConfirmPurchaseView = loadable(() =>
  import(
    /* webpackChunkName: "ConfirmPurchaseView" */ './components/views/payments/ConfirmPurchaseView/ConfirmPurchaseView'
  ),
);
const DelegateDetailsView = loadable(() =>
  import(
    /* webpackChunkName: "DelegateDetailsView" */ './components/views/paymentJourney/DelegateDetailsView/DelegateDetailsView'
  ),
);
const YourContactDetailsView = loadable(() =>
  import(
    /* webpackChunkName: "YourContactDetailsView" */ './components/views/paymentJourney/YourContactDetailsView/YourContactDetailsView'
  ),
);
const OtherContactDetailsView = loadable(() =>
  import(
    /* webpackChunkName: "OtherContactDetailsView" */ './components/views/paymentJourney/OtherContactDetailsView/OtherContactDetailsView'
  ),
);
const OrderSuccessView = loadable(() =>
  import(
    /* webpackChunkName: "OrderSuccessView" */ './components/views/payments/OrderSuccessView/OrderSuccessView'
  ),
);
const OrderDeclinedView = loadable(() =>
  import(
    /* webpackChunkName: "OrderDeclinedView" */ './components/views/paymentJourney/OrderDeclinedView/OrderDeclinedView'
  ),
);
const OrderExpiredView = loadable(() =>
  import(
    /* webpackChunkName: "OrderExpiredView" */ './components/views/paymentJourney/OrderExpiredView/OrderExpiredView'
  ),
);
const OrderNotFoundView = loadable(() =>
  import(
    /* webpackChunkName: "OrderNotFoundView" */ './components/views/paymentJourney/OrderNotFoundView/OrderNotFoundView'
  ),
);

/**
 * Payment journey
 */
const SelectPackage = loadable(() =>
  import(
    /* webpackChunkName: "SelectPackage" */ './components/paymentJourneyRedesign/pages/SelectPackage'
  ),
);
const Basket = loadable(() =>
  import(
    /* webpackChunkName: "Basket" */ './components/paymentJourneyRedesign/pages/Basket'
  ),
);
const SocialCheckout = loadable(() =>
  import(
    /* webpackChunkName: "SocialCheckout" */ './components/paymentJourneyRedesign/pages/SocialCheckout'
  ),
);
const ConfirmPurchase = loadable(() =>
  import(
    /* webpackChunkName: "ConfirmPurchase" */ './components/paymentJourneyRedesign/pages/ConfirmPurchase'
  ),
);
const OrderSuccess = loadable(() =>
  import(
    /* webpackChunkName: "OrderSuccess" */ './components/paymentJourneyRedesign/pages/OrderSuccess'
  ),
);
const OrderExpired = loadable(() =>
  import(
    /* webpackChunkName: "OrderExpired" */ './components/paymentJourneyRedesign/pages/errors/OrderExpired'
  ),
);

const PaymentDeclined = loadable(() =>
  import(
    /* webpackChunkName: "PaymentDeclined" */ './components/paymentJourneyRedesign/pages/errors/PaymentDeclined'
  ),
);

const OrderNotFound = loadable(() =>
  import(
    /* webpackChunkName: "OrderNotFound" */ './components/paymentJourneyRedesign/pages/errors/OrderNotFound'
  ),
);

function processPurchaseCookie(req) {
  req.universalCookies.remove('basket_id', {
    path: req.siteConfig.cookieSitePath,
    expires: new Date(Date.now() + 86400000),
    secure: canCookieBeSecure(),
  });

  req.universalCookies.remove('skip_delegates', {
    path: req.siteConfig.cookieSitePath,
    expires: new Date(Date.now() + 86400000),
    secure: canCookieBeSecure(),
  });

  req.universalCookies.remove('basket_order', {
    path: req.siteConfig.cookieSitePath,
    expires: new Date(Date.now() + 86400000),
    secure: canCookieBeSecure(),
  });
}

function processSitePurchaseResponse(data, req) {
  const { statusCode, customBookingUrl } = data;
  const lng = req.params.lng ? '/' + req.params.lng : '';
  const { sitePath } = req.siteConfig;
  const path = sitePath === '/' ? '' : `/${sitePath}`;

  if (statusCode === 303) {
    return {
      redirectStatusCode: statusCode,
      redirectUrl: customBookingUrl,
    };
  }

  if (statusCode === 403) {
    return {
      redirectStatusCode: 302,
      redirectUrl: `${path}${lng}/purchase/confirm-purchase/`,
    };
  }

  if (statusCode === 400 || statusCode === 404) {
    return {
      redirectStatusCode: 302,
      redirectUrl: `${path}${lng}/purchase/not-found/`,
    };
  }

  if (statusCode === 410) {
    return {
      redirectStatusCode: 302,
      redirectUrl: `${path}${lng}/purchase/expired/`,
    };
  }
}

function getIPAddress(req) {
  return req.get('Fastly-Client-IP');
}

const brandHubNotLivePageRedirect = function (store, req) {
  const { sitePath = '/' } = req.params;
  const pageStatusCode = store.getState().brandHub.page.statusCode;

  if (pageStatusCode === 307) {
    return {
      redirectStatusCode: pageStatusCode,
      redirectUrl: `/${sitePath}/`.replace(/\/+/, '/'),
    };
  }
};

const audienceHubNotLivePageRedirect = function (store, req) {
  const { brandPath = '/', sitePath = '/' } = req.params;
  const pageStatusCode = store.getState().audienceHUB.page.statusCode;

  if (pageStatusCode === 307) {
    return {
      redirectStatusCode: pageStatusCode,
      redirectUrl: (brandPath
        ? `/${brandPath}/${sitePath}/`
        : `/${sitePath}/`
      ).replace(/\/+/, '/'),
    };
  }
};

const knect365Routes = [
  {
    path: '/account/login',
    exact: true,
    type: 'public',
    oneYearCache: true,
    component: SignInView,
    fetchInitialData(store) {
      return Promise.all([store.dispatch(fetchHubAction())]);
    },
  },
  {
    path: '/account/register',
    exact: true,
    type: 'public',
    oneYearCache: true,
    component: SignUpView,
    fetchInitialData(store) {
      return Promise.all([store.dispatch(fetchHubAction())]);
    },
  },
  {
    path: '/account/forgotten-password',
    exact: true,
    type: 'public',
    oneYearCache: true,
    component: AccountForgottenPasswordView,
    fetchInitialData(store) {
      return Promise.all([store.dispatch(fetchHubAction())]);
    },
  },
  {
    path: '/account/profile',
    exact: true,
    type: 'private',
    oneYearCache: true,
    component: ProfileView,
    fetchInitialData(store) {
      return Promise.all([store.dispatch(fetchHubAction())]);
    },
  },
  {
    path: '/account/profile-edit',
    exact: true,
    type: 'private',
    oneYearCache: true,
    component: EditProfileView,
    fetchInitialData(store) {
      return Promise.all([store.dispatch(fetchHubAction())]);
    },
  },
  {
    path: '/account/activated',
    exact: true,
    oneYearCache: true,
    component: AccountActivatedView,
    fetchInitialData(store) {
      return Promise.all([store.dispatch(fetchHubAction())]);
    },
  },
];

const portfolioRoutes = [
  {
    path: '/',
    exact: true,
    component: HubPortfolioView,
    fetchInitialData(store) {
      return Promise.all([
        store.dispatch(fetchHubAction()),
        store.dispatch(fetchPortfolioAction()),
      ]);
    },
  },
];

const staticRoutes = [
  {
    path: '/account/login',
    type: 'public',
    exact: true,
    oneYearCache: true,
    component: BrandLogin,
    fetchInitialData(store, req) {
      const brandHubId = req.siteConfig.tenantConfig.brandHubId;

      return Promise.all([
        store.dispatch(fetchBrandHubLayout({ brandHubId })),
        store.dispatch(fetchBrandHubOptions({ brandHubId })),
      ]);
    },
  },
  {
    path: '/account/forgotten-password',
    type: 'public',
    exact: true,
    oneYearCache: true,
    component: BrandForgottenPassword,
    fetchInitialData(store, req) {
      const brandHubId = req.siteConfig.tenantConfig.brandHubId;

      return Promise.all([
        store.dispatch(fetchBrandHubLayout({ brandHubId })),
        store.dispatch(fetchBrandHubOptions({ brandHubId })),
      ]);
    },
  },
  {
    path: '/account/forgotten-password/sent',
    type: 'public',
    exact: true,
    noCache: true,
    component: BrandForgottenPasswordSent,
    fetchInitialData(store, req) {
      const brandHubId = req.siteConfig.tenantConfig.brandHubId;

      return Promise.all([
        store.dispatch(fetchBrandHubLayout({ brandHubId })),
        store.dispatch(fetchBrandHubOptions({ brandHubId })),
      ]);
    },
  },
  {
    path: '/account/register',
    type: 'public',
    exact: true,
    oneYearCache: true,
    component: BrandRegister,
    fetchInitialData(store, req) {
      const brandHubId = req.siteConfig.tenantConfig.brandHubId;

      return Promise.all([
        store.dispatch(fetchBrandHubLayout({ brandHubId })),
        store.dispatch(fetchBrandHubOptions({ brandHubId })),
      ]);
    },
  },
  {
    path: '/account/register/success',
    type: 'public',
    exact: true,
    noCache: true,
    component: BrandRegisterSuccess,
    fetchInitialData(store, req) {
      const brandHubId = req.siteConfig.tenantConfig.brandHubId;

      return Promise.all([
        store.dispatch(fetchBrandHubLayout({ brandHubId })),
        store.dispatch(fetchBrandHubOptions({ brandHubId })),
      ]);
    },
  },
  {
    path: '/account/activated',
    type: 'public',
    exact: true,
    oneYearCache: true,
    component: BrandAccountActivated,
    fetchInitialData(store, req) {
      const brandHubId = req.siteConfig.tenantConfig.brandHubId;

      return Promise.all([
        store.dispatch(fetchBrandHubLayout({ brandHubId })),
        store.dispatch(fetchBrandHubOptions({ brandHubId })),
      ]);
    },
  },
  {
    path: '/account/profile',
    type: 'private',
    exact: true,
    noCache: true,
    component: BrandProfile,
    fetchInitialData(store, req) {
      const brandHubId = req.siteConfig.tenantConfig.brandHubId;

      return Promise.all([
        store.dispatch(fetchBrandHubLayout({ brandHubId })),
        store.dispatch(fetchBrandHubOptions({ brandHubId })),
      ]);
    },
  },
  {
    path: '/account/profile/edit',
    type: 'private',
    exact: true,
    noCache: true,
    component: BrandEditProfile,
    fetchInitialData(store, req) {
      const brandHubId = req.siteConfig.tenantConfig.brandHubId;

      return Promise.all([
        store.dispatch(fetchBrandHubLayout({ brandHubId })),
        store.dispatch(fetchBrandHubOptions({ brandHubId })),
      ]);
    },
  },
];

const brandHubRoutes = [
  {
    path: '/:sitePath',
    exact: true,
    component: BrandHubHome,
    fetchInitialData(store) {
      const {
        siteType: {
          data: { id: brandHubId },
        },
      } = store.getState();

      return Promise.all([
        store.dispatch(fetchBrandHubHomePage({ brandHubId })),
        store.dispatch(fetchBrandHubOptions({ brandHubId })),
      ]);
    },
  },
  {
    path: '/:sitePath/search',
    exact: true,
    component: BrandHubSearch,
    async fetchInitialData(store, req) {
      const {
        siteType: {
          data: { id: brandHubId },
        },
      } = store.getState();

      await Promise.all([
        store.dispatch(
          fetchBrandHubBasePage({ brandHubId, pagePath: 'search' }),
        ),
        store.dispatch(fetchBrandHubOptions({ brandHubId })),
      ]);

      const {
        brandHub: {
          page: {
            data: { page: { details: { searchQueryData } = {} } = {} },
          },
          options: {
            data: { subBrandName: brandName },
          },
        },
      } = store.getState();

      const { search } = req.locationConfig;

      const queryObject = qs.parse(search, {
        ignoreQueryPrefix: true,
      });

      return Promise.all([
        store.dispatch(
          fetchHubPageDiscovery({
            values: {
              ...queryObject,
              ...searchQueryData,
              subBrands: [brandName],
              availableSubBrands: [brandName],
              page: 1,
              count: 20,
            },
            pageName: 'search',
          }),
        ),
      ]);
    },
    postFetchDataActions(store, req) {
      return brandHubNotLivePageRedirect(store, req);
    },
  },
  {
    path: '/:sitePath/events',
    exact: true,
    component: BrandHubEvents,
    async fetchInitialData(store, req) {
      const {
        siteType: {
          data: { id: brandHubId },
        },
      } = store.getState();

      await Promise.all([
        store.dispatch(
          fetchBrandHubBasePage({ brandHubId, pagePath: 'events' }),
        ),
        store.dispatch(fetchBrandHubOptions({ brandHubId })),
      ]);

      const {
        brandHub: {
          page: {
            data: {
              page: {
                details: {
                  searchQueryData,
                  searchQueryData: {
                    subBrands = [],
                    facetsConfig: { subBrandsEnabled } = {},
                  } = {},
                } = {},
              } = {},
            },
          },
          options: {
            data: { subBrandName: brandName },
          },
        },
      } = store.getState();

      const { search } = req.locationConfig;

      const queryObject = qs.parse(search, {
        ignoreQueryPrefix: true,
      });

      const subBrandsFilters = subBrands.length > 0 ? subBrands : [brandName];

      return Promise.all([
        store.dispatch(
          fetchHubPageDiscovery({
            values: {
              ...queryObject,
              ...searchQueryData,
              subBrands:
                subBrandsEnabled && subBrands.length ? subBrands : [brandName],
              availableSubBrands: subBrandsEnabled
                ? subBrandsFilters
                : [brandName],
              page: 1,
              count: 20,
            },
            pageName: 'events',
          }),
        ),
      ]);
    },
    postFetchDataActions(store, req) {
      return brandHubNotLivePageRedirect(store, req);
    },
  },
  {
    path: '/:sitePath/articles',
    exact: true,
    component: BrandHubArticles,
    async fetchInitialData(store, req) {
      const {
        siteType: {
          data: { id: brandHubId },
        },
      } = store.getState();

      await Promise.all([
        store.dispatch(
          fetchBrandHubBasePage({ brandHubId, pagePath: 'articles' }),
        ),
        store.dispatch(fetchBrandHubOptions({ brandHubId })),
      ]);

      const {
        brandHub: {
          page: {
            data: {
              page: {
                details: {
                  searchQueryData,
                  searchQueryData: {
                    subBrands = [],
                    facetsConfig: { subBrandsEnabled } = {},
                  } = {},
                } = {},
              } = {},
            },
          },
          options: {
            data: { subBrandName: brandName },
          },
        },
      } = store.getState();

      const { search } = req.locationConfig;

      const queryObject = qs.parse(search, {
        ignoreQueryPrefix: true,
      });
      const availableSubBrands =
        subBrandsEnabled && subBrands.length ? subBrands : [brandName];

      return Promise.all([
        store.dispatch(
          fetchHubPageDiscovery({
            values: {
              ...queryObject,
              ...searchQueryData,
              subBrands: availableSubBrands,
              availableSubBrands,
              page: 1,
              count: 20,
            },
            pageName: 'articles',
          }),
        ),
      ]);
    },
    postFetchDataActions(store, req) {
      return brandHubNotLivePageRedirect(store, req);
    },
  },
  {
    path: '/:sitePath/courses',
    exact: true,
    component: BrandHubCourses,
    async fetchInitialData(store, req) {
      const {
        siteType: {
          data: { id: brandHubId },
        },
      } = store.getState();

      await Promise.all([
        store.dispatch(
          fetchBrandHubBasePage({ brandHubId, pagePath: 'courses' }),
        ),
        store.dispatch(fetchBrandHubOptions({ brandHubId })),
      ]);

      const {
        brandHub: {
          page: {
            data: {
              page: {
                details: {
                  searchQueryData,
                  searchQueryData: {
                    subBrands = [],
                    facetsConfig: { subBrandsEnabled } = {},
                  } = {},
                } = {},
              } = {},
            },
          },
          options: {
            data: { subBrandName: brandName },
          },
        },
      } = store.getState();

      const { search } = req.locationConfig;

      const queryObject = qs.parse(search, {
        ignoreQueryPrefix: true,
      });

      const subBrandsFilters = subBrands.length > 0 ? subBrands : [brandName];

      return Promise.all([
        store.dispatch(
          fetchHubPageDiscovery({
            values: {
              ...queryObject,
              ...searchQueryData,
              subBrands:
                subBrandsEnabled && subBrands.length ? subBrands : [brandName],
              availableSubBrands: subBrandsEnabled
                ? subBrandsFilters
                : [brandName],
              page: 1,
              count: 20,
            },
            pageName: 'courses',
          }),
        ),
      ]).catch((err) => console.error(err));
    },
    postFetchDataActions(store, req) {
      return brandHubNotLivePageRedirect(store, req);
    },
  },
  {
    path: '/:sitePath/videos',
    exact: true,
    component: BrandHubStreamlyVideos,
    async fetchInitialData(store, req) {
      const {
        siteType: {
          data: { id: brandHubId },
        },
      } = store.getState();

      await Promise.all([
        store.dispatch(
          fetchBrandHubBasePage({ brandHubId, pagePath: 'videos' }),
        ),
        store.dispatch(fetchBrandHubOptions({ brandHubId })),
      ]);

      const {
        brandHub: {
          page: {
            data: { page: { details: { searchQueryData } = {} } = {} },
          },
        },
      } = store.getState();

      const { search } = req.locationConfig;

      const queryObject = qs.parse(search, {
        ignoreQueryPrefix: true,
      });

      return Promise.all([
        store.dispatch(
          fetchHubPageDiscovery({
            values: {
              ...queryObject,
              ...searchQueryData,
              page: 1,
              count: 20,
            },
            pageName: 'streamly-videos',
          }),
        ),
      ]);
    },
    postFetchDataActions(store, req) {
      return brandHubNotLivePageRedirect(store, req);
    },
  },
  {
    path: '/:sitePath/:landingPagePath/*',
    exact: true,
    component: BrandHubLanding,
    async fetchInitialData(store, req) {
      const { landingPagePath } = req.params;
      const {
        siteType: {
          data: { id: brandHubId },
        },
      } = store.getState();

      await Promise.all([
        store.dispatch(
          fetchBrandHubLandingPage({
            brandHubId,
            pagePath: encodeURIComponent(landingPagePath),
          }),
        ),
        store.dispatch(fetchBrandHubOptions({ brandHubId })),
      ]);

      if (store.getState().brandHub.page.statusCode === 404) {
        await store.dispatch(fetchBrandHubLayout({ brandHubId }));
      }

      return Promise.all([]);
    },
  },
];

const audienceHubRoutes = [
  {
    path: '/:brandPath/:sitePath',
    exact: true,
    component: AudienceHubHome,
    fetchInitialData(store) {
      const {
        siteType: {
          data: { id: audienceHubId },
        },
      } = store.getState();

      return Promise.all([
        store.dispatch(fetchAudienceHubHomePage({ audienceHubId })),
        store.dispatch(fetchAudienceHubOptions({ audienceHubId })),
      ]);
    },
  },
  {
    path: '/:brandPath/:sitePath/search',
    exact: true,
    component: AudienceHubSearch,
    async fetchInitialData(store, req) {
      const {
        siteType: {
          data: { id: audienceHubId },
        },
      } = store.getState();

      await Promise.all([
        store.dispatch(
          fetchAudienceHubBasePage({ audienceHubId, pagePath: 'search' }),
        ),
        store.dispatch(fetchAudienceHubOptions({ audienceHubId })),
      ]);

      const {
        audienceHUB: {
          page: {
            data: { page: { details: { searchQueryData } = {} } = {} },
          },
          options: {
            data: { subBrandName: brandName, l2Topic: topicName },
          },
        },
      } = store.getState();

      const { search } = req.locationConfig;

      const queryObject = qs.parse(search, {
        ignoreQueryPrefix: true,
      });

      return Promise.all([
        store.dispatch(
          fetchHubPageDiscovery({
            values: {
              ...queryObject,
              ...searchQueryData,
              subBrands: [brandName],
              mainFilterTopic: topicName,
              page: 1,
              count: 20,
            },
            pageName: 'search',
          }),
        ),
      ]);
    },
    postFetchDataActions(store, req) {
      return audienceHubNotLivePageRedirect(store, req);
    },
  },
  {
    path: '/:brandPath/:sitePath/events',
    exact: true,
    component: AudienceHubEvents,
    async fetchInitialData(store, req) {
      const {
        siteType: {
          data: { id: audienceHubId },
        },
      } = store.getState();

      await Promise.all([
        store.dispatch(
          fetchAudienceHubBasePage({ audienceHubId, pagePath: 'events' }),
        ),
        store.dispatch(fetchAudienceHubOptions({ audienceHubId })),
      ]);

      const {
        audienceHUB: {
          page: {
            data: { page: { details: { searchQueryData } = {} } = {} },
          },
          options: {
            data: { subBrandName: brandName, l2Topic: topicName },
          },
        },
      } = store.getState();

      const { search } = req.locationConfig;

      const queryObject = qs.parse(search, {
        ignoreQueryPrefix: true,
      });

      return Promise.all([
        store.dispatch(
          fetchHubPageDiscovery({
            values: {
              ...queryObject,
              ...searchQueryData,
              subBrands: [brandName],
              mainFilterTopic: topicName,
              page: 1,
              count: 20,
            },
            pageName: 'events',
          }),
        ),
      ]);
    },
    postFetchDataActions(store, req) {
      return audienceHubNotLivePageRedirect(store, req);
    },
  },
  {
    path: '/:brandPath/:sitePath/articles',
    exact: true,
    component: AudienceHubArticles,
    async fetchInitialData(store, req) {
      const {
        siteType: {
          data: { id: audienceHubId },
        },
      } = store.getState();

      await Promise.all([
        store.dispatch(
          fetchAudienceHubBasePage({ audienceHubId, pagePath: 'articles' }),
        ),
        store.dispatch(fetchAudienceHubOptions({ audienceHubId })),
      ]);

      const {
        audienceHUB: {
          page: {
            data: { page: { details: { searchQueryData } = {} } = {} },
          },
          options: {
            data: { subBrandName: brandName, l2Topic: topicName },
          },
        },
      } = store.getState();

      const { search } = req.locationConfig;

      const queryObject = qs.parse(search, {
        ignoreQueryPrefix: true,
      });

      return Promise.all([
        store.dispatch(
          fetchHubPageDiscovery({
            values: {
              ...queryObject,
              ...searchQueryData,
              subBrands: [brandName],
              availableSubBrands: [brandName],
              mainFilterTopic: topicName,
              page: 1,
              count: 20,
            },
            pageName: 'articles',
          }),
        ),
      ]);
    },
    postFetchDataActions(store, req) {
      return audienceHubNotLivePageRedirect(store, req);
    },
  },
  {
    path: '/:brandPath/:sitePath/courses',
    exact: true,
    component: AudienceHubCourses,
    async fetchInitialData(store, req) {
      const {
        siteType: {
          data: { id: audienceHubId },
        },
      } = store.getState();

      await Promise.all([
        store.dispatch(
          fetchAudienceHubBasePage({ audienceHubId, pagePath: 'courses' }),
        ),
        store.dispatch(fetchAudienceHubOptions({ audienceHubId })),
      ]);

      const {
        audienceHUB: {
          page: {
            data: { page: { details: { searchQueryData } = {} } = {} },
          },
          options: {
            data: { subBrandName: brandName, l2Topic: topicName },
          },
        },
      } = store.getState();

      const { search } = req.locationConfig;

      const queryObject = qs.parse(search, {
        ignoreQueryPrefix: true,
      });

      return Promise.all([
        store.dispatch(
          fetchHubPageDiscovery({
            values: {
              ...queryObject,
              ...searchQueryData,
              subBrands: [brandName],
              mainFilterTopic: topicName,
              page: 1,
              count: 20,
            },
            pageName: 'courses',
          }),
        ),
      ]);
    },
    postFetchDataActions(store, req) {
      return audienceHubNotLivePageRedirect(store, req);
    },
  },
  {
    path: '/:brandPath/:sitePath/videos',
    exact: true,
    component: AudienceHubStreamlyVideos,
    async fetchInitialData(store, req) {
      const {
        siteType: {
          data: { id: audienceHubId },
        },
      } = store.getState();

      await Promise.all([
        store.dispatch(
          fetchAudienceHubBasePage({
            audienceHubId,
            pagePath: 'videos',
          }),
        ),
        store.dispatch(fetchAudienceHubOptions({ audienceHubId })),
      ]);

      const {
        audienceHUB: {
          page: {
            data: { page: { details: { searchQueryData } = {} } = {} },
          },
          options: {
            data: { l2Topic: topicName },
          },
        },
      } = store.getState();

      const { search } = req.locationConfig;

      const queryObject = qs.parse(search, {
        ignoreQueryPrefix: true,
      });

      return Promise.all([
        store.dispatch(
          fetchHubPageDiscovery({
            values: {
              ...queryObject,
              ...searchQueryData,
              mainFilterTopic: topicName,
              page: 1,
              count: 20,
            },
            pageName: 'streamly-videos',
          }),
        ),
      ]);
    },
    postFetchDataActions(store, req) {
      return audienceHubNotLivePageRedirect(store, req);
    },
  },
  {
    path: '/:brandPath/:sitePath/:landingPagePath/*',
    exact: true,
    component: AudienceHubLanding,
    async fetchInitialData(store, req) {
      const { landingPagePath } = req.params;
      const {
        siteType: {
          data: { id: audienceHubId },
        },
      } = store.getState();

      await Promise.all([
        store.dispatch(
          fetchAudienceHubLandingPage({
            audienceHubId,
            pagePath: encodeURIComponent(landingPagePath),
          }),
        ),
        store.dispatch(fetchAudienceHubOptions({ audienceHubId })),
      ]);

      if (store.getState().audienceHUB.page.statusCode === 404) {
        await store.dispatch(fetchAudienceHubLayout({ audienceHubId }));
      }

      return Promise.all([]);
    },
  },
];

const industryRoutes = [
  {
    path: '/:sitePath',
    exact: true,
    component: IndustryHome,
    fetchInitialData(store, req) {
      const {
        pageConfig: { siteId },
      } = store.getState();

      const brandHubId = req.siteConfig.tenantConfig.brandHubId;

      return Promise.all([
        store.dispatch(fetchIndustryHome({ siteId })),
        store.dispatch(fetchIndustryHeader({ siteId })),
        store.dispatch(fetchBrandHubLayout({ brandHubId })),
        store.dispatch(fetchBrandHubOptions({ brandHubId })),
      ]);
    },
  },
];

const audienceRoutes = [
  {
    path: '/:brandSubPath/:sitePath',
    exact: true,
    component: AudienceHomeView,
    fetchInitialData(store) {
      return Promise.all([
        store.dispatch(fetchAudienceHubAction()),
        store.dispatch(fetchAudienceHeaderAction()),
      ]);
    },
  },
  {
    path: '/:brandSubPath/:sitePath/events',
    exact: true,
    component: AudienceEventsView,
    fetchInitialData(store) {
      return Promise.all([
        store.dispatch(fetchAudienceHeaderAction()),
        store.dispatch(fetchAudienceEventsAction()),
      ]);
    },
  },
  {
    path: '/:brandSubPath/:sitePath/articles',
    exact: true,
    component: AudienceArticlesView,
    fetchInitialData(store) {
      return Promise.all([
        store.dispatch(fetchAudienceHeaderAction()),
        store.dispatch(fetchAudienceArticlesAction()),
      ]);
    },
  },
  {
    path: '/:brandSubPath/:sitePath/courses',
    exact: true,
    component: AudienceCoursesView,
    fetchInitialData(store) {
      return Promise.all([
        store.dispatch(fetchAudienceHeaderAction()),
        store.dispatch(fetchAudienceCoursesAction()),
      ]);
    },
  },
];

const articleRoutes = [
  {
    path: '/:sitePath',
    exact: true,
    noCache: true,
    component: AudienceHubArticle,
    fetchInitialData(store, req) {
      const {
        siteType: {
          data: { id: articleId },
        },
        options: {
          data: { communityId: audienceHubId, productCode },
        },
        pageConfig: {
          tenantId,
          tenantConfig: { domain },
        },
      } = store.getState();

      const accessToken = req.universalCookies.get('access_token', {
        path: '/',
        domain: `.${domain}`,
      });
      const query = { product_code: productCode };

      if (accessToken) {
        query.token = accessToken;
        query.username = jwtDecode(accessToken)['cognito:username'];
      }

      const AAAServiceAvailable =
        clientConfig.api.services.AAAService[tenantId]?.enabled;

      return Promise.all(
        [
          store.dispatch(fetchArticleHome({ articleId })),
          store.dispatch(fetchAudienceHubLayout({ audienceHubId })),
          store.dispatch(fetchAudienceHubOptions({ audienceHubId })),
          AAAServiceAvailable &&
            store.dispatch(
              fetchLeadGenFormAction({
                leadGenType: 'article',
                leadGenId: articleId,
                queryParams: query,
              }),
            ),
        ].filter(Boolean),
      );
    },
    postFetchDataActions(store, req, res) {
      const state = store.getState();

      if (state.leadGenForm.loaded) {
        store.dispatch(setArticleGated(true));
        store.dispatch(removeArticleBody());
      } else {
        store.dispatch(setArticleGated(false));
      }
    },
  },
];

const legacyArticleRoutes = [
  {
    path: '/:sitePath',
    exact: true,
    noCache: true,
    leadGenType: 'article',
    component: ArticleHomeView,
    fetchInitialData(store, req) {
      const state = store.getState();
      const tenantId = state.pageConfig.tenantId;
      const initialRequests = [
        store.dispatch(fetchArticleHomeAction()),
        store.dispatch(
          fetchAudienceHeaderAction(state.options.data.communityId),
        ),
      ];

      // If AAAService is not enabled LeadGen will not be available also
      if (clientConfig.api.services.AAAService[tenantId]?.enabled) {
        const leadGenId = req.siteConfig.siteId;
        const leadGenType = req.activeRoute.leadGenType;
        const accessToken = req.universalCookies.get('access_token', {
          path: '/',
          domain: '.' + req.siteConfig.tenantConfig.domain,
        });
        const queryParams = {
          product_code: state.options.data.productCode,
        };

        if (accessToken) {
          queryParams.token = accessToken;
          queryParams.username = jwtDecode(accessToken)['cognito:username'];
        }

        initialRequests.push(
          store.dispatch(
            fetchLeadGenFormAction({ leadGenType, leadGenId, queryParams }),
          ),
        );
      }

      return Promise.all(initialRequests);
    },
    postFetchDataActions(store, req, res) {
      const state = store.getState();

      req.reduxStore.dispatch(
        updatePageConfigAction({
          communityId: state.options.data.communityId,
        }),
      );

      if (state.leadGenForm.loaded) {
        const portfolio = state.articleHome.data.portfolio;

        store.dispatch(removeArticleHomeAction());
        store.dispatch(setPortfolioAction(portfolio));
        store.dispatch(setArticleGatedAction(true));
      } else {
        store.dispatch(setArticleGatedAction(false));
      }
    },
  },
];

const paymentRoutes = [
  {
    path: '/:sitePath/:lng/purchase/select-package',
    exact: true,
    noCache: true,
    component: SelectPackage,
    async fetchInitialData(store, req) {
      const {
        siteType: {
          data: { type: siteType },
        },
        pageConfig: { cookieSitePath },
      } = store.getState();
      const { search } = req.locationConfig;
      const { vip_code: vipCodeFromQuery } = qs.parse(search, {
        ignoreQueryPrefix: true,
      });
      const vipCodeFromCookies = req.universalCookies.get('vip_code');
      const vipCodeValue = vipCodeFromQuery || vipCodeFromCookies;
      const isBasketOrder = req.universalCookies.get('basket_order');
      const isCourse = SiteTypes.COURSE === siteType;
      let basketId = req.universalCookies.get('basket_id');

      if (!basketId) {
        basketId = uuid();

        req.universalCookies.set('basket_id', basketId, {
          path: req.siteConfig.cookieSitePath,
          expires: new Date(Date.now() + 86400000),
          secure: canCookieBeSecure(),
        });
      }

      if (vipCodeFromQuery) {
        req.universalCookies.set('vip_code', vipCodeFromQuery, {
          expires: new Date(Date.now() + 86400000),
          path: cookieSitePath,
        });
      }

      if (isBasketOrder && vipCodeValue) {
        await store.dispatch(
          updateSitePricingVIPCode({
            basketId,
            code: vipCodeValue,
          }),
        );
      }

      // Initial fetching products and options data to create default values for the Package Form
      await store.dispatch(
        fetchSitePricingProducts({
          basketId,
        }),
      );
      isCourse &&
        (await store.dispatch(fetchCoursePricingOptions({ basketId })));

      const {
        pricing: {
          products: {
            data: { data: productsData },
          },
          courseOptions: {
            data: { data: courseOptionsData },
          },
        },
      } = store.getState();

      const formValues = getPackageFormDefaultValues({
        productsData,
        courseOptions: courseOptionsData.options,
        vipCode: vipCodeValue,
      });

      return Promise.all([
        store.dispatch(fetchSiteLayoutAction()),
        store.dispatch(
          fetchSitePricingProducts(
            getFetchPricingProductsParams({
              basketId,
              formValues,
            }),
          ),
        ),
      ]);
    },
    postFetchDataActions(store, req) {
      const {
        pricing: { products },
      } = store.getState();

      return processSitePurchaseResponse(products, req);
    },
  },
  {
    path: '/:sitePath/:lng/purchase/view-basket',
    exact: true,
    noCache: true,
    component: Basket,
    fetchInitialData(store, req) {
      const { search } = req.locationConfig;
      const { storedId } = qs.parse(search, {
        ignoreQueryPrefix: true,
      });

      const fetchSitePackages = () => {
        if (storedId) {
          return fetchSitePricingSavedPackages({ storedId });
        }

        const basketId = req.universalCookies.get('basket_id');

        return fetchSitePricingPackages({ basketId });
      };

      return Promise.all([
        store.dispatch(fetchSiteLayoutAction()),
        store.dispatch(fetchSitePackages()),
      ]);
    },
    postFetchDataActions(store, req) {
      const {
        pricing: {
          packages,
          packages: { data: { meta: { basketId } = {} } = {} } = {},
        } = {},
      } = store.getState();

      if (basketId) {
        req.universalCookies.set('basket_id', basketId, {
          path: req.siteConfig.cookieSitePath,
          expires: new Date(Date.now() + 86400000),
          secure: canCookieBeSecure(),
        });
      }

      return processSitePurchaseResponse(packages, req);
    },
  },
  {
    path: '/:sitePath/:lng/purchase/confirm-purchase',
    exact: true,
    noCache: true,
    component: ConfirmPurchase,
    fetchInitialData(store, req) {
      const basketId = req.universalCookies.get('basket_id');

      return Promise.all([
        store.dispatch(fetchSiteLayoutAction()),
        store.dispatch(fetchSitePricingPackages({ basketId })),
        store.dispatch(fetchSitePricingCheckoutDetails({ basketId })),
      ]);
    },
    postFetchDataActions(store, req) {
      const {
        pricing: { packages },
      } = store.getState();

      store.dispatch(
        updatePageConfigAction({
          ipAddress: getIPAddress(req),
        }),
      );

      return processSitePurchaseResponse(packages, req);
    },
  },
  {
    path: '/:sitePath/:lng/purchase/social-checkout',
    exact: true,
    noCache: true,
    component: SocialCheckout,
    fetchInitialData(store, req) {
      const basketId = req.universalCookies.get('basket_id');

      return Promise.all([
        store.dispatch(fetchSiteLayoutAction()),
        store.dispatch(fetchSitePricingPackages({ basketId })),
      ]);
    },
    postFetchDataActions(store, req) {
      const {
        pricing: { packages },
      } = store.getState();

      return processSitePurchaseResponse(packages, req);
    },
  },
  {
    path: '/:sitePath/:lng/purchase/receipt/:orderId',
    exact: true,
    noCache: true,
    component: OrderSuccess,
    fetchInitialData(store, req) {
      const state = store.getState();
      const { siteType, siteTypePath, siteId } = state.pageConfig;

      const fetchActions = [
        store.dispatch(fetchSiteLayoutAction()),
        store.dispatch(
          fetchSitePricingOrderReceipt({ orderId: req.params.orderId }),
        ),
      ];

      if (siteType === SiteTypes.EVENT) {
        fetchActions.push(
          store.dispatch(fetchSitePricingContent({ siteTypePath, siteId })),
        );
      }

      return Promise.all(fetchActions);
    },
    postFetchDataActions(store, req) {
      const state = store.getState();
      const lng = req.params.lng ? '/' + req.params.lng : '';

      if (state.pricing.orderReceipt.statusCode === 404) {
        return {
          redirectStatusCode: 302,
          redirectUrl: `/${req.siteConfig.sitePath}${lng}/purchase/expired/`,
        };
      }

      processPurchaseCookie(req);
    },
  },
  {
    path: '/:sitePath/:lng/purchase/expired',
    exact: true,
    noCache: true,
    component: OrderExpired,
    fetchInitialData(store) {
      return Promise.all([store.dispatch(fetchSiteLayoutAction())]);
    },
    postFetchDataActions(store, req) {
      processPurchaseCookie(req);
    },
  },
  {
    path: '/:sitePath/:lng/purchase/declined',
    exact: true,
    noCache: true,
    component: PaymentDeclined,
    fetchInitialData(store, req) {
      const basketId = req.universalCookies.get('basket_id');

      return Promise.all([
        store.dispatch(fetchSiteLayoutAction()),
        store.dispatch(fetchChosenProductsAction(basketId)),
      ]);
    },
    postFetchDataActions(store, req) {
      const state = store.getState();
      const lng = req.params.lng ? '/' + req.params.lng : '';

      if (state.chosenProducts.statusCode === 410) {
        return {
          redirectStatusCode: 302,
          redirectUrl: `/${req.siteConfig.sitePath}${lng}/purchase/expired/`,
        };
      }
    },
  },
  {
    path: '/:sitePath/:lng/purchase/not-found',
    exact: true,
    noCache: true,
    component: OrderNotFound,
    fetchInitialData(store) {
      return Promise.all([store.dispatch(fetchSiteLayoutAction())]);
    },
    postFetchDataActions(store, req) {
      processPurchaseCookie(req);
    },
  },
];

const legacyPaymentRoutes = [
  {
    path: '/:sitePath/:lng/purchase/select-package',
    exact: true,
    noCache: true,
    component: SelectPackageView,
    fetchInitialData(store, req) {
      let basketId = req.universalCookies.get('basket_id');

      if (!basketId) {
        basketId = uuid();

        req.universalCookies.set('basket_id', basketId, {
          path: req.siteConfig.cookieSitePath,
          expires: new Date(Date.now() + 86400000),
          secure: canCookieBeSecure(),
        });
      }

      return Promise.all([
        store.dispatch(fetchSiteLayoutAction()),
        store.dispatch(fetchSiteProductsAction(basketId)),
      ]);
    },
    postFetchDataActions(store, req) {
      if (req.query.skipDelegates === 'true') {
        req.universalCookies.set('skip_delegates', 'true', {
          path: req.siteConfig.cookieSitePath,
          expires: new Date(Date.now() + 86400000),
          secure: canCookieBeSecure(),
        });
      }

      return processSitePurchaseResponse(store.getState().siteProducts, req);
    },
  },
  {
    path: '/:sitePath/:lng/purchase/view-basket',
    exact: true,
    noCache: true,
    component: BasketView,
    fetchInitialData(store, req) {
      const basketId = req.universalCookies.get('basket_id');

      return Promise.all([
        store.dispatch(fetchSiteLayoutAction()),
        store.dispatch(fetchChosenProductsAction(basketId)),
      ]);
    },
    postFetchDataActions(store, req) {
      return processSitePurchaseResponse(store.getState().chosenProducts, req);
    },
  },
  {
    path: '/:sitePath/:lng/purchase/social-checkout',
    exact: true,
    noCache: true,
    component: SocialCheckoutView,
    fetchInitialData(store, req) {
      const basketId = req.universalCookies.get('basket_id');

      return Promise.all([
        store.dispatch(fetchSiteLayoutAction()),
        store.dispatch(fetchChosenProductsAction(basketId)),
      ]);
    },
    postFetchDataActions(store, req) {
      const state = store.getState();

      if (!state.options.data?.inGoConfig?.enabled) {
        const lng = req.params.lng ? '/' + req.params.lng : '';

        return {
          redirectStatusCode: 302,
          redirectUrl: `/${req.siteConfig.sitePath}${lng}/purchase/billing-details/`,
        };
      }

      return processSitePurchaseResponse(store.getState().chosenProducts, req);
    },
  },
  {
    path: '/:sitePath/:lng/purchase/billing-details',
    exact: true,
    noCache: true,
    component: BillingDetailsView,
    fetchInitialData(store, req) {
      const basketId = req.universalCookies.get('basket_id');

      return Promise.all([
        store.dispatch(fetchSiteLayoutAction()),
        store.dispatch(fetchBillingDetailsAction(basketId)),
        store.dispatch(fetchChosenProductsAction(basketId)),
      ]);
    },
    postFetchDataActions(store, req) {
      return processSitePurchaseResponse(store.getState().billingDetails, req);
    },
  },
  {
    path: '/:sitePath/:lng/purchase/contacts/my',
    exact: true,
    noCache: true,
    component: YourContactDetailsView,
    fetchInitialData(store, req) {
      const basketId = req.universalCookies.get('basket_id');

      return Promise.all([
        store.dispatch(fetchSiteLayoutAction()),
        store.dispatch(fetchSitePartnersAction()),
        store.dispatch(fetchBillingDetailsAction(basketId)),
        store.dispatch(fetchContactDetailsAction(basketId, 'my')),
      ]);
    },
  },
  {
    path: '/:sitePath/:lng/purchase/contacts/other',
    exact: true,
    noCache: true,
    component: OtherContactDetailsView,
    fetchInitialData(store, req) {
      const basketId = req.universalCookies.get('basket_id');
      const contactType = 'other';

      return Promise.all([
        store.dispatch(fetchSiteLayoutAction()),
        store.dispatch(fetchSitePartnersAction()),
        store.dispatch(fetchBillingDetailsAction(basketId)),
        store.dispatch(fetchContactDetailsAction(basketId, contactType)),
      ]);
    },
  },
  {
    path: '/:sitePath/:lng/purchase/delegate-details/package/:packageId/delegate/:delegateId',
    exact: true,
    noCache: true,
    component: DelegateDetailsView,
    fetchInitialData(store, req) {
      const basketId = req.universalCookies.get('basket_id');
      const { packageId, delegateId } = req.params;

      return Promise.all([
        store.dispatch(fetchSiteLayoutAction()),
        store.dispatch(fetchSitePartnersAction()),
        store.dispatch(fetchChosenProductsAction(basketId)),
        store.dispatch(fetchBillingDetailsAction(basketId)),
        store.dispatch(
          fetchDelegateDetailsAction(basketId, packageId, delegateId),
        ),
      ]);
    },
    postFetchDataActions(store, req) {
      return processSitePurchaseResponse(store.getState().delegateDetails, req);
    },
  },
  {
    path: '/:sitePath/:lng/purchase/confirm-purchase',
    exact: true,
    noCache: true,
    component: ConfirmPurchaseView,
    fetchInitialData(store, req) {
      const basketId = req.universalCookies.get('basket_id');

      return Promise.all([
        store.dispatch(fetchSiteLayoutAction()),
        store.dispatch(
          fetchSiteContentAction({ sitePage: 'terms-and-conditions' }),
        ),
        store.dispatch(fetchChosenProductsAction(basketId)),
        store.dispatch(fetchContactDetailsAction(basketId, 'my')),
        store.dispatch(fetchContactDetailsAction(basketId, 'other')),
      ]);
    },
    postFetchDataActions(store, req) {
      store.dispatch(
        updatePageConfigAction({
          ipAddress: getIPAddress(req),
        }),
      );
      return processSitePurchaseResponse(store.getState().chosenProducts, req);
    },
  },
  {
    path: '/:sitePath/:lng/purchase/receipt/:orderId',
    exact: true,
    noCache: true,
    component: OrderSuccessView,
    fetchInitialData(store, req) {
      return Promise.all([
        store.dispatch(fetchSiteLayoutAction()),
        store.dispatch(fetchOrderReceiptAction(req.params.orderId)),
      ]);
    },
    postFetchDataActions(store, req) {
      const state = store.getState();
      const lng = req.params.lng ? '/' + req.params.lng : '';

      if (state.orderReceipt.statusCode === 404) {
        return {
          redirectStatusCode: 302,
          redirectUrl: `/${req.siteConfig.sitePath}${lng}/purchase/expired/`,
        };
      }

      processPurchaseCookie(req);
    },
  },
  {
    path: '/:sitePath/:lng/purchase/expired',
    exact: true,
    noCache: true,
    component: OrderExpiredView,
    fetchInitialData(store) {
      return Promise.all([store.dispatch(fetchSiteLayoutAction())]);
    },
    postFetchDataActions(store, req) {
      processPurchaseCookie(req);
    },
  },
  {
    path: '/:sitePath/:lng/purchase/declined',
    exact: true,
    noCache: true,
    component: OrderDeclinedView,
    fetchInitialData(store, req) {
      const basketId = req.universalCookies.get('basket_id');

      return Promise.all([
        store.dispatch(fetchChosenProductsAction(basketId)),
        store.dispatch(fetchSiteLayoutAction()),
      ]);
    },
    postFetchDataActions(store, req) {
      const state = store.getState();
      const lng = req.params.lng ? '/' + req.params.lng : '';

      if (state.chosenProducts.statusCode === 410) {
        return {
          redirectStatusCode: 302,
          redirectUrl: `/${req.siteConfig.sitePath}${lng}/purchase/expired/`,
        };
      }
    },
  },
  {
    path: '/:sitePath/:lng/purchase/not-found',
    exact: true,
    noCache: true,
    component: OrderNotFoundView,
    fetchInitialData(store) {
      return Promise.all([store.dispatch(fetchSiteLayoutAction())]);
    },
    postFetchDataActions(store, req) {
      processPurchaseCookie(req);
    },
  },
  {
    path: '/:sitePath/:lng/purchase/select-course-option',
    exact: true,
    noCache: true,
    component: SelectCourseOptionView,
    fetchInitialData(store, req) {
      let basketId = req.universalCookies.get('basket_id');

      if (!basketId) {
        basketId = uuid();

        req.universalCookies.set('basket_id', basketId, {
          path: req.siteConfig.cookieSitePath,
          expires: new Date(Date.now() + 86400000),
          secure: canCookieBeSecure(),
        });
      }

      return Promise.all([
        store.dispatch(fetchSiteLayoutAction()),
        store.dispatch(fetchCourseOptionsAction(basketId)),
      ]);
    },
    postFetchDataActions(store, req) {
      return processSitePurchaseResponse(store.getState().courseOptions, req);
    },
  },
  {
    path: '/:sitePath/:lng/purchase/course-option/:optionIndex',
    exact: true,
    noCache: true,
    component: SelectPackageView,
    fetchInitialData(store, req) {
      let basketId = req.universalCookies.get('basket_id');
      const { optionIndex } = req.params;

      if (!basketId) {
        basketId = uuid();

        req.universalCookies.set('basket_id', basketId, {
          path: req.siteConfig.cookieSitePath,
          expires: new Date(Date.now() + 86400000),
          secure: canCookieBeSecure(),
        });
      }

      return Promise.all([
        store.dispatch(fetchSiteLayoutAction()),
        store.dispatch(fetchSiteProductsAction(basketId, {}, optionIndex)),
      ]);
    },
    postFetchDataActions(store, req) {
      return processSitePurchaseResponse(store.getState().siteProducts, req);
    },
  },
  {
    path: '/:sitePath/:lng/purchase/course-option',
    exact: true,
    noCache: true,
    component: SelectPackageView,
    fetchInitialData(store, req) {
      let basketId = req.universalCookies.get('basket_id');

      if (!basketId) {
        basketId = uuid();

        req.universalCookies.set('basket_id', basketId, {
          path: req.siteConfig.cookieSitePath,
          expires: new Date(Date.now() + 86400000),
          secure: canCookieBeSecure(),
        });
      }

      return Promise.all([
        store.dispatch(fetchSiteLayoutAction()),
        store.dispatch(fetchSiteProductsAction(basketId, {})),
      ]);
    },
    postFetchDataActions(store, req) {
      return processSitePurchaseResponse(store.getState().siteProducts, req);
    },
  },
];

const siteRoutes = [
  {
    path: '/:sitePath/:lng',
    exact: true,
    abTesting: true,
    component: HomeView,
    fetchInitialData(store) {
      return Promise.all([
        store.dispatch(fetchSiteLayoutAction()),
        store.dispatch(fetchSiteContentAction({ sitePage: 'home' })),
      ]);
    },
  },
  {
    path: '/:sitePath/:lng/agenda/download',
    exact: true,
    noCache: true,
    leadGenType: 'agenda',
    component: DownloadableAgendaView,
    fetchInitialData(store, req) {
      const state = store.getState();

      const initialRequests = [];

      const tenantId = state.pageConfig.tenantId;
      const leadGenId = req.siteConfig.siteId;
      const leadGenType = req.activeRoute.leadGenType;

      // If AAAService is not enabled LeadGen will not be available also
      if (
        clientConfig.api.services.AAAService[tenantId]?.enabled &&
        leadGenId
      ) {
        const queryParams = {
          product_code: state.options.data.productCode,
        };

        const accessToken = req.universalCookies.get('access_token', {
          path: '/',
          domain: '.' + req.siteConfig.tenantConfig.domain,
        });

        if (accessToken) {
          queryParams.token = accessToken;
          queryParams.username = jwtDecode(accessToken)['cognito:username'];
        }

        initialRequests.push(
          store.dispatch(
            fetchLeadGenFormAction({ leadGenType, leadGenId, queryParams }),
          ),
        );
      }

      return Promise.all([
        ...initialRequests,
        store.dispatch(fetchSiteLayoutAction()),
        store.dispatch(fetchDownloadableAgendaAction()),
      ]);
    },
  },
  {
    path: '/:sitePath/:lng/agenda',
    exact: true,
    component: AgendaView,
    fetchInitialData(store) {
      return Promise.all([
        store.dispatch(fetchSiteLayoutAction()),
        store.dispatch(fetchAgendaAction()),
        store.dispatch(fetchDownloadableAgendaAction()),
      ]);
    },
  },
  {
    path: '/:sitePath/:lng/agenda/:dayNumber',
    exact: true,
    component: AgendaView,
    fetchInitialData(store) {
      return Promise.all([
        store.dispatch(fetchSiteLayoutAction()),
        store.dispatch(fetchAgendaAction()),
        store.dispatch(fetchDownloadableAgendaAction()),
      ]);
    },
  },
  {
    path: '/:sitePath/:lng/co-located-agenda',
    exact: true,
    component: CoLoAgendaView,
    fetchInitialData(store, req) {
      const queryParamsFromRoute = req.locationConfig.search;

      return Promise.all([
        store.dispatch(fetchSiteLayoutAction()),
        store.dispatch(
          fetchCoLoAgendaAction({
            queryParamsFromRoute,
            isInitialLoading: true,
          }),
        ),
      ]);
    },
  },
  {
    path: '/:sitePath/:lng/co-located-agenda/:dayNumber',
    exact: true,
    component: CoLoAgendaView,
    fetchInitialData(store, req) {
      const queryParamsFromRoute = req.locationConfig.search;

      return Promise.all([
        store.dispatch(fetchSiteLayoutAction()),
        store.dispatch(
          fetchCoLoAgendaAction({
            queryParamsFromRoute,
            isInitialLoading: true,
          }),
        ),
      ]);
    },
  },
  {
    path: '/:sitePath/:lng/sponsors',
    exact: true,
    component: SponsorsView,
    fetchInitialData(store) {
      return Promise.all([
        store.dispatch(fetchSiteLayoutAction()),
        store.dispatch(fetchSiteContentAction({ sitePage: 'sponsors' })),
      ]);
    },
  },
  {
    path: '/:sitePath/:lng/speakers',
    exact: true,
    component: SpeakersView,
    fetchInitialData(store, req) {
      return Promise.all([
        store.dispatch(fetchSiteLayoutAction()),
        store.dispatch(fetchSiteSpeakersAction(req.locationConfig.search)),
      ]);
    },
  },
  {
    path: '/:sitePath/:lng/search',
    exact: true,
    component: SearchView,
    async fetchInitialData(store, req) {
      await store.dispatch(fetchSiteContentAction({ sitePage: 'search' }));

      const { search } = req.locationConfig;
      const {
        pageConfig: { siteId, siteTypePath },
        siteContent: {
          data: { details: { searchQueryData = {} } = {} } = {},
        } = {},
      } = store.getState();

      const queryObject = qs.parse(search, {
        ignoreQueryPrefix: true,
      });

      return Promise.all([
        store.dispatch(fetchSiteLayoutAction()),
        store.dispatch(
          fetchSitePageDiscovery({
            values: {
              ...queryObject,
              ...searchQueryData,
              page: 1,
              count: 20,
            },
            siteTypePath,
            siteId,
          }),
        ),
      ]);
    },
  },
  {
    path: '/:sitePath/:lng/trainers',
    exact: true,
    component: SpeakersView,
    fetchInitialData(store, req) {
      return Promise.all([
        store.dispatch(fetchSiteLayoutAction()),
        store.dispatch(fetchSiteSpeakersAction(req.locationConfig.search)),
      ]);
    },
    postFetchDataActions(store, req) {
      const state = store.getState();

      if (
        state.siteSpeakers.data &&
        state.siteSpeakers.data.speakers &&
        state.siteSpeakers.data.speakers.length === 1
      ) {
        const speakerDetails = state.siteSpeakers.data.speakers[0];

        return {
          redirectStatusCode: 302,
          redirectUrl: `${req.path}${speakerDetails.path}/`,
        };
      }
    },
  },
  {
    path: '/:sitePath/:lng/sponsors/:sponsorPath',
    exact: true,
    component: SponsorDetailsView,
    fetchInitialData(store, req) {
      return Promise.all([
        store.dispatch(fetchSiteLayoutAction()),
        store.dispatch(
          fetchSiteSponsorDetailsAction(
            encodeURIComponent(req.params.sponsorPath),
          ),
        ),
      ]);
    },
    postFetchDataActions(store, req) {
      const {
        siteSponsorDetails: {
          data: { linkToExternalPageEnabled, url } = {},
          statusCode,
        } = {},
      } = req.reduxStore.getState();

      if (statusCode === 404) {
        return {
          redirectStatusCode: 302,
          redirectUrl: `/${req.params.sitePath}/`,
        };
      }

      if (linkToExternalPageEnabled) {
        return {
          redirectStatusCode: 302,
          redirectUrl: url,
        };
      }
    },
  },
  {
    path: '/:sitePath/:lng/speakers/:speakerPath',
    exact: true,
    component: SpeakerDetailsView,
    fetchInitialData(store, req) {
      return Promise.all([
        store.dispatch(fetchSiteLayoutAction()),
        store.dispatch(fetchSiteSpeakerDetailsAction(req.params.speakerPath)),
      ]);
    },
  },
  {
    path: '/:sitePath/:lng/trainers/:trainerPath',
    exact: true,
    component: SpeakerDetailsView,
    fetchInitialData(store, req) {
      return Promise.all([
        store.dispatch(fetchSiteLayoutAction()),
        store.dispatch(fetchSiteSpeakerDetailsAction(req.params.trainerPath)),
      ]);
    },
  },
  {
    path: '/:sitePath/:lng/highlights',
    exact: true,
    component: HighlightsView,
    fetchInitialData(store) {
      return Promise.all([
        store.dispatch(fetchSiteLayoutAction()),
        store.dispatch(fetchSiteContentAction({ sitePage: 'highlights' })),
      ]);
    },
  },
  {
    path: '/:sitePath/:lng/networking',
    exact: true,
    component: NetworkingView,
    fetchInitialData(store) {
      return Promise.all([
        store.dispatch(fetchSiteLayoutAction()),
        store.dispatch(fetchSiteContentAction({ sitePage: 'networking' })),
      ]);
    },
  },
  {
    path: '/:sitePath/:lng/plan-your-visit',
    exact: true,
    component: PlanYourVisitView,
    fetchInitialData(store) {
      return Promise.all([
        store.dispatch(fetchSiteLayoutAction()),
        store.dispatch(fetchSiteContentAction({ sitePage: 'plan-your-visit' })),
      ]);
    },
  },
  {
    path: '/:sitePath/:lng/media-partners',
    exact: true,
    component: MediaPartnersView,
    fetchInitialData(store) {
      return Promise.all([
        store.dispatch(fetchSiteLayoutAction()),
        store.dispatch(fetchSiteContentAction({ sitePage: 'media-partners' })),
      ]);
    },
  },
  {
    path: '/:sitePath/:lng/awards',
    exact: true,
    component: AwardsView,
    fetchInitialData(store) {
      return Promise.all([
        store.dispatch(fetchSiteLayoutAction()),
        store.dispatch(fetchSiteContentAction({ sitePage: 'awards' })),
      ]);
    },
  },
  {
    path: '/:sitePath/:lng/partnering',
    exact: true,
    component: PartneringView,
    fetchInitialData(store) {
      return Promise.all([
        store.dispatch(fetchSiteLayoutAction()),
        store.dispatch(fetchSiteContentAction({ sitePage: 'partnering' })),
      ]);
    },
  },
  {
    path: '/:sitePath/:lng/attendees',
    exact: true,
    component: AttendeesView,
    fetchInitialData(store) {
      return Promise.all([
        store.dispatch(fetchSiteLayoutAction()),
        store.dispatch(fetchSiteContentAction({ sitePage: 'attendees' })),
      ]);
    },
  },
  {
    path: '/:sitePath/:lng/vip-services',
    exact: true,
    component: VipServicesView,
    fetchInitialData(store) {
      return Promise.all([
        store.dispatch(fetchSiteLayoutAction()),
        store.dispatch(fetchSiteContentAction({ sitePage: 'vip-services' })),
      ]);
    },
  },
  {
    path: '/:sitePath/:lng/hotel-map',
    exact: true,
    component: HotelMapView,
    fetchInitialData(store) {
      return Promise.all([
        store.dispatch(fetchSiteLayoutAction()),
        store.dispatch(fetchSiteContentAction({ sitePage: 'hotel-map' })),
      ]);
    },
  },
  {
    path: '/:sitePath/:lng/schedule',
    exact: true,
    component: GrowTixView,
    fetchInitialData(store) {
      return Promise.all([
        store.dispatch(fetchSiteLayoutAction()),
        store.dispatch(fetchSiteContentAction({ sitePage: 'schedule' })),
      ]);
    },
  },
  {
    path: '/:sitePath/:lng/guests/:guestPath',
    exact: true,
    component: GrowTixView,
    fetchInitialData(store, req) {
      return Promise.all([
        store.dispatch(fetchSiteLayoutAction()),
        store.dispatch(
          fetchSiteContentAction({
            sitePage: `guests/${req.params.guestPath}`,
          }),
        ),
      ]);
    },
  },
  {
    path: '/:sitePath/:lng/our-guests',
    exact: true,
    component: FestivalProGuestsView,
    fetchInitialData(store) {
      return Promise.all([
        store.dispatch(fetchSiteLayoutAction()),
        store.dispatch(fetchSiteContentAction({ sitePage: 'our-guests' })),
      ]);
    },
  },
  {
    path: '/:sitePath/:lng/our-guests/:guestPath/:guestId',
    exact: true,
    component: FestivalProGuestProfileView,
    fetchInitialData(store) {
      return Promise.all([
        store.dispatch(fetchSiteLayoutAction()),
        store.dispatch(fetchSiteContentAction({ sitePage: 'our-guests' })),
      ]);
    },
  },
  {
    path: '/:sitePath/:lng/our-schedule',
    exact: true,
    component: FestivalProScheduleView,
    fetchInitialData(store) {
      return Promise.all([
        store.dispatch(fetchSiteLayoutAction()),
        store.dispatch(fetchSiteContentAction({ sitePage: 'our-schedule' })),
      ]);
    },
  },
  {
    path: '/:sitePath/:lng/third-party-partners',
    exact: true,
    component: ThirdPartyPartnersView,
    fetchInitialData(store) {
      return Promise.all([
        store.dispatch(fetchSiteLayoutAction()),
        store.dispatch(fetchSitePartnersAction()),
        store.dispatch(
          fetchSiteContentAction({ sitePage: 'third-party-partners' }),
        ),
      ]);
    },
  },
  {
    path: '/:sitePath/:lng/contact',
    exact: true,
    component: ContactsView,
    fetchInitialData(store) {
      return Promise.all([
        store.dispatch(fetchSiteLayoutAction()),
        store.dispatch(fetchSiteContentAction({ sitePage: 'contact' })),
      ]);
    },
  },
  {
    path: '/:sitePath/:lng/terms-and-conditions',
    exact: true,
    component: TermsAndConditionsView,
    fetchInitialData(store) {
      return Promise.all([
        store.dispatch(fetchSiteLayoutAction()),
        store.dispatch(
          fetchSiteContentAction({ sitePage: 'terms-and-conditions' }),
        ),
      ]);
    },
  },
  {
    path: '/:sitePath/:lng/event-privacy-policy',
    exact: true,
    component: EventPrivacyPolicyView,
    fetchInitialData(store) {
      return Promise.all([store.dispatch(fetchSiteLayoutAction())]);
    },
  },
  {
    path: '/:sitePath/:lng/login',
    exact: true,
    fetchInitialData(store) {
      return Promise.all([store.dispatch(fetchSiteLayoutAction())]);
    },
    postFetchDataActions(store, req) {
      const {
        siteHeader: { data: { partneringOne } = {} } = {},
        options: { data: { deliveryType } = {} } = {},
      } = store.getState();

      if (partneringOne) {
        return {
          redirectUrl:
            '/' +
            req.params.sitePath +
            '/pone/login/' +
            req.locationConfig.search,
          redirectStatusCode: 302,
        };
      }

      if (deliveryType === 'VIRTUAL') {
        return {
          redirectUrl:
            '/' +
            req.params.sitePath +
            '/virtual/login/' +
            req.locationConfig.search,
          redirectStatusCode: 302,
        };
      }

      return {
        redirectUrl:
          '/' +
          req.params.sitePath +
          '/pone/login/' +
          req.locationConfig.search,
        redirectStatusCode: 301,
      };
    },
  },
  {
    path: '/:sitePath/:lng/reset-password',
    exact: true,
    postFetchDataActions(store, req) {
      return {
        redirectUrl:
          '/' +
          req.params.sitePath +
          '/pone/reset-password/' +
          req.locationConfig.search,
        redirectStatusCode: 301,
      };
    },
  },
  {
    path: '/:sitePath/:lng/forgotten-password',
    exact: true,
    postFetchDataActions(store, req) {
      return {
        redirectUrl:
          '/' +
          req.params.sitePath +
          '/pone/forgotten-password/' +
          req.locationConfig.search,
        redirectStatusCode: 301,
      };
    },
  },
  {
    path: '/:sitePath/:lng/account-activation',
    exact: true,
    postFetchDataActions(store, req) {
      return {
        redirectUrl:
          '/' +
          req.params.sitePath +
          '/pone/account-activation/' +
          req.locationConfig.search,
        redirectStatusCode: 301,
      };
    },
  },
  {
    path: '/:sitePath/:lng/virtual/login',
    exact: true,
    component: VirtualLoginView,
    fetchInitialData(store) {
      return Promise.all([store.dispatch(fetchSiteLayoutAction())]);
    },
  },
  {
    path: '/:sitePath/:lng/virtual/create-password',
    exact: true,
    component: VirtualCreatePasswordView,
    fetchInitialData(store) {
      return Promise.all([store.dispatch(fetchSiteLayoutAction())]);
    },
  },
  {
    path: '/:sitePath/:lng/virtual/forgotten-password',
    exact: true,
    component: VirtualForgottenPasswordView,
    fetchInitialData(store) {
      return Promise.all([
        store.dispatch(fetchSiteLayoutAction()),
        store.dispatch(
          fetchSiteContentAction({ sitePage: 'terms-and-conditions' }),
        ),
      ]);
    },
  },
  {
    path: '/:sitePath/:lng/virtual/password-expired',
    exact: true,
    component: VirtualPasswordExpiredView,
    fetchInitialData(store) {
      return Promise.all([
        store.dispatch(fetchSiteLayoutAction()),
        store.dispatch(
          fetchSiteContentAction({ sitePage: 'terms-and-conditions' }),
        ),
      ]);
    },
  },
  {
    path: '/:sitePath/:lng/virtual/setup-complete',
    exact: true,
    component: VirtualSetupCompleteView,
    fetchInitialData(store) {
      return Promise.all([store.dispatch(fetchSiteLayoutAction())]);
    },
  },
  {
    path: '/:sitePath/:lng/pone/login',
    exact: true,
    component: P1LoginView,
    fetchInitialData(store) {
      return Promise.all([store.dispatch(fetchSiteLayoutAction())]);
    },
    postFetchDataActions(store, req) {
      const {
        siteHeader: { data: { partneringOne } = {} } = {},
        pageConfig: { homeOrigin } = {},
      } = store.getState();

      if (!partneringOne) {
        return {
          redirectUrl:
            homeOrigin + '/account/login/' + req.locationConfig.search,
          redirectStatusCode: 302,
        };
      }
    },
  },
  {
    path: '/:sitePath/:lng/pone/reset-password',
    exact: true,
    component: P1ResetPasswordView,
    fetchInitialData(store) {
      return Promise.all([store.dispatch(fetchSiteLayoutAction())]);
    },
  },
  {
    path: '/:sitePath/:lng/pone/forgotten-password',
    exact: true,
    component: P1ForgottenPasswordView,
    fetchInitialData(store) {
      return Promise.all([store.dispatch(fetchSiteLayoutAction())]);
    },
    postFetchDataActions(store, req) {
      const {
        siteHeader: { data: { partneringOne } = {} } = {},
        pageConfig: { homeOrigin } = {},
      } = store.getState();

      if (!partneringOne) {
        return {
          redirectUrl:
            homeOrigin +
            '/account/forgotten-password/' +
            req.locationConfig.search,
          redirectStatusCode: 302,
        };
      }
    },
  },
  {
    path: '/:sitePath/:lng/pone/account-activation',
    exact: true,
    component: P1AccountActivationView,
    fetchInitialData(store) {
      return Promise.all([store.dispatch(fetchSiteLayoutAction())]);
    },
  },
  {
    path: '/:sitePath/:lng/news-insights',
    exact: true,
    component: ArticlesView,
    fetchInitialData(store) {
      return Promise.all([
        store.dispatch(fetchSiteLayoutAction()),
        store.dispatch(
          fetchSiteArticles({
            queries: {
              page: 1,
              pageSize: 20,
            },
          }),
        ),
      ]);
    },
  },
  {
    path: '/:sitePath/:lng/article/:siteArticlePath',
    exact: true,
    component: ArticleDetailsView,
    fetchInitialData(store, req) {
      return Promise.all([
        store.dispatch(fetchSiteLayoutAction()),
        store.dispatch(
          fetchSiteArticleDetails({
            siteArticlePath: encodeURIComponent(req.params.siteArticlePath),
          }),
        ),
      ]);
    },
  },
  {
    path: '/:sitePath/:lng/:landingPath',
    noCache: (state) => !!state.leadGenForm.data?.gateId,
    leadGenType: 'landing-page',
    component: LandingPageView,
    async fetchInitialData(store, req) {
      await store.dispatch(
        fetchSiteContentAction({
          landingPath: encodeURIComponent(
            req.params.landingPath?.toLowerCase(),
          ),
        }),
      );

      const {
        siteContent: { data: { id: leadGenId } = {} } = {},
        options: { data: { productCode } = {} } = {},
        pageConfig: { tenantId } = {},
      } = store.getState();

      const promises = [store.dispatch(fetchSiteLayoutAction())];

      const leadGenType = req.activeRoute.leadGenType;
      const isAuthorizationServiceEnabled =
        clientConfig.api.services.AAAService[tenantId]?.enabled;

      /*
       * If landing page is gated then fetch leadGen form data
       */
      if (isAuthorizationServiceEnabled && leadGenId) {
        const queryParams = {
          product_code: productCode,
        };

        const accessToken = req.universalCookies.get('access_token', {
          path: '/',
          domain: `.${req.siteConfig.tenantConfig.domain}`,
        });

        if (accessToken) {
          queryParams.token = accessToken;
          queryParams.username = jwtDecode(accessToken)['cognito:username'];
        }

        promises.push(
          store.dispatch(
            fetchLeadGenFormAction({ leadGenType, leadGenId, queryParams }),
          ),
        );
      }

      return Promise.all(promises);
    },
  },
];

export {
  articleRoutes,
  legacyArticleRoutes,
  portfolioRoutes,
  siteRoutes,
  knect365Routes,
  staticRoutes,
  brandHubRoutes,
  audienceHubRoutes,
  industryRoutes,
  audienceRoutes,
  paymentRoutes,
  legacyPaymentRoutes,
};

const routes = {
  articleRoutes,
  legacyArticleRoutes,
  portfolioRoutes,
  siteRoutes,
  knect365Routes,
  staticRoutes,
  brandHubRoutes,
  audienceHubRoutes,
  industryRoutes,
  audienceRoutes,
  paymentRoutes,
  legacyPaymentRoutes,
};

export default routes;
