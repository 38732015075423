import { createSelector, PayloadAction } from '@reduxjs/toolkit';
import shuffle from 'lodash/shuffle';
import type {
  GenericState,
  HubBasePage,
  HubHomePage,
  HubLandingPage,
  HubModule,
  HubModuleWithDiscovery,
  HubPage,
  UniqueIdentifier,
} from '../../../../../../types';
import type { HeroModuleType } from '../../../../shared/siteBuilder/modules/HeroModule';
import type { CarouselModuleType } from '../../../../shared/siteBuilder/modules/CarouselModule/types';
import type { TestimonialsModuleType } from '../../../../shared/siteBuilder/modules/TestimonialsModule';
import type { Discovery } from '../../../../../../store/features/discovery';
import type { RootState } from '../../../../../../store/store';
import { HubModuleTypes, HubPageTypes } from '../../../../../../constants';
import {
  createGenericAsyncThunk,
  createGenericBuilderCases,
  createGenericSlice,
} from '../../../../../../store/features/generic';

export function shuffleHomeHeroBanner(module: HeroModuleType) {
  return {
    ...module,
    carouselImages: shuffle(module.carouselImages),
  };
}

export function shuffleSiteBuilderModules(modules: HubModule[]) {
  return modules.map((module) => {
    switch (module['@type']) {
      case HubModuleTypes.Hero: {
        const data = module as HeroModuleType;

        if (data.displayOrder === 'RANDOM') {
          return shuffleHomeHeroBanner(data);
        }
        return data;
      }

      case HubModuleTypes.Carousel: {
        const data = module as CarouselModuleType;

        if (data.displayOrder === 'RANDOM') {
          return {
            ...module,
            subsections: shuffle(data.subsections),
          };
        }
        return module;
      }

      case HubModuleTypes.Testimonials: {
        const data = module as TestimonialsModuleType;

        if (data.displayOrder === 'RANDOM') {
          return {
            ...module,
            testimonials: shuffle(data.testimonials),
          };
        }
        return module;
      }

      default:
        return module;
    }
  });
}

export interface BrandHubPageParams {
  brandHubId: UniqueIdentifier;
  pagePath: string;
}

export type BrandHubHomePageParams = Omit<BrandHubPageParams, 'pagePath'>;

export const fetchBrandHubHomePage = createGenericAsyncThunk<
  HubHomePage,
  BrandHubHomePageParams
>(
  'brandHubPage/fetchBrandHubHomePage',
  async ({ brandHubId }, { extra, rejectWithValue }) => {
    const response = await extra.ajax.get(
      `/caas/content/api/v1/brand-hub/${brandHubId}/home`,
      {
        headers: {
          'Accept-Language': extra.headers['Accept-Language'],
          'Published-State': extra.headers['Published-State'],
        },
      },
    );
    const data: HubHomePage = await response.json();
    data.pageType = HubPageTypes.Home;

    if (response.status !== 200) {
      return rejectWithValue(extra.processRejectedValue(response, data));
    }

    data.header.heroBanner = shuffleHomeHeroBanner(data.header.heroBanner);
    data.home.modules = shuffleSiteBuilderModules(data.home.modules);

    return data;
  },
);

export const fetchBrandHubBasePage = createGenericAsyncThunk<
  HubBasePage,
  BrandHubPageParams
>(
  'brandHubPage/fetchBrandHubBasePage',
  async ({ brandHubId, pagePath }, { extra, rejectWithValue }) => {
    const response = await extra.ajax.get(
      `/caas/content/api/v1/brand-hub/${brandHubId}/${pagePath}`,
      {
        headers: {
          'Accept-Language': extra.headers['Accept-Language'],
          'Published-State': extra.headers['Published-State'],
        },
      },
    );
    const data: HubBasePage = await response.json();
    data.pageType = HubPageTypes.Base;

    if (response.status !== 200) {
      return rejectWithValue(extra.processRejectedValue(response, data));
    }

    return data;
  },
);

export const fetchBrandHubLandingPage = createGenericAsyncThunk<
  HubLandingPage,
  BrandHubPageParams
>(
  'brandHubPage/fetchBrandHubLandingPage',
  async ({ brandHubId, pagePath }, { extra, rejectWithValue }) => {
    const response = await extra.ajax.get(
      `/caas/content/api/v1/brand-hub/${brandHubId}/landings/${pagePath}`,
      {
        headers: {
          'Accept-Language': extra.headers['Accept-Language'],
          'Published-State': extra.headers['Published-State'],
        },
      },
    );
    const data: HubLandingPage = await response.json();
    data.pageType = HubPageTypes.Landing;

    if (response.status !== 200) {
      return rejectWithValue(extra.processRejectedValue(response, data));
    }

    data.landingPage.modules = shuffleSiteBuilderModules(
      data.landingPage.modules,
    );

    return data;
  },
);

export interface UpdateModuleByIdPayload {
  moduleId: UniqueIdentifier;
  discovery: Discovery;
}

const brandHubPageSlice = createGenericSlice({
  name: 'brandHubPage',
  initialState: {
    status: 'idle',
    data: {},
  } as GenericState<any>,
  reducers: {
    updateModuleById(state, action: PayloadAction<UpdateModuleByIdPayload>) {
      const { moduleId, discovery } = action.payload;
      const pageType = state.data.pageType;
      let modules: HubModuleWithDiscovery[];

      switch (pageType) {
        case HubPageTypes.Home:
          modules = state.data.home.modules;
          break;
        case HubPageTypes.Landing:
          modules = state.data.landingPage.modules;
          break;
        default:
          modules = [];
      }

      const module = modules.find((module) => module.id === moduleId);

      if (module) {
        module.discovery = discovery;
      }
    },
  },
  extraReducers: (builder) => {
    createGenericBuilderCases<HubHomePage, BrandHubHomePageParams>(
      builder,
      fetchBrandHubHomePage,
    );

    createGenericBuilderCases<HubBasePage, BrandHubPageParams>(
      builder,
      fetchBrandHubBasePage,
    );

    createGenericBuilderCases<HubLandingPage, BrandHubPageParams>(
      builder,
      fetchBrandHubLandingPage,
    );
  },
});

export const selectBrandHubPage = function <T>() {
  return createSelector(
    [(state: RootState) => state.brandHub.page],
    (data) => data as GenericState<T>,
  );
};

export const selectBrandHubPageModules = createSelector(
  [(state: RootState) => state.brandHub.page.data],
  (data: GenericState<HubPage>['data']) => {
    let modules: HubModule[];

    switch (data.pageType) {
      case HubPageTypes.Home:
        modules = (data as HubHomePage).home.modules;
        break;

      case HubPageTypes.Landing:
        modules = (data as HubLandingPage).landingPage.modules;
        break;

      default:
        modules = [];
    }

    return modules;
  },
);

export const selectBrandHubPageLogo = function (state: RootState) {
  const {
    data: { pageType },
  } = selectBrandHubPage<HubPage>()(state);

  if (pageType === HubPageTypes.Landing) {
    const {
      data: {
        landingPage: {
          details: { pageLogo, overrideLogo },
        },
      },
    } = selectBrandHubPage<HubLandingPage>()(state);

    if (overrideLogo && pageLogo) {
      return pageLogo;
    }
  }

  return null;
};

export const { start, fulfilled, rejected, updateModuleById } =
  brandHubPageSlice.actions;

export default brandHubPageSlice.reducer;
