import React, { Component } from 'react';
import loadable from '@loadable/component';
import PropTypes from 'prop-types';
import Scroll from 'react-scroll';
import merge from 'lodash/merge';
import classnames from 'classnames';
import { connect } from 'react-redux';
import qs from 'qs';
import { Cookies, withCookies } from 'react-cookie';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import Sticky from 'react-sticky-el';
import { isMobile } from 'react-device-detect';

import { fetchChosenProductsAction } from '../../../store/pricingDucks/chosenProducts';
import { addElementsPropertiesAction } from '../../../store/commonDucks/pageConfig';
import { fetchSiteContentAction } from '../../../store/siteDucks/siteContent';
import { fetchSitePricingPackages } from '../../paymentJourneyRedesign/store/features/packages';

import Auth from '../../../../src/services/AuthService';
import Api from '../../../api/CommerceService/v1';
import gtm from '../../../utils/initialGTM';
import BrandProvider, { BrandConsumer } from '../../../providers/BrandProvider';
import {
  canCookieBeSecure,
  getImgixUrl,
  lazyLoadingRefresh,
  renderCSSString,
} from '../../../helpers';

import './SiteSection/SiteSection.scss';
import './SiteLayout.scss';

import Advertising from '../../shared/Advertising/Advertising';
import InformaRibbon from '../../common/InformaRibbon/InformaRibbon';
import SiteFooter from './SiteFooter/SiteFooter';
import CookieBanner from '../../common/CookieBanner/CookieBanner';
import ScrollToTop from '../../common/ScrollToTop';
import VIPBanner from '../../common/VIPBanner/VIPBanner';
import DefineBrandStyles from './layoutComponents/DefineBrandStyles/DefineBrandStyles';
import MultiLevelNav from './layoutComponents/MultiLevelNav/MultiLevelNav';
import SiteHeader from '../../styled/siteLayout/SiteHeader';
import { deleteForbiddenQueryParams } from '../../../utils/iFrameSection';
import VisualEditorService from '../../../services/VisualEditorService';
import SiteMeta from './SiteMeta/SiteMeta';

const SiteMiniBasket = loadable(() =>
  import(
    /* webpackChunkName: "SiteMiniBasket" */ '../../paymentJourneyRedesign/shared/components/basketElements/SiteMiniBasket'
  ),
);

const advertisingBgColorByTheme = {
  default: 'white',
  FanExpoHQ: 'secondary',
};

const BASE_PAGES = [
  'home',
  'attendees',
  'awards',
  'highlights',
  'media-partners',
  'networking',
  'partnering',
  'plan-your-visit',
  'sponsors',
  'vip-services',
];

export class SiteLayout extends Component {
  static propTypes = {
    helmet: PropTypes.object,
    siteFooter: PropTypes.object,
    footerUpperHidden: PropTypes.bool,
    children: PropTypes.node,
    siteHeader: PropTypes.object,
    siteContent: PropTypes.object,
    location: PropTypes.object,
    cookies: PropTypes.instanceOf(Cookies),
    style: PropTypes.object,
    addElementsProperties: PropTypes.func,
    fetchSiteContent: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      discount: '',
    };

    this.checkBasket = this.checkBasket.bind(this);
    this.patchVisualEditorPageContent =
      this.patchVisualEditorPageContent.bind(this);
  }

  componentDidMount() {
    const { 'cognito:username': userId, loggedIn } =
      Auth.getShortAccountDetails();

    if (loggedIn) {
      gtm.pushUserID(userId);
    }

    gtm.isUserLoggedIn(loggedIn);
    this.checkBasket();
    this.checkVipCode();
    this.checkIncomingQueryParams();
    window.addEventListener('load', () => this.scrollToTarget());

    if (this.isVisualEditorPreview()) {
      this.initVisualEditor();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('load', () => this.scrollToTarget());
  }

  isVisualEditorPreview() {
    const { pageConfig: { location: { search } = {} } = {} } = this.props;

    const { visualEditorPreview } = qs.parse(search, {
      ignoreQueryPrefix: true,
    });

    return !!visualEditorPreview;
  }

  async patchVisualEditorPageContent(data, onUpdate) {
    const {
      pageConfig: { siteSubPath },
    } = this.props;

    const pagePath = siteSubPath || 'home';
    const pageType = BASE_PAGES.includes(pagePath) ? 'sitePage' : 'landingPath';

    try {
      await this.props.fetchSiteContent({
        [pageType]: pagePath,
        visualEditorPreviewConfig: data,
      });
      if (typeof onUpdate === 'function') {
        onUpdate();
      }
    } catch (e) {
      console.error(e);
    }
  }

  initVisualEditor() {
    const {
      pageConfig: {
        location: { search },
      },
    } = this.props;

    const { sectionId: qSectionId } = qs.parse(search, {
      ignoreQueryPrefix: true,
    });

    const editor = new VisualEditorService();
    editor.setActiveSectionId(qSectionId);

    const observer = new MutationObserver(() => {
      editor.refresh();

      if (editor.getSectionsLength() === 1) {
        editor.removeSectionsAttrs();
        editor.scrollToPosition(0, 'instant');
        return;
      }

      const activeSection = editor.getActiveSection();
      editor.setActiveSectionAttrs(activeSection);

      if (editor.getSectionsLength() !== editor.getPrevSectionsLength()) {
        editor.scrollToSection(activeSection, 'instant');
      }
    });

    observer.observe(document.querySelector('main'), {
      attributes: false,
      childList: true,
      subtree: true,
    });

    document.querySelector('main').addEventListener('click', (event) => {
      const section = event.target.closest('section[data-section-id]');
      if (!section || editor.getSectionsLength() === 1) return;

      const sectionId = section.dataset.sectionId;
      if (editor.getActiveSectionId() === sectionId) return;

      editor.setActiveSectionId(sectionId);
      editor.removeSectionsAttrs();
      editor.setActiveSectionAttrs(section);
      editor.scrollToSection(section);

      const message = {
        type: 'core-visual-editor:change-section',
        data: { sectionId },
      };
      window.parent.postMessage(message, '*');
    });

    document.querySelector('main').addEventListener('click', (event) => {
      if (event.target.closest('a')) {
        event.preventDefault();
      }
    });

    window.addEventListener('message', (event) => {
      const {
        data: { type, data },
      } = event;

      if (type === 'core-visual-editor:patch-section') {
        this.patchVisualEditorPageContent(data, () => {
          lazyLoadingRefresh(false);
        });
      }
    });
  }

  scrollToTarget() {
    const MAIN_MENU_HEIGHT = isMobile ? 50 : 70;

    const hash = this.props.location.hash;
    if (hash) {
      try {
        const element = document.querySelector(hash);
        if (element) {
          Scroll.animateScroll.scrollTo(element.offsetTop - MAIN_MENU_HEIGHT, {
            duration: 0,
          });
        }
      } catch (e) {
        process.env.NODE_ENV === 'development' && console.error(e);
      }
    }
  }

  checkBasket() {
    const {
      pageConfig: { sitePath } = {},
      options: { data: { newPaymentJourneyEnabled = false } = {} } = {},
    } = this.props;

    const basketId = this.props.cookies.get('basket_id');
    const basketOrder = this.props.cookies.get('basket_order');

    if (basketId && basketOrder === basketId) {
      this.props.loadChosenProducts(
        basketId,
        newPaymentJourneyEnabled,
        (response) => {
          if (response.statusCode === 410) {
            this.props.cookies.remove('basket_id', {
              path: '/' + sitePath,
              secure: canCookieBeSecure(),
            });
            this.props.cookies.remove('basket_order', {
              path: '/' + sitePath,
              secure: canCookieBeSecure(),
            });
          }
        },
      );
    }
  }

  checkVipCode() {
    const {
      pageConfig: { siteId = '', cookieSitePath } = {},
      cookies,
      location: { search = '', pathname = '' } = {},
      i18n,
    } = this.props;
    const queryParams = qs.parse(search.replace(/\?/, ''));
    const { vip_code: vipCode } = queryParams;

    if (vipCode) {
      cookies.set('vip_code', vipCode, {
        expires: new Date(Date.now() + 86400000), // 24 * 60 * 60 * 1000
        path: cookieSitePath,
      });

      if (!pathname.includes('purchase')) {
        // check - is it purchase page
        fetch(Api.checkVipCode({ siteId, vipCode }), {
          headers: {
            'Accept-Language': i18n.language,
          },
        })
          .then((response) => {
            if (response.status >= 400) {
              throw response;
            }

            return response.json();
          })
          .then((response) => {
            if (response.data && response.data.discount) {
              this.setState({
                discount: response.data.discount,
              });
            }
          })
          .catch((error) => {
            console.error(error);
          });
      }
    }
  }

  checkIncomingQueryParams() {
    const {
      options: { data: { proxyIncomingQueryParameters = false } = {} } = {},
      pageConfig: { siteId } = {},
      location: { search } = {},
    } = this.props;
    const iFrameProxyParamsList =
      JSON.parse(localStorage.getItem('iFrameProxyParams')) || {};
    const urlParamsToCheck =
      search &&
      deleteForbiddenQueryParams(qs.parse(search, { ignoreQueryPrefix: true }));

    if (!proxyIncomingQueryParameters) {
      // remove related site params from sessionStore
      const filteredIFrameProxyParamsList = { ...iFrameProxyParamsList };

      if (siteId in filteredIFrameProxyParamsList) {
        delete filteredIFrameProxyParamsList[siteId];
      }
      localStorage.setItem(
        'iFrameProxyParams',
        JSON.stringify(filteredIFrameProxyParamsList),
      );
      return;
    }

    // add allowed params to localStorage
    const storedSiteIFrameProxyParams = iFrameProxyParamsList[siteId] || null;
    const preparedIFrameProxyParams = qs.stringify(urlParamsToCheck, {
      addQueryPrefix: true,
    });

    if (
      !preparedIFrameProxyParams ||
      preparedIFrameProxyParams === storedSiteIFrameProxyParams
    ) {
      return;
    }

    const newIFrameProxyParamsList = {
      ...iFrameProxyParamsList,
      [siteId]: preparedIFrameProxyParams,
    };
    localStorage.setItem(
      'iFrameProxyParams',
      JSON.stringify(newIFrameProxyParamsList),
    );
  }

  getDefaultOpenGraphImage(
    openGraphImage,
    tenantId,
    ogParams,
    imageBackground,
    helmet,
  ) {
    return openGraphImage
      ? getImgixUrl(tenantId, openGraphImage.path, ogParams)
      : imageBackground
      ? getImgixUrl(tenantId, imageBackground.path, ogParams)
      : helmet.og.image || helmet.image;
  }

  getSiteData() {
    const {
      helmet: helmetConfig = {},
      siteHeader: {
        data: {
          logo,
          imageBackground,
          mobileLayout,
          homeHeaderStyle,
          eventId,
        } = {},
        meta: {
          title: defaultMetaTitle,
          description: defaultMetaDescription,
          openGraphImage,
        } = {},
      } = {},
      siteContent: {
        data: { overrideLogo, pageLogo } = {},
        meta: {
          title: contentMetaTitle,
          description: contentMetaDescription,
        } = {},
      } = {},
      pageConfig: {
        tenantId,
        tenantConfig: { ui: { theme } = {} } = {},
        sitePath,
        siteSubPath = '',
        location: { pathname = '' } = {},
        canonicalUrl,
      } = {},
      options: {
        data: {
          brand: { colours = {} } = {},
          hideFromSearchEngines = false,
          hideFromInformaSearch = false,
        } = {},
      } = {},
    } = this.props;

    const mobileLogoEnabledTenants = [
      'MEFCC',
      'FANEXPOHQ',
      'INTERIORDESIGNSHOW',
    ];
    const siteLogo = overrideLogo && pageLogo ? pageLogo : logo;

    const helmet = merge(
      {},
      {
        canonical: canonicalUrl,
        title: contentMetaTitle || defaultMetaTitle,
        description: contentMetaDescription || defaultMetaDescription,
        image: getImgixUrl(tenantId, siteLogo?.path, 'w=370&h=80'),
        url: canonicalUrl,
        meta: {
          robots: 'index, follow',
          stRobots: 'index, follow',
          keywords: '',
        },
      },
      helmetConfig,
      { meta: {}, og: {} },
    );
    const siteType = eventId ? 'event' : 'course';
    let pathName = siteSubPath.split('/')[0] || 'home';
    if (sitePath === '/') {
      pathName = pathname.split('/').filter(Boolean)[0] || 'home';
    }
    const ogParams = 'w=400';
    const defaultOpenGraphImage = this.getDefaultOpenGraphImage(
      openGraphImage,
      tenantId,
      ogParams,
      imageBackground,
      helmet,
    );
    const advertisingBg = advertisingBgColorByTheme[theme]
      ? `#${colours[advertisingBgColorByTheme[theme]]}`
      : advertisingBgColorByTheme.default;
    const topHeaderClass = classnames({
      'hidden-xs': mobileLayout === 'BOTTOM',
    });
    const bottomHeaderClass = classnames({
      'visible-xs': mobileLayout === 'BOTTOM',
      hidden: mobileLayout === 'TOP',
    });
    const robots = hideFromSearchEngines
      ? 'noindex, nofollow'
      : helmet.meta.robots;
    const stRobots = hideFromInformaSearch
      ? 'noindex, nofollow'
      : helmet.meta.stRobots;

    const visualEditorPreview = this.isVisualEditorPreview();

    const alternateHomeHeaderStyle =
      pathName === 'home' && homeHeaderStyle === 'TRANSPARENT_WITHOUT_HEADER';

    const tenantClassName = tenantId
      ? `l-site--${tenantId.toLocaleLowerCase()}`
      : '';

    return {
      mobileLogoEnabledTenants,
      helmet,
      siteType,
      pathName,
      defaultOpenGraphImage,
      advertisingBg,
      topHeaderClass,
      bottomHeaderClass,
      robots,
      stRobots,
      visualEditorPreview,
      alternateHomeHeaderStyle,
      tenantClassName,
    };
  }

  render() {
    const {
      footerUpperHidden = false,
      children,
      style: contentStyle,
      siteHeader: {
        data: {
          hideInformaBar = false,
          paymentHub,
          navBarLogoEnabled,
          scrollToTopEnabled,
        } = {},
      } = {},
      siteContent: { data: { sections = [] } = {} } = {},
      siteFooter,
      pageConfig: { tenantId, renderError } = {},
      options: { data: { newPaymentJourneyEnabled = false } = {} } = {},
      addElementsProperties,
    } = this.props;

    const { discount } = this.state;

    const {
      mobileLogoEnabledTenants,
      helmet,
      siteType,
      pathName,
      defaultOpenGraphImage,
      advertisingBg,
      topHeaderClass,
      bottomHeaderClass,
      robots,
      stRobots,
      visualEditorPreview,
      alternateHomeHeaderStyle,
      tenantClassName,
    } = this.getSiteData();

    return (
      <BrandProvider>
        <SiteMeta
          robots={robots}
          stRobots={stRobots}
          helmet={helmet}
          defaultOpenGraphImage={defaultOpenGraphImage}
          renderError={renderError}
        />
        <div
          className={`l-site l-site-${siteType} l-site--${pathName} ${tenantClassName}`}
        >
          <div className="l-site-wrapper">
            {!visualEditorPreview && (
              <div className="l-site__top">
                <CookieBanner />
                {!hideInformaBar && <InformaRibbon />}
                <Advertising
                  advSlots={['728_1']}
                  advSize="728_90"
                  style={{ backgroundColor: advertisingBg }}
                />
                {!alternateHomeHeaderStyle && (
                  <div className={topHeaderClass}>
                    <SiteHeader
                      siteHeader={this.props.siteHeader}
                      addElementsProperties={addElementsProperties}
                    />
                  </div>
                )}
              </div>
            )}

            <div className="l-site__body">
              {discount && (
                <div className="l-site__sticky">
                  <Sticky>
                    <VIPBanner discount={discount} />
                  </Sticky>
                </div>
              )}

              {!paymentHub && !visualEditorPreview && (
                <Sticky
                  className="l-site__sticky-wrapper"
                  stickyClassName="l-site__sticky"
                >
                  <MultiLevelNav
                    topNav
                    withHomeNav={!!navBarLogoEnabled}
                    withMobileLogo={mobileLogoEnabledTenants.includes(tenantId)}
                    transparent={alternateHomeHeaderStyle}
                    miniBasket={
                      newPaymentJourneyEnabled ? <SiteMiniBasket /> : null
                    }
                  />
                  {scrollToTopEnabled && <ScrollToTop />}
                </Sticky>
              )}

              <main
                className="l-site__content"
                id="content"
                style={contentStyle}
              >
                {!visualEditorPreview && (
                  <>
                    <div className={bottomHeaderClass}>
                      <SiteHeader siteHeader={this.props.siteHeader} />
                    </div>
                    <div className="l-site__fixed-multi-level-nav">
                      <MultiLevelNav fixedNav />
                    </div>
                  </>
                )}
                {visualEditorPreview && sections.length === 0 && (
                  <div className="l-site__no-sections-in-preview">
                    <h4>The site builder module is incomplete.</h4>
                    <p>
                      There isn't sufficient data to display it. Please ensure
                      that all the required form fields are filled in, and the
                      module will appear immediately.
                    </p>
                  </div>
                )}
                {children}
              </main>

              {!visualEditorPreview && (
                <SiteFooter
                  footer={siteFooter}
                  footerUpperHidden={footerUpperHidden}
                />
              )}
            </div>

            <BrandConsumer>
              {(brand) => <DefineBrandStyles brand={brand} />}
            </BrandConsumer>

            <BrandConsumer>
              {(brand) =>
                renderCSSString(`
                    .bg-primary-color {
                      background-color: ${brand.primaryColor};
                    }

                    .bg-secondary-color {
                      background-color: ${brand.secondaryColor};
                    }

                    .c-button--primary,
                    .c-button--primary:hover:not(:disabled) {
                      background-color: ${brand.primaryColor};
                    }

                    .c-button--secondary,
                    .c-button--secondary:hover:not(:disabled) {
                      background-color: ${brand.secondaryColor};
                    }

                    .c-button--accent,
                    .c-button--accent:hover:not(:disabled) {
                      background-color: ${brand.accentColor};
                      color: rgba(0, 0, 0, 0.8);
                    }

                    .cls-1 {
                      fill: ${brand.primaryColor};
                    }

                    .cls-2 {
                      fill: ${brand.secondaryColor};
                    }
  
                    .cls-3 {
                      fill: ${brand.accentColor};
                    }

                    .fc-primary {
                      color: ${brand.primaryColor};
                    }

                    .fc-secondary {
                      color: ${brand.secondaryColor};
                    }

                    .fc-accent {
                      color: ${brand.accentColor};
                    }

                    .c-speaker-block, .site-card {
                      color: ${brand.secondaryColor};
                    }

                    .agenda-keynote-sessions,
                    .c-speaker-block:hover,
                    .site-card:hover {
                      background-color: ${brand.secondaryColor};
                      color: #fff;
                    }

                    .c-agenda-day-selectors, .c-co-lo-agenda-action-bar {
                      background-color: ${brand.darkSecondaryColor};
                    }

                    .c-agenda-day-selectors .agenda-day-selector.selected a,
                    .c-co-lo-agenda-day-selector__link--selected {
                      border-color: ${brand.accentColor};
                      color: ${brand.accentColor};
                    }
                    
                    .c-co-lo-agenda-day-selector-mobile .c-co-lo-agenda-day-selector__link--selected {
                      color: ${brand.accentColor};
                    }

                    .agenda-download.event a {
                      color: ${brand.accentColor};
                    }
                    
                    .agenda-download.course a {
                      background-color: ${brand.accentColor};
                      color: ${brand.darkSecondaryColor};
                    }

                    .c-filterable-content .c-filter-checkbox input + span::before {
                      border-color: ${brand.secondaryColor};
                    }

                    .c-filterable-content .c-filter-checkbox input:checked + span::before {
                      background-color: ${brand.secondaryColor};
                      border-color: ${brand.secondaryColor};
                    }

                    .c-modal .session-sponsors a {
                      color: ${brand.darkSecondaryColor};
                    }
                  `)
              }
            </BrandConsumer>
          </div>
        </div>
      </BrandProvider>
    );
  }
}

function mapStateToProps(state) {
  return {
    siteHeader: state.siteHeader,
    siteFooter: state.siteFooter,
    siteContent: state.siteContent,
    pageConfig: state.pageConfig,
    options: state.options,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    loadChosenProducts(basketId, newPaymentJourneyEnabled, cb) {
      if (newPaymentJourneyEnabled) {
        dispatch(fetchSitePricingPackages({ basketId })).then((response) => {
          const { payload = {} } = response;
          cb(payload);
        });
      } else {
        dispatch(fetchChosenProductsAction(basketId, cb));
      }
    },
    addElementsProperties(elProperties) {
      return dispatch(addElementsPropertiesAction(elProperties));
    },
    fetchSiteContent(payload) {
      return dispatch(fetchSiteContentAction(payload));
    },
  };
}

export default withTranslation()(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(withRouter(withCookies(SiteLayout))),
);
