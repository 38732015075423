import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import qs from 'qs';
import Auth from '../../../../../../services/AuthService';
import { useRootSelector } from '../../../../../../store/hooks';
import { selectBrandHubLayout } from '../../../store/features/layout';
import { selectBrandHubOptions } from '../../../store/features/options';
import { selectPageConfig } from '../../../../../../store/features/pageConfig';
import { selectTenantConfig } from '../../../../../../store/features/tenantConfig';
import s from './ForgottenPasswordSent.module.scss';
import StaticPageLayout from '../../../layouts/StaticPageLayout';
import { MetaTags } from '../../../../shared/MetaTags';
import PublicPageLayout from '../shared/PublicPageLayout';
import Paper from '../shared/Paper';
import Button from '../shared/Button';

export function ForgottenPasswordSent() {
  const {
    data: {
      header: {
        headerNavBar: { brandLogo },
      },
    },
  } = useRootSelector(selectBrandHubLayout);
  const {
    data: { name: hubName },
  } = useRootSelector(selectBrandHubOptions);

  const { location } = useSelector(selectPageConfig);
  const { id: tenantId } = useSelector(selectTenantConfig);
  const [loading, setLoading] = useState(false);

  const { username } = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });

  const handleResendConfirmationEmail = useCallback(async () => {
    setLoading(true);

    try {
      await Auth.sendForgotPassword({ username, tenantId });
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [tenantId, username]);

  return (
    <StaticPageLayout className={s.body}>
      <MetaTags
        title="Forgotten Password"
        titleSuffix={hubName}
        thumbnail={brandLogo?.path}
      />
      <PublicPageLayout>
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-md-10 col-md-offset-1 col-lg-8 col-lg-offset-2">
              <Paper
                heading="Forgotten Password"
                text={
                  <>
                    <p>
                      If the email you provided matches an Informa Connect
                      account, we will send a reset password link to{' '}
                      <strong>{username as string}</strong>. Click the link in
                      your email to reset your password.
                    </p>
                    <p>
                      If you cannot see the email in your inbox please check
                      your spam and any other folders it may have been sent to.
                    </p>
                  </>
                }
              >
                <Button
                  onClick={handleResendConfirmationEmail}
                  color="grey"
                  disabled={loading}
                >
                  Send the email again
                </Button>
              </Paper>
            </div>
          </div>
        </div>
      </PublicPageLayout>
    </StaticPageLayout>
  );
}
