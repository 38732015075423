import { createSelector, PayloadAction } from '@reduxjs/toolkit';
import type {
  GenericState,
  HubBasePage,
  HubHomePage,
  HubLandingPage,
  HubModule,
  HubModuleWithDiscovery,
  HubPage,
  UniqueIdentifier,
} from '../../../../../../types';
import type { Discovery } from '../../../../../../store/features/discovery';
import type { RootState } from '../../../../../../store/store';
import { HubPageTypes } from '../../../../../../constants';
import {
  shuffleHomeHeroBanner,
  shuffleSiteBuilderModules,
} from '../../../../brandHub/store/features/page';
import {
  createGenericAsyncThunk,
  createGenericBuilderCases,
  createGenericSlice,
} from '../../../../../../store/features/generic';

export interface AudienceHubPageParams {
  audienceHubId: UniqueIdentifier;
  pagePath: string;
}

export type AudienceHubHomePageParams = Omit<AudienceHubPageParams, 'pagePath'>;

export const fetchAudienceHubHomePage = createGenericAsyncThunk<
  HubHomePage,
  AudienceHubHomePageParams
>(
  'audienceHubPage/fetchAudienceHubHomePage',
  async ({ audienceHubId }, { extra, rejectWithValue }) => {
    const response = await extra.ajax.get(
      `/caas/content/api/v1/audience-home/${audienceHubId}/home`,
      {
        headers: {
          'Published-State': extra.headers['Published-State'],
          'Accept-Language': extra.headers['Accept-Language'],
        },
      },
    );
    const data: HubHomePage = await response.json();
    data.pageType = HubPageTypes.Home;

    if (response.status !== 200) {
      return rejectWithValue(extra.processRejectedValue(response, data));
    }

    data.home.modules = shuffleSiteBuilderModules(data.home.modules);
    data.header.heroBanner = shuffleHomeHeroBanner(data.header.heroBanner);

    return data;
  },
);

export const fetchAudienceHubBasePage = createGenericAsyncThunk<
  HubBasePage,
  AudienceHubPageParams
>(
  'audienceHubPage/fetchAudienceHubBasePage',
  async ({ audienceHubId, pagePath }, { extra, rejectWithValue }) => {
    const response = await extra.ajax.get(
      `/caas/content/api/v1/audience-home/${audienceHubId}/${pagePath}`,
      {
        headers: {
          'Accept-Language': extra.headers['Accept-Language'],
          'Published-State': extra.headers['Published-State'],
        },
      },
    );
    const data: HubBasePage = await response.json();
    data.pageType = HubPageTypes.Base;

    if (response.status !== 200) {
      return rejectWithValue(extra.processRejectedValue(response, data));
    }

    return data;
  },
);

export const fetchAudienceHubLandingPage = createGenericAsyncThunk<
  HubLandingPage,
  AudienceHubPageParams
>(
  'audienceHubPage/fetchAudienceHubLandingPage',
  async ({ audienceHubId, pagePath }, { extra, rejectWithValue }) => {
    const response = await extra.ajax.get(
      `/caas/content/api/v1/audience-home/${audienceHubId}/landings/${pagePath}`,
      {
        headers: {
          'Published-State': extra.headers['Published-State'],
          'Accept-Language': extra.headers['Accept-Language'],
        },
      },
    );
    const data: HubLandingPage = await response.json();
    data.pageType = HubPageTypes.Landing;

    if (response.status !== 200) {
      return rejectWithValue(extra.processRejectedValue(response, data));
    }

    const modules = data.landingPage.modules;
    data.landingPage.modules = shuffleSiteBuilderModules(modules);

    return data;
  },
);

export interface UpdateModuleByIdPayload {
  moduleId: UniqueIdentifier;
  discovery: Discovery;
}

const audienceHubPageSlice = createGenericSlice({
  name: 'audienceHubPage',
  initialState: {
    status: 'idle',
    data: {},
  } as GenericState<any>,
  reducers: {
    updateModuleById(state, action: PayloadAction<UpdateModuleByIdPayload>) {
      const { moduleId, discovery } = action.payload;
      const pageType = state.data.pageType;
      let modules: HubModuleWithDiscovery[];

      switch (pageType) {
        case HubPageTypes.Home:
          modules = state.data.home.modules;
          break;
        case HubPageTypes.Landing:
          modules = state.data.landingPage.modules;
          break;
        default:
          modules = [];
      }

      const module = modules.find((module) => module.id === moduleId);

      if (module) {
        module.discovery = discovery;
      }
    },
  },
  extraReducers: (builder) => {
    createGenericBuilderCases<HubHomePage, AudienceHubHomePageParams>(
      builder,
      fetchAudienceHubHomePage,
    );

    createGenericBuilderCases<HubBasePage, AudienceHubPageParams>(
      builder,
      fetchAudienceHubBasePage,
    );

    createGenericBuilderCases<HubLandingPage, AudienceHubPageParams>(
      builder,
      fetchAudienceHubLandingPage,
    );
  },
});

export const selectAudienceHubPage = function <T>() {
  return createSelector(
    [(state: RootState) => state.audienceHUB.page],
    (data) => data as GenericState<T>,
  );
};

export const selectAudienceHubPageModules = createSelector(
  [(state: RootState) => state.audienceHUB.page.data],
  (data: GenericState<HubPage>['data']) => {
    let modules: HubModule[];

    switch (data.pageType) {
      case HubPageTypes.Home:
        modules = (data as HubHomePage).home.modules;
        break;

      case HubPageTypes.Landing:
        modules = (data as HubLandingPage).landingPage.modules;
        break;

      default:
        modules = [];
    }

    return modules;
  },
);

export const selectAudienceHubPageLogo = function (state: RootState) {
  const {
    data: { pageType },
  } = selectAudienceHubPage<HubPage>()(state);

  if (pageType === HubPageTypes.Landing) {
    const {
      data: {
        landingPage: {
          details: { pageLogo, overrideLogo },
        },
      },
    } = selectAudienceHubPage<HubLandingPage>()(state);

    if (overrideLogo && pageLogo) {
      return pageLogo;
    }
  }

  return null;
};

export const { fulfilled, rejected, updateModuleById } =
  audienceHubPageSlice.actions;

export default audienceHubPageSlice.reducer;
