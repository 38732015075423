import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';

import clientConfig from '../../../clientConfig';
import gtm from '../../../utils/initialGTM';

import '../Button/Button.scss';

import { localeInPath } from '../../../helpers/locale';
import {
  isAbsoluteUrl,
  isBoolean,
  isEnv,
  isMailToUrl,
  isTelephoneUrl,
  isString,
} from '../../../helpers';
import processURLQuery from '../../../helpers/processURLQuery';
import constructURL from '../../../helpers/constructURL';
import EventPageDetailsService from '../../../services/EventPageDetailsService';

export class Link extends Component {
  formatLink(arr) {
    const {
      pageConfig: { singleSiteMode, sitePath },
    } = this.props;

    let href = `/${arr
      .filter(Boolean)
      .join('/')
      .replace(/\/+/g, '/')
      .replace(/^\/+/, '')}`;
    if (singleSiteMode) {
      href = href.replace(new RegExp('/' + sitePath), '');
    }

    return href;
  }

  getOriginWithSubdomain() {
    const env = this.props.env || isEnv();
    const {
      sub = '',
      tenant,
      pageConfig: {
        tenantId,
        homeOrigin = '',
        tenantConfig = {},
        tenantsConfig = {},
      } = {},
    } = this.props;

    const hasWWW = env === 'prod' && tenantConfig.useWWW;

    let origin = homeOrigin;

    if (sub) {
      const stringToReplace = hasWWW ? '//www.' : '//';

      origin = origin.replace(
        stringToReplace,
        '//' +
          (sub !== tenant
            ? env === 'prod'
              ? sub.toLowerCase() + '.'
              : sub.toLowerCase() + '-'
            : ''),
      );
    }

    if (tenant && tenantsConfig[tenant]) {
      if (tenant !== tenantId) {
        origin = origin.replace(
          tenantConfig.domain,
          tenantsConfig[tenant].domain,
        );
      }

      if (!tenantsConfig[tenant].useWWW) {
        origin = origin.replace('//www.', '//');
      }
    }

    return origin;
  }

  getLinkDetails() {
    const {
      to,
      sub,
      tenant,
      parent,
      event,
      target,
      label,
      children,
      pageConfig: {
        sitePath,
        siteType = 'EVENT',
        homeOrigin = '',
        location: { pathname } = {},
      } = {},
      siteHeader: { data: { colo } = {} } = {},
    } = this.props;

    const lang = this.props.lang || localeInPath(pathname);

    if (isAbsoluteUrl(to) || isMailToUrl(to) || isTelephoneUrl(to)) {
      return {
        path: to,
        label: label || children,
        target: target || '_blank',
      };
    }

    if (parent) {
      return {
        path: homeOrigin + this.formatLink([to]),
        label: label || children,
        target: target || '_self',
      };
    }

    if (sub || tenant) {
      const eventPath = event ? (isString(event) ? event : sitePath) : null;
      const langPath = eventPath ? lang : null;

      return {
        path:
          this.getOriginWithSubdomain() +
          this.formatLink([eventPath, langPath, to]),
        label: label || children,
        target: target || '_self',
      };
    }

    if (event) {
      if (isString(event)) {
        return {
          path: this.formatLink([event, lang, to]),
          label: label || children,
          target: target || '_self',
        };
      }

      if (isBoolean(event)) {
        return {
          path: this.formatLink([sitePath, lang, to]),
          label: label || children,
          target: target || '_self',
        };
      }

      const { path, label: innerLabel } =
        EventPageDetailsService.getButtonDetails(event, siteType, colo);

      const specifiedPath = event.customUrl || path;

      const linkPath =
        event.type === 'CUSTOM' && isAbsoluteUrl(specifiedPath)
          ? specifiedPath
          : this.formatLink([sitePath, lang, specifiedPath, to]);

      return {
        path:
          isAbsoluteUrl(specifiedPath) || isMailToUrl(specifiedPath)
            ? specifiedPath
            : linkPath,
        label: children || label || event.customText || innerLabel,
        target: target || '_self',
        shown: event.shown,
      };
    }

    return {
      path: this.formatLink([to]),
      label: label || children,
      target: target || '_self',
    };
  }

  render() {
    const {
      type,
      tooltip = {},
      params,
      rel,
      className,
      style = {},
      look = 'accent',
      size = 'large',
      onClick,
      event,
      gaNavbar,
      itemprop,
      itemtype,
      itemscope,
      download,
      ...rest
    } = this.props;

    const { shown = true, path, label, target } = this.getLinkDetails();

    if (!shown) return null;

    const classes = [];

    if (type === 'button') {
      classes.push(
        'c-button',
        'c-button--' + look,
        'c-button--' + size,
        'uppercase',
      );
    }

    className && classes.push(className);

    const isPartneringOne =
      event &&
      event.type &&
      (event.type === 'PARTNERING_ONE' ||
        event.type === 'PARTNERING_ONE_ACCESS');

    isPartneringOne && classes.push('trademark');

    const clickCallback = (...params) => {
      gaNavbar && gtm.pushNavBar(path);

      const buyLink = event && event.type === 'BUY_LINK';
      const counter = buyLink && gtm.counter++;
      buyLink && gtm.pushBuy(counter);

      onClick && onClick(...params);
    };

    const query = processURLQuery(params);
    const url = constructURL(path, query, event?.type === 'CUSTOM');

    return (
      <>
        <a
          href={url}
          target={isEnv('local') ? '_self' : target}
          rel={rel}
          data-tip={tooltip.tip}
          data-event={tooltip.event}
          className={classes.join(' ').trim()}
          style={style}
          onClick={clickCallback}
          itemProp={itemprop}
          itemScope={itemscope}
          itemType={itemtype}
          data-background-image={rest['data-background-image']}
          download={download}
        >
          {label}
        </a>
        {tooltip.tip && (
          <ReactTooltip
            afterShow={clickCallback}
            className={tooltip.className}
            globalEventOff={tooltip.eventOff || 'click'}
          />
        )}
      </>
    );
  }
}

Link.propTypes = {
  type: PropTypes.oneOf(['link', 'button']),
  look: PropTypes.oneOf([
    'classic',
    'primary',
    'secondary',
    'accent',
    'transparent',
  ]),
  size: PropTypes.oneOf([
    'default',
    'submit',
    'popup',
    'minuscule',
    'tiny',
    'small',
    'medium',
    'large',
  ]),
  to: PropTypes.string,
  download: PropTypes.bool,
  sub: PropTypes.string,
  tenant: PropTypes.string,
  parent: PropTypes.bool,
  lang: PropTypes.oneOf(['none', ...clientConfig.i18n.whitelist]),
  params: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  tooltip: PropTypes.shape({
    tip: PropTypes.string,
    event: PropTypes.string,
    className: PropTypes.string,
    eventOff: PropTypes.string,
  }),
  event: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
    PropTypes.shape({
      uploaded: PropTypes.string,
      path: PropTypes.string,
      label: PropTypes.string,
      shown: PropTypes.bool,
    }),
  ]),
  rel: PropTypes.string,
  label: PropTypes.node,
  target: PropTypes.oneOf(['_blank', '_self', '_top', '_parent']),
  className: PropTypes.string,
  children: PropTypes.node,
  style: PropTypes.object,
  onClick: PropTypes.func,
  gaNavbar: PropTypes.bool,
  siteHeader: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    pageConfig: state.pageConfig,
    siteHeader: state.siteHeader,
  };
}

export default connect(mapStateToProps)(Link);
