export enum PaletteColors {
  brandPrimary = 'brandPrimary',
  brandSecondary = 'brandSecondary',
  brandAccent = 'brandAccent',
  customError = 'customError',
  customWarning = 'customWarning',
  customSuccess = 'customSuccess',
  customInfo = 'customInfo',
}

export enum PricingProductSelectionTypes {
  SINGLE_CHOICE = 'SINGLE_CHOICE',
  MULTIPLE_CHOICE = 'MULTIPLE_CHOICE',
}

export enum PricingProductsDelegateTypeLayouts {
  HORIZONTAL = 'HORIZONTAL',
  VERTICAL = 'VERTICAL',
}

export enum PricingProductsProductTypeLayouts {
  LIST = 'LIST',
  PRODUCTS_WITH_PRODUCT_TYPES = 'PRODUCTS_WITH_PRODUCT_TYPES',
}

export enum PricingInputsTypes {
  TEXT = 'TEXT',
  RADIO = 'RADIO',
  SELECT = 'SELECT',
  CHECKBOX = 'CHECKBOX',
  CHECKBOX_GROUP = 'CHECKBOX_GROUP',
  HIDDEN = 'HIDDEN',
  TEXTAREA = 'TEXTAREA',
  DATE = 'DATE',
  DATE_MM = 'DATE_MM',
  DATE_YY = 'DATE_YY',
  FILE = 'FILE',
  FILE_GROUP = 'FILE_GROUP',
  EMAIL = 'EMAIL',
  TELEPHONE = 'TELEPHONE',
  MOBILE_TELEPHONE = 'MOBILE_TELEPHONE',
}

export enum PricingLayoutTypes {
  ROW = 'ROW',
  COLUMN = 'COLUMN',
}

export enum PricingSelectionTypes {
  ROW = 'ALL',
  COLUMN = 'CERTAIN',
}

export enum PricingRedeemTypes {
  PER_ORDER = 'PER_ORDER',
  PER_DELEGATE = 'PER_DELEGATE',
}

export enum PricingCustomerRoleTypes {
  NONE = 'NONE',
  PAYING_DELEGATE = 'PAYING_DELEGATE',
  VIP = 'VIP',
  SPEX_GUEST = 'SPEX_GUEST',
  STAND_STAFF = 'STAND_STAFF',
  PRESS = 'PRESS',
  DEMOER = 'DEMOER',
  PAYING_SPEAKER = 'PAYING_SPEAKER',
  LICENCED_USER = 'LICENCED_USER',
  LICENCED_USER_TRIAL = 'LICENCED_USER_TRIAL',
  LICENCED_USER_FREE = 'LICENCED_USER_FREE',
}

export enum PricingGroupDiscountStatusTypes {
  MSG_PERCENTAGE = 'MSG_PERCENTAGE',
  MSG_FIXED = 'MSG_FIXED',
}

export enum PricingBillingPaymentOptionTypes {
  ONLINE = 'ONLINE',
  INVOICE = 'INVOICE',
  FREE = 'FREE',
}

export enum PricingDelegateType {
  standard = 'standard',
  mea = 'mea',
  online = 'online',
  iff = 'iff',
  distanceLearning = 'distance-learning',
  subscription = 'subscription',
  etfEdu = 'etf-edu',
}

export enum InfoWidgetIconTypes {
  accessTimeIcon = 'accessTimeIcon',
  checkCircleIcon = 'checkCircleIcon',
  percentIcon = 'percentIcon',
  eventNoteIcon = 'eventNoteIcon',
  addCircleIcon = 'addCircleIcon',
  shoppingCartIcon = 'shoppingCartIcon',
  sellIcon = 'sellIcon',
  lightbulbIcon = 'lightbulbIcon',
  accountCircleIcon = 'accountCircleIcon',
  starIcon = 'starIcon',
  callIcon = 'callIcon',
  autorenewIcon = 'autorenewIcon',
  eventBusyIcon = 'eventBusyIcon',
  creditCardIcon = 'creditCardIcon',
  emailIcon = 'emailIcon',
  checkIcon = 'checkIcon',
  closeIcon = 'closeIcon',
}

export enum PricingFormInputType {
  TEXT = 'TEXT',
  EMAIL = 'EMAIL',
  TELEPHONE = 'TELEPHONE',
  TEXTAREA = 'TEXTAREA',
  SELECT = 'SELECT',
  DATE = 'DATE',
  DATE_MM = 'DATE_MM',
  DATE_YY = 'DATE_YY',
  FILE = 'FILE',
  FILE_GROUP = 'FILE_GROUP',
  RADIO = 'RADIO',
  CHECKBOX_GROUP = 'CHECKBOX_GROUP',
}

export enum PricingFormInputOptionSource {
  COUNTRIES = 'countries',
  REGIONS = 'regions',
}

export enum PricingFormInputLayout {
  ROW = 'ROW',
  COLUMN = 'COLUMN',
}

export enum DiscountStatuses {
  MSG_FIXED = 'MSG_FIXED',
  MSG_PERCENTAGE = 'MSG_PERCENTAGE',
  APPLIED = 'APPLIED',
  VIP_SUCCESS_PERCENTAGE = 'VIP_SUCCESS_PERCENTAGE',
  VIP_SUCCESS_PERCENTAGE_FOR_PRODUCTS = 'VIP_SUCCESS_PERCENTAGE_FOR_PRODUCTS',
  VIP_SUCCESS_FIXED = 'VIP_SUCCESS_FIXED',
  VIP_SUCCESS_FIXED_FOR_PRODUCTS = 'VIP_SUCCESS_FIXED_FOR_PRODUCTS',
  VIP_NOT_ENOUGH_CLAIMS_PERCENTAGE = 'VIP_NOT_ENOUGH_CLAIMS_PERCENTAGE',
  VIP_NOT_ENOUGH_CLAIMS_PERCENTAGE_FOR_PRODUCTS = 'VIP_NOT_ENOUGH_CLAIMS_PERCENTAGE_FOR_PRODUCTS',
  VIP_NOT_ENOUGH_CLAIMS_FIXED = 'VIP_NOT_ENOUGH_CLAIMS_FIXED',
  VIP_NOT_ENOUGH_CLAIMS_FIXED_FOR_PRODUCTS = 'VIP_NOT_ENOUGH_CLAIMS_FIXED_FOR_PRODUCTS',
  NO_CODE_EXISTS = 'NO_CODE_EXISTS',
  CODE_EXPIRED = 'CODE_EXPIRED',
  MAX_CLAIMS_REACHED = 'MAX_CLAIMS_REACHED',
  CODE_IS_AVAILABLE_ONLY_FOR_PRODUCTS = 'CODE_IS_AVAILABLE_ONLY_FOR_PRODUCTS',
  CODE_IS_AVAILABLE_ONLY_FOR_DELEGATE_TYPES = 'CODE_IS_AVAILABLE_ONLY_FOR_DELEGATE_TYPES',
  CODE_IS_AVAILABLE_ONLY_FOR_DELEGATE_TYPES_AND_FOR_PRODUCTS = 'CODE_IS_AVAILABLE_ONLY_FOR_DELEGATE_TYPES_AND_FOR_PRODUCTS',
  withoutStatus = 'withoutStatus',
}

export enum DiscountResultTypes {
  GroupDiscountApplyResult = 'GroupDiscountApplyResult',
  PackageDiscountApplyResult = 'PackageDiscountApplyResult',
  PromotionCodeApplyResult = 'PromotionCodeApplyResult',
}

export enum CardTypes {
  VISA = 'VISA',
  AMEX = 'AMEX',
  MC = 'MC',
}

export enum AcceptedFileTypeKeys {
  JPEG = '.JPEG',
  PNG = '.PNG',
  GIF = '.GIF',
  PDF = '.PDF',
  RTF = '.RTF',
  DOCX = '.DOCX',
  XLSX = '.XLSX',
  PPTX = '.PPTX',
}

export enum TermsAndConditionId {
  UPDATED_PARTICIPANT_TERMS_AND_CONDITIONS_LMA = 'UPDATED_PARTICIPANT_TERMS_AND_CONDITIONS_LMA',
  UPDATED_TRAINING_PARTICIPANT_TERMS_INFORMA_CONNECT = 'UPDATED_TRAINING_PARTICIPANT_TERMS_INFORMA_CONNECT',
}

export enum PricingPurchaseErrorStatus {
  EXPIRED = 'EXPIRED',
}

export enum PricingPurchaseStatus {
  PURCHASED = 'PURCHASED',
  ALREADY_PROCESSED = 'ALREADY_PROCESSED',
  ACCEPTING_PAYMENT = 'ACCEPTING_PAYMENT',
  DECLINED = 'DECLINED',
  AUTHENTICATION_REQUIRED = 'AUTHENTICATION_REQUIRED',
}
