import React from 'react';
import {
  HubNavBarItemTypes,
  HubModuleTypes,
  HubPageTypes,
  HubVisualStyles,
  SiteModuleTypes,
} from '../constants';
import { HeroModuleType as HubHeroModuleType } from '../components/hub/shared/siteBuilder/modules/HeroModule';
import { RootStore } from '../store/store';
import { FacetsConfigData, Discovery } from '../store/features/discovery';
import { Store } from 'redux';

export type GenericStatus = 'idle' | 'pending' | 'fulfilled' | 'rejected';

export interface GenericState<T> {
  data: T;
  status: GenericStatus;
  statusCode?: Response['status'];
  error?: unknown;
}

export interface RejectedPayloadData<T> {
  data: T;
  statusCode: Response['status'];
}

export type RejectedRequestError = RejectedPayloadData<{
  errors: {
    [key: string]: string;
  };
  message: string;
}>;

export interface ServerConfig {
  api: {
    services: {
      CommerceService: {
        v1: {
          host: string;
        };
      };
      ContentService: {
        v1: {
          host: string;
        };
      };
      LeadGenService: {
        v1: {
          host: string;
        };
      };
      DiscoveryService: {
        v1: {
          host: string;
        };
      };
    };
  };
}

type AdvSize = [number, number];
type AdvViewport = [number, number];
type AdvSizeMap = {
  viewport: AdvViewport;
  sizes: AdvSize[];
};

export interface ClientConfig {
  advertising: {
    [key: string]: {
      sizes: AdvSize[];
      sizeMapping: AdvSizeMap[];
    };
  };
}

export type UniqueIdentifier = string;

export interface RouteInstance {
  path: string;
  exact: boolean;
  noCache?: boolean;
  type?: string;
  oneYearCache?: boolean;
  component: React.ReactElement;
  fetchInitialData?: (store: Store, req: Request) => Promise<any>;
  postFetchDataActions?: (
    store: Store,
    req: Request,
  ) => {
    redirectStatusCode: number;
    redirectUrl: string;
  } | void;
}

export interface UploadedImage {
  '@type': string;
  path: string;
  fileName: string;
}

export interface UploadedFile {
  filename: string;
  lastUpdated: string;
  sourceFilename: string;
}

export interface QuickLink {
  url: string;
  text: string;
  openInNewTab: boolean;
}

export interface HubNavBarBaseItem {
  '@type': HubNavBarItemTypes;
  id: string;
  href: string;
  label: string;
  openInNewTab: boolean;
  displayAsCTA?: boolean;
}

export interface HubNavBarItem extends HubNavBarBaseItem {
  groupItems: HubNavBarBaseItem[];
}

export interface SocialNetworkLink {
  type: string;
  url: string;
  text: string;
}

export interface HubSector {
  id: UniqueIdentifier;
  audienceId: UniqueIdentifier;
  name: string;
  path: string;
  brandUrlSuffix: string;
}

export interface HubHeader {
  headerNavBar: {
    brandLogo: UploadedImage;
    sponsorLogo: UploadedImage;
    disableNavbarHomeLink: boolean;
    displayBrandLogoInNavbar: boolean;
    displayKeywordSearch: boolean;
    searchPlaceholderText: string;
    searchDisplayPlace: 'HEADER' | 'NAVBAR';
    displayTextContent: boolean;
    textContent: string;
    navLinks: QuickLink[];
  };
  heroBanner: HubHeroModuleType & {
    inlineLogo?: UploadedImage;
  };
  navBarItems: HubNavBarItem[];
}

export interface HubFooter {
  hashtag: string;
  footerLogo: UploadedImage;
  footerLinks: QuickLink[];
  footerLinksTitle: string;
  publicLinks: QuickLink[];
  socialLinks: SocialNetworkLink[];
}

export interface HubOptionsSignUp {
  title: string;
  body: string;
  articleEnabled: boolean;
  homeEnabled: boolean;
}

export interface HubOptions {
  name: string;
  subBrandName: string;
  brandUrlSuffix: string;
  path: string;
  productCode: string;
  newsletterCode: string;
  digitalSponsorshipCode: string;
  showSponsorLogos: boolean;
  theme: string;
  cacheIds: UniqueIdentifier[];
  promotionalSignUp: HubOptionsSignUp;
  newsletterSignUp: HubOptionsSignUp;
  advertisingEnabled: boolean;
  networkId: string;
  adUnit: string;
}

export interface HubLayout {
  header: HubHeader;
  footer: HubFooter;
  sectors: HubSector[];
}

export interface HubPage extends HubLayout {
  pageType: HubPageTypes;
}

export interface HubHomePage extends HubPage {
  home: {
    details: {
      title: string;
      metaDescription: string;
    };
    modules: HubModule[];
  };
}

export interface HubBasePage extends HubPage {
  page: {
    path: string;
    details: {
      live: boolean;
      title: string;
      metaDescription: string;
      description: string;
      searchQueryData: FacetsConfigData;
    };
  };
}

export interface HubLandingPage extends HubPage {
  landingPage: {
    id: UniqueIdentifier;
    details: {
      live: boolean;
      name: string;
      path: string;
      title: string;
      metaDescription: string;
      noindexEnabled: boolean;
      menuTitle: string;
      overrideLogo: boolean;
      pageLogo: UploadedImage;
    };
    modules: HubModule[];
  };
}

export type HubSiteBuilderModulesConfig = {
  [key in HubModuleTypes]: {
    fetchInitialData: (store: RootStore, module: any) => [Promise<any>];
  };
};

export type SiteBuilderModulesConfig = {
  [key in SiteModuleTypes]: {
    fetchInitialData: (store: RootStore) => [void];
  };
};

export interface HubModule {
  '@type': HubModuleTypes;
  id: UniqueIdentifier;
  name: string;
  elementId: string;
  visualStyle: HubVisualStyles;
  displayOrder?: 'AS_LISTED' | 'RANDOM';
}

export interface SiteModule {
  // ToDo: define list of modules
  '@type': string;
  id: UniqueIdentifier;
  name: string;
  elementId: string;
}

export interface SiteModule {
  id: UniqueIdentifier;
  type: SiteModuleTypes;
}

export type PropsWithTestId<T> = T & {
  testId?: string;
};

export interface HubModuleWithDiscovery extends HubModule {
  discovery: Discovery;
}

export interface Breadcrumb {
  path: string;
  label: string;
  tenant?: string;
}

export enum HubModulePredefinedLinks {
  NONE = 'NONE',
  EVENTS_LINK = 'EVENTS_LINK',
  COURSES_LINK = 'COURSES_LINK',
  ARTICLES_LINK = 'ARTICLES_LINK',
  VIDEOS_LINK = 'VIDEOS_LINK',
  KEYWORD_SEARCH = 'KEYWORD_SEARCH',
  CUSTOM = 'CUSTOM_LINK',
}

export enum HubModuleSiteLinkTypes {
  HOME_LINK = 'HOME_LINK',
  CUSTOM = 'CUSTOM',
  LANDING = 'LANDING',
}

export enum CallToActionSelectTypes {
  NONE = 'NONE',
  BASE = 'BASE',
  CUSTOM = 'CUSTOM',
  LANDING = 'LANDING',
}

export interface HubModuleCTA {
  type: HubModulePredefinedLinks;
  customUrl: string;
  customText: string;
  openInNewTabEnabled: boolean;
  shown: boolean;
}

export interface HubModuleSiteLink extends Omit<HubModuleCTA, 'type'> {
  type: HubModuleSiteLinkTypes;
  selectType: CallToActionSelectTypes;
}

export interface BrandColors {
  primary: string;
  lightPrimary: string;
  darkPrimary: string;
  primaryRGB: string;
  secondary: string;
  lightSecondary: string;
  darkSecondary: string;
  secondaryRGB: string;
  accent: string;
  lightAccent: string;
  darkAccent: string;
  accentRGB: string;
}

export enum FacetType {
  CONTENT_TYPE = 'CONTENT_TYPE',
  EVENT_TYPES = 'EVENT_TYPES',
  COURSE_TYPES = 'COURSE_TYPES',
  AUDIENCE_HOME = 'AUDIENCE_HOME',
  SUB_BRANDS = 'SUB_BRANDS',
  SECTORS = 'SECTORS',
  TOPICS = 'TOPICS',
  DATES = 'DATES',
  LOCATIONS = 'LOCATIONS',
  PUBLISHED_DATES = 'PUBLISHED_DATES',
  LANGUAGES = 'LANGUAGES',
  AWARD_GROUPS = 'AWARD_GROUPS',
  INFORMATION_TYPES = 'INFORMATION_TYPES',
  PARTNERS = 'PARTNERS',
  CREDIT_PARTNERS = 'CREDIT_PARTNERS',
  ACCESS_TYPES = 'ACCESS_TYPES',
  CREATED_TIME_FRAME = 'CREATED_TIME_FRAME',
  SEARCH_MODULE_PAGE_NAMES = 'SEARCH_MODULE_PAGE_NAMES',
  SPONSOR_CATEGORIES = 'SPONSOR_CATEGORIES',
  SPEAKER_STREAMS = 'SPEAKER_STREAMS',
}

export type HubFacetTypes = Exclude<
  FacetType,
  | FacetType.SEARCH_MODULE_PAGE_NAMES
  | FacetType.SPONSOR_CATEGORIES
  | FacetType.SPEAKER_STREAMS
>;
export type SiteFacetTypes =
  | FacetType.CONTENT_TYPE
  | FacetType.SEARCH_MODULE_PAGE_NAMES
  | FacetType.SPONSOR_CATEGORIES
  | FacetType.SPEAKER_STREAMS;

export enum SiteLocales {
  en = 'en',
  de = 'de',
  fr = 'fr',
  es = 'es',
  pt = 'pt',
  br = 'br',
  ru = 'ru',
  vn = 'vn',
  cf = 'cf',
  ar = 'ar',
  cn = 'cn',
}

export enum SiteTypePath {
  events = 'events',
  courses = 'courses',
}

export enum SiteTypes {
  HUB = 'HUB',
  STATIC = 'STATIC',
  BRAND_HUB = 'BRAND_HUB',
  AUDIENCE_HUB = 'AUDIENCE_HOME',
  PORTFOLIO = 'PORTFOLIO',
  ARTICLE = 'ARTICLE',
  EVENT = 'EVENT',
  COURSE = 'COURSE',
  L1_TOPIC_HUB = 'L1_TOPIC_HUB',
  L2_TOPIC_HUB = 'L2_TOPIC_HUB',
}

export type SocialIconType =
  | 'facebook'
  | 'instagram'
  | 'youtube'
  | 'twitter'
  | 'threads'
  | 'linkedin'
  | 'blog'
  | 'artsy'
  | 'flickr'
  | 'tiktok'
  | 'streamly'
  | 'whatsapp';

export enum SiteModuleLinkTypes {
  NONE = 'NONE',
  CUSTOM = 'CUSTOM',
  LANDING = 'LANDING',
  BASE = 'BASE',
}

export enum SiteModulePredefinedLinks {
  HOME_LINK = 'HOME_LINK',
  AGENDA_LINK = 'AGENDA_LINK',
  DOWNLOADABLE_AGENDA = 'DOWNLOADABLE_AGENDA',
  SPEAKERS_LINK = 'SPEAKERS_LINK',
  SPONSORS_OPPORTUNITIES_LINK = 'SPONSORS_OPPORTUNITIES_LINK',
  CONTACT = 'CONTACT',
  HIGHLIGHTS = 'HIGHLIGHTS',
  NETWORKING = 'NETWORKING',
  PLAN_YOUR_VISIT = 'PLAN_YOUR_VISIT',
  MEDIA_PARTNERS = 'MEDIA_PARTNERS',
  BUY_LINK = 'BUY_LINK',
  AWARDS = 'AWARDS',
  PARTNERING = 'PARTNERING',
  ATTENDEES = 'ATTENDEES',
  VIP_SERVICES = 'VIP_SERVICES',
  PARTNERING_ONE = 'PARTNERING_ONE',
  GROWTIX_SCHEDULE = 'GROWTIX_SCHEDULE',
  CUSTOM_LOGIN = 'CUSTOM_LOGIN',
}

export enum BookingFormType {
  DEFAULT = 'DEFAULT',
  CUSTOM = 'CUSTOM',
}

export enum BookingRegistrationName {
  APPLY = 'APPLY',
  REGISTER = 'REGISTER',
  BOOK_NOW = 'BOOK_NOW',
}

export interface SiteModuleLink {
  customText: string;
  customUrl: string;
  loggedInText: string;
  loggedInUnmatchedText: string;
  loggedInUnmatchedUrl: string;
  loggedInUrl: string;
  notLoggedInText: string;
  notLoggedInUrl: string;
  openInNewTabEnabled: boolean;
  type: SiteModuleLinkTypes;
  productCodeIds: Array<string>;
  selectType: SiteModulePredefinedLinks;
  shown: boolean;
}

export interface SiteModuleCTA {
  type: SiteModuleLink;
  customUrl: string;
  customText: string;
  openInNewTabEnabled: boolean;
  shown: boolean;
}
