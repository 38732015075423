import { HubModuleCTA, HubModulePredefinedLinks } from '../../../../../types';

export type PageRecordType = Record<
  HubModulePredefinedLinks,
  {
    label: string;
    url: string;
  }
>;

export const pages: PageRecordType = {
  [HubModulePredefinedLinks.NONE]: {
    label: '',
    url: '',
  },
  [HubModulePredefinedLinks.CUSTOM]: {
    label: '',
    url: '',
  },
  [HubModulePredefinedLinks.EVENTS_LINK]: {
    label: 'Events',
    url: '/events',
  },
  [HubModulePredefinedLinks.COURSES_LINK]: {
    label: 'Courses',
    url: '/courses',
  },
  [HubModulePredefinedLinks.ARTICLES_LINK]: {
    label: 'Articles',
    url: '/articles',
  },
  [HubModulePredefinedLinks.VIDEOS_LINK]: {
    label: 'Videos',
    url: '/videos',
  },
  [HubModulePredefinedLinks.KEYWORD_SEARCH]: {
    label: 'Search',
    url: '/search',
  },
};

export function getCallToActionAttrs(link: HubModuleCTA) {
  const { type, customUrl, customText, openInNewTabEnabled } = link;

  if (type === HubModulePredefinedLinks.CUSTOM) {
    return {
      url: customUrl,
      label: customText,
      openInNewTab: openInNewTabEnabled,
    };
  }

  return {
    url: pages[type]?.url || '',
    label: pages[type]?.label || '',
    openInNewTab: openInNewTabEnabled,
  };
}
