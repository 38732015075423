import clientConfig from '../../clientConfig';
import { siteTypesEnum } from '../../helpers';

const NavBarItemTypes = {
  PREDEFINED: 'PredefinedNavBarItemView',
  GROUP: 'GroupNavBarItemView',
  CUSTOM: 'LandingNavBarItemView',
};

const { COURSE } = siteTypesEnum();

const service = {
  getConfigByType(type) {
    return this.callToActionConfig[type] || null;
  },
  getNavLinkDetailsByConfig(props, params = {}) {
    let { name: type, href: path, nameUpper, nameLower, groupTitle } = props;
    const { siteType, agendaPageName, trainersMenuTitle, isCoLocatedAgenda } =
      params;

    if (props['@type'] === NavBarItemTypes.PREDEFINED) {
      /**
       * In case if AGENDA but it's CO-LOCATED-AGENDA then use different path
       */
      if (type === 'AGENDA' && isCoLocatedAgenda) {
        path = this.callToActionConfig.COLO_AGENDA_LINK.path;
      }

      /**
       * In case if AGENDA and COURSE then use custom translations
       */
      if (type === 'AGENDA' && siteType === COURSE) {
        type = `LEARNING_${agendaPageName}`;
      }

      /**
       * In case if TRAINERS and COURSE then use custom translations
       */
      if (type === 'TRAINERS' && siteType === COURSE) {
        type = `${trainersMenuTitle.replace(/\s+/g, '_')}`;
      }

      return {
        path,
        translationKey: `event.nav.${type}.one-line`,
        upperTranslationKey: `event.nav.${type}.upper`,
        lowerTranslationKey: `event.nav.${type}.lower`,
      };
    }

    if (props['@type'] === NavBarItemTypes.GROUP) {
      return {
        path: null,
        label: groupTitle,
        group: true,
      };
    }

    return {
      path,
      label: nameUpper,
      upperLabel: nameUpper,
      lowerLabel: nameLower,
      custom: true,
    };
  },
  getLinkDetailsByConfig({ type, customUrl, customText }) {
    const linkDetails = this.getConfigByType(type);

    if (linkDetails) {
      return {
        path: linkDetails.path,
        translationKey: linkDetails.label,
      };
    }

    return {
      path: customUrl || '/',
      label: customText,
      custom: true,
    };
  },
  callToActionConfig: {
    BUY_LINK: {
      path: '/purchase/select-package',
      coursePath: '/purchase/select-course-option',
      label: 'event.link.BUY_LINK.text',
    },
    COURSE_BUY_LINK: {
      path: '/purchase/select-course-option',
      label: 'event.link.BUY_LINK.text',
    },
    VIEW_BASKET: {
      path: '/purchase/view-basket',
      label: 'event.purchase.view-basket',
    },
    AGENDA_LINK: {
      path: '/agenda',
      label: 'event.link.AGENDA_LINK.text',
    },
    AGENDA_LINK_SHORT: {
      path: '/agenda',
      label: 'event.view-agenda',
    },
    COLO_AGENDA_LINK: {
      path: '/co-located-agenda',
      label: 'event.link.AGENDA_LINK.text',
    },
    DOWNLOADABLE_AGENDA: {
      path: '/agenda/download',
      label: 'event.link.DOWNLOADABLE_AGENDA.text',
    },
    ATTENDEES: {
      path: '/attendees',
      label: 'event.link.ATTENDEES.text',
    },
    AWARDS: {
      path: '/awards',
      label: 'event.link.AWARDS.text',
    },
    CONTACT: {
      path: '/contact',
      label: 'event.link.CONTACT.text',
    },
    HIGHLIGHTS: {
      path: '/highlights',
      label: 'event.link.HIGHLIGHTS.text',
    },
    HOME_LINK: {
      path: '/',
      label: 'event.link.HOME_LINK.text',
    },
    MEDIA_PARTNERS: {
      path: '/media-partners',
      label: 'event.link.MEDIA_PARTNERS.text',
    },
    NETWORKING: {
      path: '/networking',
      label: 'event.link.NETWORKING.text',
    },
    PARTNERING: {
      path: '/partnering',
      label: 'event.link.PARTNERING.text',
    },
    PLAN_YOUR_VISIT: {
      path: '/plan-your-visit',
      label: 'event.link.PLAN_YOUR_VISIT.text',
    },
    SPEAKERS_LINK: {
      path: '/speakers',
      label: 'event.link.SPEAKERS_LINK.text',
    },
    TRAINERS_LINK: {
      path: '/trainers',
      label: 'event.link.TRAINERS_LINK.text',
    },
    SPONSORS_OPPORTUNITIES_LINK: {
      path: '/sponsors#sponsorship-opportunities',
      label: 'event.link.SPONSORS_OPPORTUNITIES_LINK.text',
    },
    VIP_SERVICES: {
      path: '/vip-services',
      label: 'event.link.VIP_SERVICES.text',
    },
    GROWTIX_SCHEDULE: {
      path: '/schedule',
      label: 'event.link.GROWTIX_SCHEDULE.text',
    },
    CUSTOM_LOGIN: {
      // Custom implementation
    },
    PARTNERING_ONE: {
      path: '/pone/login',
      label: 'event.link.PARTNERING_ONE.login.text',
    },
    PARTNERING_ONE_ACCESS: {
      path: clientConfig.partneringOne.partneringOneDashBoardUrl,
      label: 'event.link.PARTNERING_ONE.access.text',
      withConfiguredPath: true,
    },
  },
};

export default service;
