import type { GenericState, UniqueIdentifier } from '../../../../types';
import type { RootState } from '../../../store';
import {
  createGenericAsyncThunk,
  createGenericBuilderCases,
  createGenericSlice,
} from '../../generic';

export enum TurtlPosition {
  TOP = 'TOP',
  BOTTOM = 'BOTTOM',
}

export interface ArticleTurtl {
  storyId?: string;
  linkUrl?: string;
  linkTitle?: string;
  storyPosition?: TurtlPosition;
}

export interface RelatedArticleData {
  articleId: UniqueIdentifier;
  articlePath: string;
  communityName: string;
  communityPath: string;
  contentType: string;
  dateModified?: string;
  datePublished?: string;
  gated: boolean;
  imageUrl: string;
  summary?: string;
  title: string;
  type: string;
  primaryTopic: string;
}

export interface SiteArticleDetails {
  data: {
    articleId: UniqueIdentifier;
    articlePath: string;
    author: {
      name: string;
      email?: string;
    };
    content?: string;
    contentType: string;
    dateCreated?: string;
    datePublished?: string;
    imageUrl: string;
    relatedArticles: RelatedArticleData[];
    summary?: string;
    tags: string[];
    title: string;
    turtl: ArticleTurtl;
    uploadedPdfUrls: string[];
  }
  meta: {
    title: string,
  }
}

export interface SiteArticleDetailsParams {
  siteArticlePath: string
}


export const  fetchSiteArticleDetails = createGenericAsyncThunk<
  SiteArticleDetails,
  SiteArticleDetailsParams
>(
  'siteArticleDetails/fetchSiteArticleDetails',
  async ({ siteArticlePath }, { extra, rejectWithValue }) => {
    const response = await extra.ajax.get(
      `/caas/content/api/v1/articles/path/${siteArticlePath}/`,
      {
        headers: {
          'Accept-Language': extra.headers['Accept-Language'],
          'Published-State': extra.headers['Published-State'],
        },
      },
    );
    const data: SiteArticleDetails = await response.json();

    if (response.status !== 200) {
      return rejectWithValue(extra.processRejectedValue(response, data));
    }

    return data;
  },
);

const siteArticleDetailsSlice = createGenericSlice({
  name: 'siteArticleDetails',
  initialState: {
    status: 'idle',
    data: {} as unknown as SiteArticleDetails,
  } as GenericState<SiteArticleDetails>,
  reducers: {},
  extraReducers: (builder) => {
    createGenericBuilderCases<SiteArticleDetails, SiteArticleDetailsParams>(
      builder,
      fetchSiteArticleDetails,
    );
  },
});

export const selectSiteArticleDetailsData = (state: RootState) =>
  state.site.articleDetails.data.data;

export const selectSiteArticleDetailsMeta = (state: RootState) =>
  state.site.articleDetails.data.meta;

export const { start, fulfilled, rejected } =
  siteArticleDetailsSlice.actions;

export default siteArticleDetailsSlice.reducer;
