import { getHeaderMAHeader } from '../../utils/getMAHeader';
import { createSelector } from 'reselect';
import getLimitedList from '../../utils/getLimitedList';
import { shuffleHomeHeroBanner } from '../../components/hub/brandHub/store/features/page';

const REQUEST = 'SITE_HEADER_REQUEST';
const SUCCESS = 'SITE_HEADER_SUCCESS';
const FAILURE = 'SITE_HEADER_FAILURE';

const initialState = {
  loaded: false,
  data: {},
  meta: {},
};

export default function siteHeaderReducer(state = initialState, action) {
  switch (action.type) {
    case REQUEST:
      return Object.assign({}, state, {
        loading: true,
      });

    case SUCCESS: {
      const data = Object.assign(
        {},
        {
          loaded: true,
          statusCode: action.data.statusCode,
          ...action.data.json,
        },
      );

      if (data.data?.homeHeroBanner?.displayOrder === 'RANDOM') {
        data.data.homeHeroBanner = shuffleHomeHeroBanner(
          data.data.homeHeroBanner,
        );
      }
      return data;
    }

    case FAILURE:
      return Object.assign(
        {},
        {
          error: true,
          statusCode: action.data.statusCode,
          ...action.data.json,
        },
      );

    default:
      return state;
  }
}

export function fetchSiteHeaderRequest(data) {
  return { type: REQUEST, data };
}

export function fetchSiteHeaderSuccess(data) {
  return { type: SUCCESS, data };
}

export function fetchSiteHeaderFailure(data) {
  return { type: FAILURE, data };
}

export function fetchSiteHeaderAction({ maSegments } = {}) {
  return function (dispatch, getState, { Api, i18n }) {
    dispatch(fetchSiteHeaderRequest());

    const {
      pageConfig: { isPreview, siteId, siteTypePath },
      options: { data: { maConfig } = {} } = {},
    } = getState();

    return Api()
      .cache(true)
      .service('ContentService')
      .version('v1')
      .setup((req) => {
        return {
          headers: {
            ...getHeaderMAHeader(req, maConfig, maSegments),
            'Accept-Language': i18n.language,
            'Published-State': isPreview ? 'Draft' : 'Published',
          },
        };
      })
      .fetchSiteHeader({ siteId, siteTypePath })
      .then((response) => {
        if (response.statusCode === 200) {
          return dispatch(fetchSiteHeaderSuccess(response));
        } else {
          return dispatch(fetchSiteHeaderFailure(response));
        }
      });
  };
}

export const getLimitedCourseOptions = createSelector(
  (state) => state.options.data?.deliveryType,
  (state) => state.siteHeader.data?.courseOptions,
  (deliveryType, courseOptions = []) => {
    const limitedOptionList = getLimitedList(courseOptions, 2);

    if (deliveryType !== 'DISTANCE') {
      return limitedOptionList;
    }

    return limitedOptionList.filter((currentOption, index) => {
      const nextOption = limitedOptionList[index + 1];

      if (nextOption) {
        return (
          currentOption.startDate !== nextOption.startDate ||
          currentOption.endDate !== nextOption.endDate
        );
      }

      return currentOption;
    });
  },
);

export const getVenueData = createSelector(
  (state) => state.options.data?.deliveryType,
  (state) => state.siteHeader.data,
  (deliveryType, { formattedDate, venueName, venueCity, shortInfo } = {}) => {
    return { formattedDate, venueName, venueCity, shortInfo, deliveryType };
  },
);
