import { UniqueIdentifier } from '../../../types';
import { createGenericAsyncThunk } from '../generic';
import { DiscoveryValues } from '../discovery';
import { DiscoveryContentTypes } from '../../../constants';
import { deleteValueFromFilters } from '../../../components/hub/shared/helpers';

export enum DiscoverySuggestionContentType {
  KEYWORD = 'KEYWORD',
  EVENT = 'EVENT',
  COURSE = 'COURSE',
  ARTICLE = 'ARTICLE',
  STREAMLY_VIDEO = 'STREAMLY_VIDEO',
  AUDIENCE_HOME = 'AUDIENCE_HOME',
}

export const getDiscoverySuggestionKeywordItem = (searchInput: string) => {
  return {
    id: 'keyword',
    type: DiscoverySuggestionContentType.KEYWORD,
    title: searchInput,
    highlights: [`‘<mark>${searchInput}</mark>’`],
  };
};

export const discoverySuggestionsConfigInitialValues = {
  suggestions: [],
  total: 0,
};

export interface DiscoverySuggestion {
  id: UniqueIdentifier;
  type: DiscoverySuggestionContentType;
  title: string;
  highlights: string[];
}

export interface DiscoverySuggestionsConfig {
  suggestions: DiscoverySuggestion[];
  total: number;
}

export type DiscoverySuggestionsConfigParams = Partial<DiscoveryValues>;

export const fetchDiscoverySuggestionsConfig = createGenericAsyncThunk<
  DiscoverySuggestionsConfig,
  DiscoverySuggestionsConfigParams
>(
  'hubDiscovery/fetchDiscoverySuggestionsConfig',
  async (values, { extra, rejectWithValue }) => {
    const response = await extra.ajax.post(
      '/caas/discovery/api/v1/discovery/hub-search/suggestions',
      deleteValueFromFilters(values, DiscoveryContentTypes.ALL),
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );
    const data: DiscoverySuggestionsConfig = await response.json();

    if (response.status !== 200) {
      return rejectWithValue(extra.processRejectedValue(response, data));
    }

    return data;
  },
);
