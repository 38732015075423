import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation, Trans } from 'react-i18next';

import { allowScroll, anchorPointScroll } from '../../../../helpers';
import Link from '../../../shared/Link/Link';

import './CookieMessage.scss';

export class CookieMessage extends Component {
  static propTypes = {
    accepted: PropTypes.bool,
    acceptCookie: PropTypes.func,
    addElementsPropertiesAction: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      bannerHeight: 0,
    };

    this.cookieBanner = React.createRef();

    this.updateDimensions = this.updateDimensions.bind(this);
  }

  updateDimensions() {
    const { addElementsPropertiesAction } = this.props;
    const pageWidth = window.innerWidth;
    const bannerHeight =
      this.cookieBanner.current && this.cookieBanner.current.clientHeight;
    const mobileBreakPoint = 812;

    let hashElement = null;
    if (document.location.hash) {
      try {
        hashElement = document.querySelector(document.location.hash);
      } catch (e) {
        console.warn('not valid hash selector');
      }
    }
    const offset = hashElement ? hashElement.offsetTop : 0;

    if (pageWidth > mobileBreakPoint) {
      allowScroll(offset);

      this.setState({
        bannerHeight,
      });
      addElementsPropertiesAction({
        cookieMessage: {
          height: bannerHeight,
        },
      });
    } else {
      if (!this.props.accepted) {
        anchorPointScroll(offset);
      }

      this.setState({
        bannerHeight: 0,
      });
      addElementsPropertiesAction({
        cookieMessage: {
          height: 0,
        },
      });
    }
  }

  componentDidMount() {
    this.updateDimensions();

    window.addEventListener('resize', this.updateDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  getCookiePolicyLink() {
    const tenantId = this.props.tenantId;
    return tenantId === 'KNECT365'
      ? 'https://knect365.com/cookie-policy/'
      : '/cookie-policy/';
  }

  render() {
    const { acceptCookie, accepted = true, t } = this.props;
    const { bannerHeight } = this.state;
    const messageStyle = accepted
      ? { top: '-100%', opacity: 0 }
      : { top: 0, opacity: 1 };

    return (
      <div className="c-cookie-message__container">
        <div
          className="c-cookie-message__placeholder"
          style={{ height: accepted ? 0 : bannerHeight + 'px' }}
        />
        <div
          className="c-cookie-message"
          style={messageStyle}
          ref={this.cookieBanner}
        >
          <div className="c-cookie-message__text">
            <Trans
              i18nKey="informa.cookiebar.text"
              values={{
                0: t('informa.cookie-policy'),
                1: t('informa.privacy-policy'),
              }}
            >
              <Link
                to={this.getCookiePolicyLink()}
                parent
                className="c-cookie-message__link"
              >
                {t('informa.cookie-policy')}
              </Link>
              <Link
                to="https://informa.com/privacy-policy/"
                parent
                className="c-cookie-message__link"
              >
                {t('informa.privacy-policy')}
              </Link>
            </Trans>
          </div>
          <div className="c-cookie-message__button" onClick={acceptCookie}>
            <i className="material-icons c-cookie-message__button-icon">
              close
            </i>
            <span className="c-cookie-message__button-text">
              {t('event.continue')}
            </span>
          </div>
        </div>
        <div
          className={`c-cookie-message__overlay${accepted ? ' hidden' : ''}`}
        />
      </div>
    );
  }
}

export default withTranslation()(CookieMessage);
