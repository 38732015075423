import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import {
  isAbsoluteUrl,
  isBoolean,
  isEnv,
  isFunction,
  isMailToUrl,
  isString,
  isTelephoneUrl,
  siteTypesEnum,
} from '../../../helpers';
import { localizePath } from '../../../helpers/locale';
import usePOneAccess from '../../../hooks/usePOneAccess';
import CallToActionService from '../../../services/CallToActionService';
import VirtualContentAccessCTA from '../VirtualContentAccessCTA/VirtualContentAccessCTA';
import LinkTo from '../LinkTo/LinkTo';
import Spinner from '../Spinner/Spinner';
import './CallToAction.scss';

const { COURSE, EVENT } = siteTypesEnum();

export const Types = {
  LINK: 'link',
  BUTTON: 'button',
  SUBMIT: 'submit',
};

export const Themes = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  ACCENT: 'accent',
  HOLLOW: 'hollow',
  DISABLED: 'disabled',
};

export const Modes = {
  NONE: 'none',
  BLACK: 'black',
  'BLACK-ON-PRIMARY': 'black-on-primary',
  WHITE: 'white',
  COLOR: 'color',
  'WHITE-ON-BLACK': 'white-on-black',
};

export const Sizes = {
  TINY: 'tiny',
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large',
  STYLED: 'styled',
  SUBMIT: 'submit',
};

/**
 * WHITE LABEL
 */
export function CallToAction(props) {
  const {
    to = '',
    label,
    labelOverwrite,
    translationKey,
    translationKeyOverwrite,
    type = Types.BUTTON,
    look = Themes.ACCENT,
    mode = Modes.NONE,
    size = Sizes.LARGE,
    noWrap,
    children,
    style,
    className,
    sitePath,
    brandSubPath,
    env,
    tenantId = '',
    portfolioId = '',
    ctaConfig = {},
    target,
    loading,
    disabled,
    isAutoWidthOnMobile = false,
    siteHeader: {
      partneringOneEventId,
      colo: isCoLocatedAgenda,
      booking: bookingEnabled,
      bookingForm: {
        bookingFormType,
        buttonText: bookingCustomText,
        mobileButtonText: bookingMobileCustomText,
        customBookingUrl: bookingCustomUrl,
      } = {},
      registrationName: bookingRegistrationName = 'BOOK_NOW',
    } = {},
    options: { data: { locales: { primary: primaryLocale } = {} } = {} } = {},
    pageConfig: {
      tenantConfig: { domain: tenantDomain } = {},
      tenantId: configTenantId = '',
      portfolioId: configPortfolioId = '',
      siteType: configSiteType,
      sitePath: configSitePath,
      brandSubPath: configBrandSubPath,
      location: { protocol, port } = {},
      tenantsConfig = {},
    } = {},
    i18n,
    onClick,
    testId,
  } = props;

  const accessToPartneringOneGranted = usePOneAccess(
    ctaConfig.type,
    partneringOneEventId,
  );

  function getLinkConfig({ siteType = EVENT, isCoLocatedAgenda }) {
    let linkUrl = to || '';
    let linkLabel = label;
    let linkTranslationKey = translationKey;

    if (isAbsoluteUrl(to) || isMailToUrl(to) || isTelephoneUrl(to)) {
      return { linkUrl, linkLabel, linkTranslationKey };
    }

    if (ctaConfig.type) {
      /**
       * In case if BUY_LINK type provided and it's a COURSE then use COURSE_BUY_LINK type instead
       */
      if (ctaConfig.type === 'BUY_LINK' && siteType === COURSE) {
        ctaConfig.type = 'COURSE_BUY_LINK';
      }

      /**
       * If ctaType is PARTNERING_ONE and user has POneEventAccess we will use PARTNERING_ONE_ACCESS ctaType
       */
      if (accessToPartneringOneGranted) {
        ctaConfig.type = 'PARTNERING_ONE_ACCESS';
      }

      /**
       * In case if AGENDA_LINK type provided and it's a CO-LOCATED-AGENDA then use COLO_AGENDA_LINK type instead
       */
      if (ctaConfig.type === 'AGENDA_LINK' && isCoLocatedAgenda) {
        ctaConfig.type = 'COLO_AGENDA_LINK';
      }

      const ctaConfigDetails =
        CallToActionService.getLinkDetailsByConfig(ctaConfig);

      const isAbsoluteLink = isAbsoluteUrl(ctaConfigDetails.path);
      const isMailToLink = isMailToUrl(ctaConfigDetails.path);
      const isTelephoneLink = isTelephoneUrl(ctaConfigDetails.path);

      if (isAbsoluteLink || isMailToLink || isTelephoneLink) {
        linkUrl = ctaConfigDetails.path;
      } else {
        const linkPath = ctaConfigDetails.path;
        const linkSitePath = isBoolean(sitePath)
          ? configSitePath
          : sitePath || configSitePath;
        linkUrl = `/${linkSitePath}${linkPath}${to || ''}`;

        if (primaryLocale) {
          linkUrl = localizePath(linkUrl, i18n.language, primaryLocale);
        }
      }

      linkLabel = label || ctaConfigDetails.label;
      linkTranslationKey = translationKey || ctaConfigDetails.translationKey;
    } else if (sitePath) {
      const prevLinkUrl = linkUrl;

      if (sitePath === true && configSitePath) {
        linkUrl = `/${configSitePath}`;
      } else if (isString(sitePath)) {
        linkUrl = `/${sitePath}`;
      }

      if (brandSubPath === true && configBrandSubPath) {
        linkUrl = `/${configBrandSubPath}${linkUrl}`;
      } else if (isString(brandSubPath)) {
        linkUrl = `/${brandSubPath}${linkUrl}`;
      } else if (brandSubPath !== false && configBrandSubPath) {
        linkUrl = `/${configBrandSubPath}${linkUrl}`;
      }

      linkUrl += prevLinkUrl;

      if (primaryLocale) {
        linkUrl = localizePath(linkUrl, i18n.language, primaryLocale);
      }
    }

    const removePortfolioFlag =
      configPortfolioId && !portfolioId && portfolioId !== '';

    if (tenantId || portfolioId || removePortfolioFlag) {
      let hostname =
        tenantsConfig[tenantId] && tenantId !== configTenantId
          ? tenantsConfig[tenantId].domain
          : '';

      if (!hostname) {
        const differentPortfolioIds =
          portfolioId && portfolioId !== configPortfolioId;
        if (differentPortfolioIds || removePortfolioFlag) {
          hostname = tenantDomain;
        }
      }

      if (hostname) {
        const envStr = env || isEnv();
        const isProd = envStr === 'prod';

        if (!isProd) {
          hostname = envStr + '.' + hostname;
        }

        if (portfolioId && portfolioId !== tenantId) {
          hostname = portfolioId + (isProd ? '.' : '-') + hostname;
        } else if (isProd && tenantsConfig[tenantId || configTenantId].useWWW) {
          hostname = 'www.' + hostname;
        }

        hostname = protocol + '//' + hostname + (port ? ':' + port : '');

        linkUrl = hostname.toLowerCase() + linkUrl;
      }
    }

    return { linkUrl, linkLabel, linkTranslationKey };
  }

  if (ctaConfig.shown === false) {
    return null;
  }

  /**
   * A lot of custom logic, decided to create a separate component
   */
  if (ctaConfig.type === 'CUSTOM_LOGIN') {
    return (
      <VirtualContentAccessCTA
        look={look}
        mode={mode}
        size={size}
        ctaConfig={ctaConfig}
      />
    );
  }

  const componentClassNames = classNames({
    'c-cta': type !== Types.LINK,
    [`c-cta--${look}`]: type !== Types.LINK && look,
    [`c-cta--${size}`]: type !== Types.LINK && size,
    [`c-cta--${mode}`]: type !== Types.LINK && mode !== Modes.NONE,
    'c-cta--no-wrap': noWrap,
    'c-cta--loading': loading,
    'c-cta--disabled': disabled,
    'c-cta--auto-width-mobile': isAutoWidthOnMobile,
    [className]: className,
  });

  if (type === Types.LINK || to || ctaConfig.type) {
    if (isBoolean(ctaConfig.shown) && ctaConfig.shown === false) {
      return null;
    }

    let { linkUrl, linkLabel, linkTranslationKey } = getLinkConfig({
      siteType: configSiteType,
      isCoLocatedAgenda,
    });
    let allowTrailingSlash =
      ctaConfig.type !== 'CUSTOM' && linkUrl.indexOf('#') === -1;

    /**
     * Booking button overrides
     */
    if (ctaConfig.type === 'BUY_LINK' || ctaConfig.type === 'COURSE_BUY_LINK') {
      if (!bookingEnabled) {
        return null;
      }

      linkTranslationKey =
        translationKey || `event.book-now.${bookingRegistrationName}`;

      if (bookingFormType === 'CUSTOM') {
        linkUrl = bookingCustomUrl;
        linkLabel = bookingCustomText;
        allowTrailingSlash = false;
      }
    }

    const linkTarget =
      target || (ctaConfig.openInNewTabEnabled ? '_blank' : undefined);

    return (
      <LinkTo
        to={linkUrl}
        target={linkTarget}
        label={linkLabel}
        labelOverwrite={labelOverwrite}
        translationKey={linkTranslationKey}
        translationKeyOverwrite={translationKeyOverwrite}
        allowTrailingSlash={allowTrailingSlash}
        loading={loading}
        className={componentClassNames}
        style={style}
        onClick={onClick}
        testId={testId}
      >
        {isFunction(children)
          ? children({
              props,
              label: linkLabel,
              translationKey: linkTranslationKey,
              bookingMobileCustomText,
            })
          : children}
      </LinkTo>
    );
  }

  return !children && !label ? null : (
    <button
      type={type}
      disabled={loading}
      className={componentClassNames}
      style={style}
      onClick={onClick}
      data-testid={testId}
    >
      {loading && <Spinner />}
      <span>{children || label}</span>
    </button>
  );
}

export const CallToActionConfigProps = PropTypes.shape({
  type: PropTypes.oneOf([
    'CUSTOM',
    'LANDING',
    ...Object.keys(CallToActionService.callToActionConfig),
  ]),
  customUrl: PropTypes.string,
  customText: PropTypes.string,
  openInNewTabEnabled: PropTypes.bool,
  shown: PropTypes.bool,
});

export const CallToActionProps = {
  to: PropTypes.string,
  sitePath: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  brandSubPath: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  tenantId: PropTypes.string,
  portfolioId: PropTypes.string,
  ctaConfig: CallToActionConfigProps,
  type: PropTypes.oneOf(Object.values(Types)),
  look: PropTypes.oneOf(Object.values(Themes)),
  mode: PropTypes.oneOf(Object.values(Modes)),
  size: PropTypes.oneOf(Object.values(Sizes)),
  label: PropTypes.string,
  labelOverwrite: PropTypes.string,
  translationKey: PropTypes.string,
  translationKeyOverwrite: PropTypes.string,
  noWrap: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  style: PropTypes.object,
  className: PropTypes.string,
  target: PropTypes.string,
  siteHeader: PropTypes.object,
  pageConfig: PropTypes.object,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  isAutoWidthOnMobile: PropTypes.bool,
  onClick: PropTypes.func,
  testId: PropTypes.string,
};

CallToAction.propTypes = CallToActionProps;

function mapStateToProps(state) {
  return {
    siteHeader: state.siteHeader.data,
    pageConfig: state.pageConfig,
    options: state.options,
  };
}

export default withTranslation()(connect(mapStateToProps)(CallToAction));
